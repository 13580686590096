export default function slugify(text) {
    // lowercase 
    text = text.toLowerCase();
    // remove special characters
    text = text.replace(/[^a-zA-Z0-9\-\_]/g, '-');
    // remove multiple dashes
    text = text.replace(/-+/g, '-');
    // remove leading and trailing dashes
    // text = text.replace(/^-|-$/g, '');
    return text;
}