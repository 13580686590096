import CompanionService from "../services/entity.companion.service";

// Action to get entity by id
export const readCompanion = (entityId, id) => (dispatch) => {
    return CompanionService.readCompanion(entityId, id).then((data) => {
        console.log(data);
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to count all companions
export const countCompanions = (entityId, params) => (dispatch) => {
    return CompanionService.countCompanions(entityId, params).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to get all companions
export const readCompanions = (entityId, params) => (dispatch) => {
    return CompanionService.readCompanions(entityId, params).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to create the companion
export const createCompanion = (entityId, companion) => (dispatch) => {
    return CompanionService.createCompanion(entityId, companion).then((data) => {
        console.log(data);
        return Promise.resolve(data);
    }, (error) => {
        console.log(error.message);
        return Promise.reject(error);
    });
}

// Action to update the companion
export const updateCompanion = (entityId, id, companion) => (dispatch) => {
    return CompanionService.updateCompanion(entityId, id, companion).then((data) => {
        console.log(data);
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to delete the companion
export const deleteCompanion = (entityId, id) => (dispatch) => {
    return CompanionService.deleteCompanion(entityId, id).then((data) => {
        // dispatch({
        //     type: DELETE_ENTITY_REGION_SUCCESS,
        //     payload: id
        // });
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}