import React, { useCallback, useEffect, useState } from 'react';
import { Layout, Popconfirm, message, Button, Table, Space, Tag } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import MemberSidebar from './components/member.sidebar.component';
import MemberFooter from './components/member.footer.component';
import { useDispatch, useSelector } from 'react-redux';
import { readAdmins, deleteAdmin, disableAdmin, resetAdmin, enableAdmin } from '../actions/admin';
import { isRoot, isTopAdmin, isRootOrTopAdmin } from '../helpers/user';
import moment from 'moment';
import { PageHeader } from '@ant-design/pro-components';

function Admins() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { Content } = Layout;
    const currentUser = useSelector(state => state.auth.user);
    const [dataSource, setDataSource] = useState([]);

    const resetAdminAction = (id) => {
        dispatch(resetAdmin(id)).then(response => {
            message.success('Admin reset successfully');
            reloadAdmins();
        }).catch(error => {
            try {
                let responseMessage = error.response.data.message;
                console.log(responseMessage);
                message.error(responseMessage);
            } catch (e) {
                console.log('Failed:', e);
                message.error(JSON.stringify(e));
            }
        });
    }
    const deleteAdminAction = (id) => {
        dispatch(deleteAdmin(id)).then(response => {
            message.success('Admin deleted successfully');
            reloadAdmins();
        }).catch(error => {
            try {
                let responseMessage = error.response.data.message;
                console.log(responseMessage);
                message.error(responseMessage);
            } catch (e) {
                console.log('Failed:', e);
                message.error(JSON.stringify(e));
            }
        });
    }
    const enableAdminAction = (id) => {
        dispatch(enableAdmin(id)).then(response => {
            message.success('Admin enabled successfully');
            reloadAdmins();
        }).catch(error => {
            try {
                let responseMessage = error.response.data.message;
                console.log(responseMessage);
                message.error(responseMessage);
            } catch (e) {
                console.log('Failed:', e);
                message.error(JSON.stringify(e));
            }
        });

    }
    const disableAdminAction = (id) => {
        dispatch(disableAdmin(id)).then(response => {
            message.success('Admin is disabled successfully');
            reloadAdmins();
        }).catch(error => {
            try {
                let responseMessage = error.response.data.message;
                console.log(responseMessage);
                message.error(responseMessage);
            } catch (e) {
                console.log('Failed:', e);
                message.error(JSON.stringify(e));
            }
        });
    }

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Is Root?',
            dataIndex: 'isRoot',
            key: 'isRoot',
            render: (text, record) => {
                if (record.isRoot) {
                    return 'Yes';
                } else {
                    return 'No';
                }
            }
        },
        {
            title: 'Is Top Admin?',
            dataIndex: 'isTopAdmin',
            key: 'isTopAdmin',
            render: (text, record) => {
                if (record.isTopAdmin) {
                    return 'Yes';
                } else {
                    return 'No';
                }
            }
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => {
                if (record.status) {
                    return 'Active';
                } else {
                    return 'Inactive';
                }
            }
        },
        {
            title: 'Need Password Change?',
            dataIndex: 'needChangePassword',
            key: 'needChangePassword',
            render: (text, record) => {
                if (record.needChangePassword) {
                    return 'Yes';
                } else {
                    return 'No';
                }
            }
        },
        // {
        //     title: 'Roles',
        //     dataIndex: 'roles',
        //     key: 'roles',
        //     render: (text, record) => {
        //         if (Array.isArray(record.roles) && record.roles.length > 0) {
        //             return <>{
        //                 record.roles.map(role => {
        //                     return <Link to={`/role/${role._id}`} key={role._id}><Tag color="cyan">{role.name}</Tag></Link>
        //                 })
        //             }</>
        //         } else {
        //             return '-';
        //         }
        //     }
        // },
        {
            title: 'Token Expiry Date',
            dataIndex: 'byPassTokenExpiredDate',
            key: 'byPassTokenExpiredDate',
            render: (text, record) => {
                if (record.byPassTokenExpiredDate) {
                    return moment(record.byPassTokenExpiredDate).format('YYYY-MM-DD HH:mm:ss');
                } else {
                    return '-';
                }
            }

        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: (text, record) => {
                let canTriggerResetPassword = false;
                let canEditAdmin = false;
                let canDeleteAdmin = false;
                let canEnableDisableAdmin = false;
                if (isRoot(currentUser)) {
                    //  Current user is root
                    canTriggerResetPassword = true;
                    canEditAdmin = true;
                    canDeleteAdmin = true;
                    canEnableDisableAdmin = true;
                } else if (isTopAdmin(currentUser)) {
                    // Current user is top admin
                    if (!isRootOrTopAdmin(record)) {
                        // Current record is not root or top admin
                        canTriggerResetPassword = true;
                        canEditAdmin = true;
                        canDeleteAdmin = true;
                        canEnableDisableAdmin = true;
                    }
                }
                if (currentUser.id === record.id) {
                    canTriggerResetPassword = false;
                    canEditAdmin = false;
                    canDeleteAdmin = false;
                    canEnableDisableAdmin = false;
                }
                return <Space>
                    {canTriggerResetPassword && <Popconfirm
                        title="Are you sure to reset this admin?"
                        onConfirm={() => resetAdminAction(record.id)}
                        okText="Reset"
                        cancelText="Cancel"
                    >
                        <Button type="primary">Reset Password</Button>
                    </Popconfirm>}
                    {canEditAdmin && <Button type="primary" onClick={`/settings/admin/${record.id}`}>Edit</Button>}
                    {canDeleteAdmin && <Popconfirm
                        title="Are you sure to delete this admin?"
                        onConfirm={() => deleteAdminAction(record.id)}
                        okText="Delete"
                        cancelText="Cancel"
                    >
                        <Button danger type="primary">Delete</Button>
                    </Popconfirm>}
                    {canEnableDisableAdmin && (record.status ? <Popconfirm
                        title="Are you sure to disable this admin?"
                        onConfirm={() => disableAdminAction(record.id)}
                        okText="Disable"
                        cancelText="Cancel"
                    ><Button type="primary" danger>Disable</Button></Popconfirm> : <Popconfirm
                        title="Are you sure to enable this admin?"
                        onConfirm={() => enableAdminAction(record.id)}
                        okText="Enable"
                        cancelText="Cancel"
                    ><Button type="primary">Enable</Button></Popconfirm>)}
                </Space>
            }
        }
    ];

    const reloadAdmins = useCallback(() => {
        dispatch(readAdmins()).then((response) => {
            if (typeof response.result !== 'undefined') {
                setDataSource(response.result);
            } else {
                setDataSource([]);
            }
        }).catch((error) => {
            try {
                let responseMessage = error.response.data.message;
                console.log(responseMessage);
                message.error(responseMessage);
            } catch (e) {
                console.log('Failed:', e);
                message.error(JSON.stringify(e));
            }
        });
    }, [dispatch]);

    useEffect(() => {
        reloadAdmins();
    }, []);

    return <>
        <MemberSidebar />
        <Layout className="site-layout">
            <Content style={{ margin: '0 16px' }}>
                <div className="site-layout-background">
                    <PageHeader
                        className="site-page-header"
                        onBack={() => navigate('/dashboard')}
                        title="Admins"
                        subTitle="Manage the permissions of your users"
                        extra={[
                            <Button key="create" type="primary" href={'/settings/admin'}>New</Button>
                        ]}
                    />
                    <Table
                        rowKey={record => record.id}
                        dataSource={dataSource}
                        columns={columns} />
                </div>
            </Content>
            <MemberFooter />
        </Layout>
    </>;
}

export default Admins;