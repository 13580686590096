import React, { useEffect, useState } from 'react';
import { Layout, message, Button, Form, Input, Switch } from 'antd';
import { PageHeader } from '@ant-design/pro-components';
import { useNavigate, useParams } from 'react-router-dom';
import MemberSidebar from './components/member.sidebar.component';
import MemberFooter from './components/member.footer.component';
import { useDispatch, useSelector } from 'react-redux';
import {
    readDiscussion,
    createDiscussion,
    updateDiscussion
} from '../actions/entity.discussion'
import { userIsReadOnlyInEntity } from '../helpers/user';
import { useTranslation } from 'react-i18next';

const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 10,
    },
};

function Discussion() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const params = useParams();
    const entities = useSelector(state => state.entity.entities);
    const currentEntity = entities ? entities.find(entity => entity.id === params.entityId) : null;
    const { Content } = Layout;
    const [form] = Form.useForm();
    const [discussionTitle, setDiscussionTitle] = useState('');
    const currentUser = useSelector(state => state.auth.user);
    const userIsReadOnly = userIsReadOnlyInEntity(currentUser, currentEntity);

    const onFinish = (values) => {
        if (userIsReadOnly) {
            message.error(t('discussion.message.read-only'));
            return;
        }
        if (typeof params.discussionId !== 'undefined') {
            // Update the existing discussion
            dispatch(updateDiscussion(params.entityId, params.discussionId, values)).then((response) => {
                if (typeof response.result !== 'undefined' && typeof response.result.id !== 'undefined') {
                    setDiscussionTitle(t('discussion.title.edit') + ' - ' + response.result.id);
                    form.setFieldsValue(response.result);
                }
                message.success(t('discussion.message.update-success'));
            }).catch(error => {
                try {
                    let responseMessage = error.response.data.message;
                    message.error(responseMessage);
                } catch (e) {
                    console.log('Failed:', e);
                    message.error(JSON.stringify(e));
                }
            });
        } else {
            // Create new campaign
            dispatch(createDiscussion(params.entityId, values)).then((data) => {
                message.success(t('discussion.message.create-success'));
                if (data.result) {
                    navigate(`/entity/${params.entityId}/discussion/${data.result.id}`);
                } else {
                    navigate(`/entity/${params.entityId}/discussions`);
                }
            }).catch(error => {
                try {
                    let responseMessage = error.response.data.message;
                    message.error(responseMessage);
                } catch (e) {
                    console.log('Failed:', e);
                    message.error(JSON.stringify(e));
                }
            });
        }
    }

    useEffect(() => {
        if (typeof params.discussionId !== 'undefined') {
            setDiscussionTitle(t('discussion.title.edit') + ' - ' + params.discussionId);
            dispatch(readDiscussion(params.entityId, params.discussionId)).then((response) => {
                if (typeof response.result !== 'undefined' && typeof response.result.id !== 'undefined') {
                    setDiscussionTitle(t('discussion.title.edit') + ' - ' + response.result.id);
                    form.setFieldsValue(response.result);
                } else {
                    message.error(t('discussion.message.error-reading'));
                    navigate(`/entity/${params.entityId}/discussions`);
                }
            }).catch(e => {
                message.error(t('discussion.message.error-reading'));
                navigate(`/entity/${params.entityId}/discussions`);
            });
        } else {
            setDiscussionTitle(t('discussion.title.new'));
        }
    }, [params.discussionId, params.entityId, dispatch, navigate, form]);

    const onFinishFailed = (errorInfo) => {
        if (typeof errorInfo.errorFields !== 'undefined') {
            errorInfo.errorFields.forEach(field => {
                message.error(field.errors[0]);
            });
        }
    }

    let extraButtons = [
        <Button key="cancel" type="primary" danger href={`/entity/${params.entityId}/discussions`}>{t('discussion.actions.cancel')}</Button>
    ];
    if (!userIsReadOnly) {
        extraButtons.push(<Button key="save" type="primary" htmlType="submit">{params.discussionId ? t('discussion.actions.update') : t('discussion.actions.save')}</Button>);
    }
    return <>
        <MemberSidebar />
        <Layout className="site-layout">
            <Content style={{ margin: '0 16px' }}>
                <div className="site-layout-background">
                    <Form
                        {...layout}
                        form={form}
                        name="entity-region"
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        initialValues={{
                            status: true
                        }}>
                        <PageHeader
                            className="site-page-header"
                            onBack={() => navigate(`/entity/${params.entityId}/discussions`)}
                            title={discussionTitle}
                            extra={extraButtons}
                        />
                        <>
                            <Form.Item
                                label={t('discussion.field.status.label')}
                                name="status"
                                valuePropName="checked"
                            >
                                <Switch />
                            </Form.Item>
                            <Form.Item
                                label={t('discussion.field.name.label')}
                                required={true}
                            >
                                <Form.Item
                                    name="name"
                                    rules={[
                                        {
                                            required: true,
                                            message: t('discussion.field.name.required')
                                        },
                                    ]}
                                    noStyle>
                                    <Input placeholder={t('discussion.field.name.placeholder')} />
                                </Form.Item>
                                <div>{t('discussion.field.name.note')}</div>
                            </Form.Item>
                        </>
                    </Form>
                </div>
            </Content>
            <MemberFooter />
        </Layout>
    </>;
}

export default Discussion;