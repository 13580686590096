import TripTopicService from "../services/entity.trip.topic.service";

// Action to get all active trip topic translations by entity id
export const readAllActiveTripTopicsTranslations = (entityId) => (dispatch) => {
    return TripTopicService.readAllActiveTripTopicsTranslations(entityId).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to get entity by id
export const readTripTopic = (entityId, id) => (dispatch) => {
    return TripTopicService.readTripTopic(entityId, id).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to get all entity topics
export const readTripTopics = (entityId) => (dispatch) => {
    return TripTopicService.readTripTopics(entityId).then((data) => {
        // if (data.result) {
        //     dispatch({
        //         type: READ_ENTITY_REGIONS_SUCCESS,
        //         payload: data.result
        //     });
        // }
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to create the entity topic
export const createTripTopic = (entityId, tripType) => (dispatch) => {
    return TripTopicService.createTripTopic(entityId, tripType).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        console.log(error.message);
        return Promise.reject(error);
    });
}

// Action to update the entity topic
export const updateTripTopic = (entityId, id, tripTopic) => (dispatch) => {
    return TripTopicService.updateTripTopic(entityId, id, tripTopic).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to delete the entity topic
export const deleteTripTopic = (entityId, id) => (dispatch) => {
    return TripTopicService.deleteTripTopic(entityId, id).then((data) => {
        // dispatch({
        //     type: DELETE_ENTITY_REGION_SUCCESS,
        //     payload: id
        // });
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}