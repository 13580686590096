import React, { useCallback, useEffect, useState } from 'react';
import { Layout, Popconfirm, message, Button, Table, Space, Upload, Input } from 'antd';
import { PageHeader } from '@ant-design/pro-components';
import { CopyOutlined, InboxOutlined, RedoOutlined } from '@ant-design/icons';
import MemberSidebar from './components/member.sidebar.component';
import MemberFooter from './components/member.footer.component';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { readMedia, countMedia, deleteMedia } from '../actions/media';
import { API_CDN_URL, API_MEDIA_URL } from '../services/config';
import authHeader from '../services/auth-header';
import moment from 'moment';
import { userIsReadOnlyInEntity } from '../helpers/user';
import { useTranslation } from 'react-i18next';
import { defaultPageSize, withFilterForm } from '../components/withFilterForm';

function EntityMedia(props) {
    const { form, searchParams, setPage, setPageSize } = props;
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { Content } = Layout;
    const [total, setTotal] = useState(0);
    const [dataSource, setDataSource] = useState([]);
    const params = useParams();
    const currentEntityId = params.entityId ? params.entityId : props.entityId;
    const mediaUploadAction = API_MEDIA_URL(currentEntityId) + 'upload';
    const entities = useSelector(state => state.entity.entities);
    const currentEntity = entities ? entities.find(entity => entity.id === currentEntityId) : null;
    const currentUser = useSelector(state => state.auth.user);
    const userIsReadOnly = userIsReadOnlyInEntity(currentUser, currentEntity);
    const [processedUploadedFiles, setProcessedUploadedFiles] = useState([]);

    const deleteMediaAction = (record) => {
        dispatch(deleteMedia(currentEntityId, record.id)).then((response) => {
            message.success(t('media.message.delete-success'));
            reloadMedia();
        }).catch(error => {
            try {
                let responseMessage = error.response.data.message;
                console.log(responseMessage);
                message.error(responseMessage);
            } catch (e) {
                console.log('Failed:', e);
                message.error(JSON.stringify(e));
            }
        });
    }

    const mediaUploadChange = (event) => {
        if (Array.isArray(event.fileList)) {
            event.fileList.forEach(uploadedFile => {
                console.log(uploadedFile);
                if (uploadedFile.status === 'error' || uploadedFile.status === 'done') {
                    if (processedUploadedFiles.indexOf(uploadedFile.name) === -1) {
                        setProcessedUploadedFiles([...processedUploadedFiles, uploadedFile.name]);
                        if (uploadedFile.status === 'done') {
                            message.success(uploadedFile.response.message);
                        } else {
                            message.error(uploadedFile.response.message);
                        }
                    }
                }
            });
        }
    }


    const columns = [
        {
            title: t('media.columns.image'),
            dataIndex: 'image',
            key: 'image',
            render: (text, record) => {
                let link = API_CDN_URL(currentEntity) + record.path;
                return <a href={link} rel="noreferrer" target="_blank"><img src={link} alt={record.name} style={{ width: 100, height: 100, objectFit: 'contain' }} /></a>;
            }
        },
        {
            title: t('media.columns.name'),
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: t('media.columns.link'),
            key: 'link',
            width: '40%',
            render: (text, record) => {
                if (currentEntity) {
                    let link = API_CDN_URL(currentEntity) + record.path;
                    return <a href={link} rel="noreferrer" target="_blank">{link}</a>;
                } else {
                    return 'N/A';
                }
            }
        },
        {
            title: t('media.columns.created'),
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (text, record) => {
                return moment(record['createdAt']).format('YYYY-MM-DD HH:mm:ss');
            }
        },
        {
            title: t('media.columns.actions'),
            key: 'action',
            render: (text, record) => {
                return <Space>
                    {props.entityId && <Button size="small" type="primary" onClick={() => props.selectMedia ? props.selectMedia(record) : null}>{t('media.actions.select')}</Button>}
                    <Button size="small" type="primary" onClick={() => copyMediaUrl(record)}><CopyOutlined /> {t('media.actions.copy')}</Button>
                    {!userIsReadOnly && <Popconfirm
                        title={t('media.message.delete-confirm')}
                        onConfirm={() => deleteMediaAction(record)}
                        okText={t('media.actions.delete-yes')}
                        cancelText={t('media.actions.delete-no')}
                    ><Button size="small" type="primary" danger>{t('media.actions.delete')}</Button></Popconfirm>}
                </Space>
            }
        }
    ];

    const copyMediaUrl = (record) => {
        if (currentEntity) {
            let url = API_CDN_URL(currentEntity) + record.path;
            navigator.clipboard.writeText(url);
            message.success(t('media.message.copied'));
        }
    }

    const reloadMedia = useCallback(() => {
        const filters = {};
        if (typeof searchParams.get('name') !== 'undefined' && searchParams.get('name') && searchParams.get('name') !== '') {
            filters['name'] = searchParams.get('name');
        }
        const filtersWithPagination = { ...filters, page: searchParams.get('page'), pageSize: searchParams.get('pageSize') };
        dispatch(countMedia(currentEntityId, filters)).then(function (data) {
            setTotal(data.result);
        }).catch(error => {
            try {
                let responseMessage = error.response.data.message;
                console.log(responseMessage);
                message.error(responseMessage);
            } catch (e) {
                console.log('Failed:', e);
                message.error(JSON.stringify(e));
            }
        });
        dispatch(readMedia(currentEntityId, filtersWithPagination)).then((data) => {
            setDataSource(data.result);
        }).catch((error) => {
            try {
                setDataSource([]);
                let responseMessage = error.response.data.message;
                console.log(responseMessage);
                message.error(responseMessage);
            } catch (e) {
                console.log('Failed:', e);
                message.error(JSON.stringify(e));
            }
        });
    }, [dispatch, currentEntityId, searchParams]);

    useEffect(() => {
        reloadMedia();
    }, [reloadMedia]);

    const headerBack = () => navigate('/');
    const innerMediaLibrary = <><PageHeader
        className="site-page-header"
        onBack={props.entityId ? null : headerBack}
        title={t('media.title')}
        subTitle={t('media.subtitle')}
        extra={[
            <Button key="refresh" type="primary" onClick={() => reloadMedia()}>
                <RedoOutlined /> {t('media.actions.refresh')}</Button>
        ]}
    />
        {!userIsReadOnly && <div style={{ marginBottom: 20 }}>
            <Upload.Dragger
                name="files"
                headers={authHeader()}
                action={mediaUploadAction}
                onChange={mediaUploadChange} multiple={true}>
                <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                </p>
                <p className="ant-upload-text">{t('media.upload.action-reminder')}</p>
                <p className="ant-upload-hint">{t('media.upload.support-reminder')}</p>
            </Upload.Dragger>
        </div>}
        {!userIsReadOnly && <h4>{t('media.message.refresh-reminder')}</h4>}
        {form}
        <Table
            size='small'
            rowKey={record => record.id}
            dataSource={dataSource}
            columns={columns}
            pagination={{
                total: total,
                onChange(page, pageSize) {
                    setPage(page);
                    setPageSize(pageSize);
                },
                showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                defaultCurrent: searchParams.get('page') ? parseInt(searchParams.get('page')) : 1,
                defaultPageSize: searchParams.get('pageSize') ? parseInt(searchParams.get('pageSize')) : defaultPageSize,
                showSizeChanger: true,
                pageSizeOptions: [defaultPageSize.toString(), '250', '500', '1000'],
                position: ['topRight', 'bottomRight']
            }}
        /></>;

    if (props.entityId) {
        // Means called in drawer
        return innerMediaLibrary;
    } else {
        return <>
            <MemberSidebar />
            <Layout className="site-layout">
                <Content style={{ margin: '0 16px' }}>
                    <div className="site-layout-background">
                        {innerMediaLibrary}
                    </div>
                </Content>
                <MemberFooter />
            </Layout>
        </>;
    }
}

export default withFilterForm(EntityMedia, [
    { name: 'name', label: 'Name', formItem: <Input placeholder='Name' /> },
], null, null, null, defaultPageSize, 1, ['name']);