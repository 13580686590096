import {
    READ_ENTITIES_SUCCESS,
    DELETE_ENTITY_SUCCESS,
    LOGOUT
} from "../actions/types";

const initialState = { entities: null };

function entityReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case LOGOUT:
            return {
                ...state,
                entities: null
            };
        case READ_ENTITIES_SUCCESS:
            return {
                ...state,
                entities: payload
            };
        case DELETE_ENTITY_SUCCESS:
            return {
                ...state,
                entities: state.entities.filter(entity => entity.id !== payload)
            };
        default:
            return state;
    }
}
export default entityReducer;