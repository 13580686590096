import React, { useEffect, useState } from 'react';
import { Layout, message, Button, Form, Input, Switch, Select, Tabs, Drawer, DatePicker } from 'antd';
import { PageHeader } from '@ant-design/pro-components';
import { useNavigate, useParams } from 'react-router-dom';
import MemberSidebar from './components/member.sidebar.component';
import MemberFooter from './components/member.footer.component';
import { useDispatch, useSelector } from 'react-redux';
import { green, gold } from '@ant-design/colors';
import { API_CDN_URL, API_MEDIA_URL, API_USER_MEDIA_URL } from '../services/config';
import dot from 'dot-object';
import {
    readCompanion,
    createCompanion,
    updateCompanion
} from '../actions/entity.companion'
import { userIsReadOnlyInEntity } from '../helpers/user';
import { useTranslation } from 'react-i18next';
import CompanionForm from './components/companionForm.component';
import EntityMedia from './entity.media.page';
import { changeToDateUTC, changeToDayJsObject } from '../helpers/date';
import { getShortToken } from '../actions/auth';

const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 10,
    },
};

function convertDataFromFormToData(data) {
    if (typeof data.memberId !== 'undefined' && data.memberId !== null && typeof data.memberEmail !== 'undefined' && data.memberEmail !== null) {
        data.isMember = true;
    } else {
        data.isMember = false;
    }
    // if (typeof data.passport !== 'undefined' && data.passport !== null) {
    //     if (typeof data.passport.mediaDocuments !== 'undefined' && data.passport.mediaDocuments !== null) {
    //         let mediaDocuments = [];
    //         for (let i in data.passport.mediaDocuments) {
    //             let mediaDocument = data.passport.mediaDocuments[i];
    //             if (typeof mediaDocument._id !== 'undefined' && mediaDocument._id !== null) {
    //                 mediaDocuments.push(`${mediaDocument._id}/${mediaDocument.path}`);
    //             }
    //         }
    //         data.passport.mediaDocuments = mediaDocuments;
    //     }
    // }
    return data;
}

function convertDatesFromFormToData(data) {
    if (typeof data.basic !== 'undefined' && data.basic) {
        if (typeof data.basic.dob !== 'undefined' && data.basic.dob !== null) {
            data.basic.dob = changeToDateUTC(data.basic.dob);
        } else {
            data.basic.dob = null;
        }
    }
    if (typeof data.passport !== 'undefined' && data.passport) {
        let passportDateFieldKeys = ['passportIssuedDate', 'passportExpiredDate', 'chinaRelatedIssuedDate', 'chinaRelatedExpiredDate'];
        for (let i in passportDateFieldKeys) {
            let key = passportDateFieldKeys[i];
            if (typeof data.passport[key] !== 'undefined' && data.passport[key] !== null) {
                data.passport[key] = changeToDateUTC(data.passport[key]);
            } else {
                data.passport[key] = null;
            }
        }
    }
    return data;
}

function convertDatesFromDataToForm(data) {
    if (typeof data.basic !== 'undefined' && data.basic) {
        if (typeof data.basic.dob !== 'undefined' && data.basic.dob !== null) {
            data.basic.dob = changeToDayJsObject(data.basic.dob);
        } else {
            data.basic.dob = null;
        }
    }
    if (typeof data.passport !== 'undefined' && data.passport) {
        let passportDateFieldKeys = ['passportIssuedDate', 'passportExpiredDate', 'chinaRelatedIssuedDate', 'chinaRelatedExpiredDate'];
        for (let i in passportDateFieldKeys) {
            let key = passportDateFieldKeys[i];
            if (typeof data.passport[key] !== 'undefined' && data.passport[key] !== null) {
                data.passport[key] = changeToDayJsObject(data.passport[key]);
            } else {
                data.passport[key] = null;
            }
        }
    }
    return data;
}

function Companion() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const params = useParams();
    const entities = useSelector(state => state.entity.entities);
    const currentEntity = entities ? entities.find(entity => entity.id === params.entityId) : null;
    const { Content } = Layout;
    const [form] = Form.useForm();
    const [companionTitle, setCompanionTitle] = useState('');
    const currentUser = useSelector(state => state.auth.user);
    const userIsReadOnly = userIsReadOnlyInEntity(currentUser, currentEntity);
    const [isMember, setIsMember] = useState(false);
    const [mediaDrawerVisible, setMediaDrawerVisible] = useState(false);
    const [currentMediaFieldName, setCurrentMediaFieldName] = useState(null);
    const [dataSource, setDataSource] = useState(null);
    const [shortToken, setShortToken] = useState(null);

    const mediaLinkRenderer = (props) => {
        console.log('mediaLinkRenderer', props);
        try {
            let fieldName = props.fieldName;
            let concatedFieldName = fieldName.join('.');
            // get the value from the datasource using dot
            let value = dot.pick(concatedFieldName, dataSource);
            return API_USER_MEDIA_URL(value.entity) + value._id + '?token=' + (shortToken ? shortToken : '')
        } catch (e) {
            return '#';
        }
    }

    const showMediaDrawer = (fieldName) => {
        console.log(fieldName);
        setCurrentMediaFieldName(fieldName);
        setMediaDrawerVisible(true);
    };
    const onMediaDrawerClose = () => {
        setMediaDrawerVisible(false);
    };

    const onSelectMedia = (media) => {
        setMediaDrawerVisible(false);
        if (currentMediaFieldName && currentEntity) {
            let url = API_CDN_URL(currentEntity) + media.path;
            let newValue = {
                [currentMediaFieldName.join('.')]: url
            };
            let formValues = form.getFieldsValue();
            // Use dot to update partial values
            newValue = dot.dot(newValue);
            formValues = dot.dot(formValues);
            newValue = { ...formValues, ...newValue };
            newValue = dot.object(newValue);
            form.setFieldsValue(newValue);
        }
    }

    const onFinish = (values) => {
        if (userIsReadOnly) {
            message.error(t('companion.message.read-only'));
            return;
        }
        try {
            values = convertDatesFromFormToData(values);
        } catch (e) {
            console.log(e);
        }
        console.log('values', values);
        if (typeof params.companionId !== 'undefined') {
            // Update the existing companion
            dispatch(updateCompanion(params.entityId, params.companionId, values)).then((response) => {
                if (typeof response.result !== 'undefined' && typeof response.result.id !== 'undefined') {
                    setCompanionTitle(`${t('companion.title.edit')} - ${response.result.id}`);
                    setDataSource(JSON.parse(JSON.stringify(response.result)));
                    let data = convertDatesFromDataToForm(response.result);
                    data = convertDataFromFormToData(data);
                    setIsMember(data.isMember);
                    form.setFieldsValue(data);
                }
                message.success(t('companion.message.update-success'));
            }).catch(error => {
                try {
                    let responseMessage = error.response.data.message;
                    message.error(responseMessage);
                } catch (e) {
                    console.log('Failed:', e);
                    message.error(JSON.stringify(e));
                }
            });
        } else {
            // Create new campaign
            dispatch(createCompanion(params.entityId, values)).then((data) => {
                message.success(t('companion.message.create-success'));
                if (data.result) {
                    navigate(`/entity/${params.entityId}/companion/${data.result.id}`);
                } else {
                    navigate(`/entity/${params.entityId}/companions`);
                }
            }).catch(error => {
                try {
                    let responseMessage = error.response.data.message;
                    message.error(responseMessage);
                } catch (e) {
                    console.log('Failed:', e);
                    message.error(JSON.stringify(e));
                }
            });
        }
    }

    useEffect(() => {
        dispatch(getShortToken()).then((data) => {
            if (data && data.shortToken) {
                setShortToken(data.shortToken);
            }
        }).catch(e => {
            console.log(e.message);
        });
        if (typeof params.companionId !== 'undefined') {
            setCompanionTitle(t('companion.title.edit'));
            dispatch(readCompanion(params.entityId, params.companionId)).then((response) => {
                if (typeof response.result !== 'undefined' && typeof response.result.id !== 'undefined') {
                    setCompanionTitle(`${t('companion.title.edit')} - ${response.result.id}`);
                    setDataSource(JSON.parse(JSON.stringify(response.result)));
                    let data = convertDatesFromDataToForm(response.result);
                    data = convertDataFromFormToData(data);
                    setIsMember(data.isMember);
                    form.setFieldsValue(data);
                } else {
                    message.error(t('companion.message.error-reading'));
                    navigate(`/entity/${params.entityId}/companions`);
                }
            }).catch(e => {
                console.log(e);
                message.error(t('companion.message.error-reading'));
                navigate(`/entity/${params.entityId}/companions`);
            });
        } else {
            setCompanionTitle(t('companion.title.new'));
        }
    }, [params.companionId, params.entityId, dispatch, navigate, form]);

    const onFinishFailed = (errorInfo) => {
        if (typeof errorInfo.errorFields !== 'undefined') {
            errorInfo.errorFields.forEach(field => {
                message.error(field.errors[0]);
            });
        }
    }

    const isMemberAction = (checked) => {
        setIsMember(checked);
    }

    let extraButtons = [
        <Button key="cancel" type="primary" danger href={`/entity/${params.entityId}/companions`}>{t('companion.actions.cancel')}</Button>
    ];
    if (!userIsReadOnly) {
        extraButtons.push(<Button key="save" type="primary" htmlType="submit">{params.companionId ? t('companion.actions.update') : t('companion.actions.save')}</Button>);
    }
    return <>
        <MemberSidebar />
        <Layout className="site-layout">
            <Content style={{ margin: '0 16px' }}>
                <div className="site-layout-background">
                    <Form
                        {...layout}
                        form={form}
                        name="entity-companion"
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        initialValues={{
                            keyIsVerified: true,
                            status: true,
                            // hasSentInvitation: false,
                            // needChangePassword: true
                        }}>
                        <PageHeader
                            className="site-page-header"
                            onBack={() => navigate(`/entity/${params.entityId}/companions`)}
                            title={companionTitle}
                            extra={extraButtons}
                        />
                        <>
                            <Form.Item
                                label={t('companion.field.primary.label')}
                                name="primary"
                                valuePropName="checked"
                            >
                                <Switch />
                            </Form.Item>
                            <Form.Item
                                name="user"
                                label={t('companion.field.user.label')}
                            >
                                {params.companionId ? (dataSource && dataSource.user) : <Input placeholder={t('companion.field.user.label')} />}
                            </Form.Item>
                            <Form.Item
                                label={t('companion.field.isMember.label')}
                                name="isMember"
                                valuePropName="checked"
                            >
                                <Switch onChange={isMemberAction} />
                            </Form.Item>
                            {isMember && <>
                                <Form.Item
                                    label={t('companion.field.memberId.label')}
                                    name="memberId"
                                    required={true}
                                    rules={[
                                        {
                                            required: true,
                                            message: t('companion.field.memberId.required-message'),
                                        }
                                    ]}

                                >
                                    <Input placeholder={t('companion.field.memberId.placeholder')} />
                                </Form.Item>
                                <Form.Item
                                    label={t('companion.field.memberEmail.label')}
                                    name="memberEmail"
                                    required={true}
                                    rules={[
                                        {
                                            required: true,
                                            message: t('companion.field.memberEmail.required-message'),
                                        },
                                        {
                                            type: 'email',
                                            message: t('companion.field.email.invalid-message'),
                                        }
                                    ]}
                                >
                                    <Input placeholder={t('companion.field.memberEmail.placeholder')} />
                                </Form.Item>
                            </>}
                            {!isMember && <CompanionForm mediaLinkRenderer={mediaLinkRenderer} form={form} showMediaDrawer={showMediaDrawer} t={t} />}
                        </>
                    </Form>
                    <Drawer contentWrapperStyle={{ width: '1000px', maxWidth: '100%', minWidth: '50%' }} title={t('mediaDrawer.title')} placement="right" onClose={onMediaDrawerClose} open={mediaDrawerVisible}>
                        <EntityMedia entityId={params.entityId} selectMedia={onSelectMedia} />
                    </Drawer>
                </div>
            </Content>
            <MemberFooter />
        </Layout >
    </>;
}

export default Companion;