import TripTagService from "../services/entity.trip.tag.service";

// Action to get all active entity trip tag translations by entity id
export const readAllActiveTripTagsTranslations = (entityId) => (dispatch) => {
    return TripTagService.readAllActiveTripTagsTranslations(entityId).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to get entity by id
export const readTripTag = (entityId, id) => (dispatch) => {
    return TripTagService.readTripTag(entityId, id).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to get all entity trip tags
export const readTripTags = (entityId) => (dispatch) => {
    return TripTagService.readTripTags(entityId).then((data) => {
        // if (data.result) {
        //     dispatch({
        //         type: READ_ENTITY_REGIONS_SUCCESS,
        //         payload: data.result
        //     });
        // }
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to create the entity trip tag
export const createTripTag = (entityId, tripTag) => (dispatch) => {
    return TripTagService.createTripTag(entityId, tripTag).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        console.log(error.message);
        return Promise.reject(error);
    });
}

// Action to update the entity trip tag
export const updateTripTag = (entityId, id, entityTripTag) => (dispatch) => {
    return TripTagService.updateTripTag(entityId, id, entityTripTag).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to delete the entity trip tag
export const deleteTripTag = (entityId, id) => (dispatch) => {
    return TripTagService.deleteTripTag(entityId, id).then((data) => {
        // dispatch({
        //     type: DELETE_ENTITY_REGION_SUCCESS,
        //     payload: id
        // });
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}