import { Form, Input, Select, DatePicker, Button, Switch } from "antd";
import { MinusCircleOutlined, PlusOutlined, UpCircleOutlined, DownCircleOutlined } from '@ant-design/icons';
import MediaSelect from "./mediaSelect.field";
import { useState } from "react";

const nationalityOptions = [
    {
        "iso2": "HK",
        "iso3": "HKG",
        "label": {
            "en": "HKSAR",
            "zh": "香港特別行政區"
        },
        "value": "hksar"
    },
    {
        "iso2": "GB",
        "iso3": "GBN",
        "label": {
            "en": "British Nationals (Oversea)",
            "zh": "英國國民（海外）"
        },
        "value": "british-nationals-oversea"
    },
    {
        "iso2": "MO",
        "iso3": "MAC",
        "label": {
            "en": "Macao SAR",
            "zh": "澳門特別行政區"
        },
        "value": "macao-sar"
    },
    {
        "iso2": "AU",
        "iso3": "AUS",
        "label": {
            "en": "Australia",
            "zh": "澳洲"
        },
        "value": "australia"
    },
    {
        "iso2": "CA",
        "iso3": "CAN",
        "label": {
            "en": "Canada",
            "zh": "加拿大"
        },
        "value": "canada"
    },
    {
        "iso2": "CN",
        "iso3": "CHN",
        "label": {
            "en": "China",
            "zh": "中國"
        },
        "value": "china"
    },
    {
        "iso2": "SG",
        "iso3": "SGP",
        "label": {
            "en": "Republic of Singapore",
            "zh": "新加坡"
        },
        "value": "republic-of-singapore"
    },
    {
        "iso2": "US",
        "iso3": "USA",
        "label": {
            "en": "United States of America",
            "zh": "美國"
        },
        "value": "united-states-of-america"
    },
    {
        "iso2": "GB",
        "iso3": "GBR",
        "label": {
            "en": "United Kingdom of Great Britain and Northern Ireland",
            "zh": "大不列顛及北愛爾蘭聯合王國"
        },
        "value": "united-kingdom-of-great-britain-and-northern-ireland"
    },
    {
        "iso2": "AF",
        "iso3": "AFG",
        "label": {
            "en": "Afghanistan",
            "zh": "阿富汗"
        },
        "value": "afghanistan"
    },
    {
        "iso2": "AL",
        "iso3": "ALB",
        "label": {
            "en": "Albania",
            "zh": "阿爾巴尼亞"
        },
        "value": "albania"
    },
    {
        "iso2": "DZ",
        "iso3": "DZA",
        "label": {
            "en": "Algeria",
            "zh": "阿爾及利亞"
        },
        "value": "algeria"
    },
    {
        "iso2": "AD",
        "iso3": "AND",
        "label": {
            "en": "Andorra",
            "zh": "安道爾"
        },
        "value": "andorra"
    },
    {
        "iso2": "AO",
        "iso3": "AGO",
        "label": {
            "en": "Angola",
            "zh": "安哥拉"
        },
        "value": "angola"
    },
    {
        "iso2": "AG",
        "iso3": "ATG",
        "label": {
            "en": "Antigua and Barbuda",
            "zh": "安地卡及巴布達"
        },
        "value": "antigua-and-barbuda"
    },
    {
        "iso2": "AR",
        "iso3": "ARG",
        "label": {
            "en": "Argentina",
            "zh": "阿根廷"
        },
        "value": "argentina"
    },
    {
        "iso2": "AM",
        "iso3": "ARM",
        "label": {
            "en": "Armenia",
            "zh": "亞美尼亞"
        },
        "value": "armenia"
    },
    {
        "iso2": "AU",
        "iso3": "AUS",
        "label": {
            "en": "Australia",
            "zh": "澳洲"
        },
        "value": "australia"
    },
    {
        "iso2": "AT",
        "iso3": "AUT",
        "label": {
            "en": "Austria",
            "zh": "奧地利"
        },
        "value": "austria"
    },
    {
        "iso2": "AZ",
        "iso3": "AZE",
        "label": {
            "en": "Azerbaijan",
            "zh": "亞塞拜然"
        },
        "value": "azerbaijan"
    },
    {
        "iso2": "BS",
        "iso3": "BHS",
        "label": {
            "en": "Bahamas",
            "zh": "巴哈馬"
        },
        "value": "bahamas"
    },
    {
        "iso2": "BH",
        "iso3": "BHR",
        "label": {
            "en": "Bahrain",
            "zh": "巴林"
        },
        "value": "bahrain"
    },
    {
        "iso2": "BD",
        "iso3": "BGD",
        "label": {
            "en": "Bangladesh",
            "zh": "孟加拉"
        },
        "value": "bangladesh"
    },
    {
        "iso2": "BB",
        "iso3": "BRB",
        "label": {
            "en": "Barbados",
            "zh": "巴貝多"
        },
        "value": "barbados"
    },
    {
        "iso2": "BY",
        "iso3": "BLR",
        "label": {
            "en": "Belarus",
            "zh": "白俄羅斯"
        },
        "value": "belarus"
    },
    {
        "iso2": "BE",
        "iso3": "BEL",
        "label": {
            "en": "Belgium",
            "zh": "比利時"
        },
        "value": "belgium"
    },
    {
        "iso2": "BZ",
        "iso3": "BLZ",
        "label": {
            "en": "Belize",
            "zh": "貝里斯"
        },
        "value": "belize"
    },
    {
        "iso2": "BJ",
        "iso3": "BEN",
        "label": {
            "en": "Benin",
            "zh": "貝南"
        },
        "value": "benin"
    },
    {
        "iso2": "BT",
        "iso3": "BTN",
        "label": {
            "en": "Bhutan",
            "zh": "不丹"
        },
        "value": "bhutan"
    },
    {
        "iso2": "VE",
        "iso3": "VEN",
        "label": {
            "en": "Bolivarian Republic of Venezuela",
            "zh": "委內瑞拉玻利瓦爾共和國"
        },
        "value": "bolivarian-republic-of-venezuela"
    },
    {
        "iso2": "BA",
        "iso3": "BIH",
        "label": {
            "en": "Bosnia and Herzegovina",
            "zh": "波士尼亞與赫塞哥維納"
        },
        "value": "bosnia-and-herzegovina"
    },
    {
        "iso2": "BW",
        "iso3": "BWA",
        "label": {
            "en": "Botswana",
            "zh": "波札那"
        },
        "value": "botswana"
    },
    {
        "iso2": "BR",
        "iso3": "BRA",
        "label": {
            "en": "Brazil",
            "zh": "巴西"
        },
        "value": "brazil"
    },
    {
        "iso2": "BN",
        "iso3": "BRN",
        "label": {
            "en": "Brunei Darussalam",
            "zh": "汶萊達魯薩蘭國"
        },
        "value": "brunei-darussalam"
    },
    {
        "iso2": "BG",
        "iso3": "BGR",
        "label": {
            "en": "Bulgaria",
            "zh": "保加利亞"
        },
        "value": "bulgaria"
    },
    {
        "iso2": "BF",
        "iso3": "BFA",
        "label": {
            "en": "Burkina Faso",
            "zh": "布吉納法索"
        },
        "value": "burkina-faso"
    },
    {
        "iso2": "BI",
        "iso3": "BDI",
        "label": {
            "en": "Burundi",
            "zh": "蒲隆地"
        },
        "value": "burundi"
    },
    {
        "iso2": "CV",
        "iso3": "CPV",
        "label": {
            "en": "Cabo Verde",
            "zh": "維德角"
        },
        "value": "cabo-verde"
    },
    {
        "iso2": "KH",
        "iso3": "KHM",
        "label": {
            "en": "Cambodia",
            "zh": "柬埔寨"
        },
        "value": "cambodia"
    },
    {
        "iso2": "CM",
        "iso3": "CMR",
        "label": {
            "en": "Cameroon",
            "zh": "喀麥隆"
        },
        "value": "cameroon"
    },
    {
        "iso2": "CA",
        "iso3": "CAN",
        "label": {
            "en": "Canada",
            "zh": "加拿大"
        },
        "value": "canada"
    },
    {
        "iso2": "CF",
        "iso3": "CAF",
        "label": {
            "en": "Central African Republic",
            "zh": "中非共和國"
        },
        "value": "central-african-republic"
    },
    {
        "iso2": "TD",
        "iso3": "TCD",
        "label": {
            "en": "Chad",
            "zh": "查德"
        },
        "value": "chad"
    },
    {
        "iso2": "CL",
        "iso3": "CHL",
        "label": {
            "en": "Chile",
            "zh": "智利"
        },
        "value": "chile"
    },
    {
        "iso2": "CO",
        "iso3": "COL",
        "label": {
            "en": "Colombia",
            "zh": "哥倫比亞"
        },
        "value": "colombia"
    },
    {
        "iso2": "KM",
        "iso3": "COM",
        "label": {
            "en": "Comoros",
            "zh": "葛摩"
        },
        "value": "comoros"
    },
    {
        "iso2": "CG",
        "iso3": "COG",
        "label": {
            "en": "Congo",
            "zh": "剛果"
        },
        "value": "congo"
    },
    {
        "iso2": "CR",
        "iso3": "CRI",
        "label": {
            "en": "Costa Rica",
            "zh": "哥斯大黎加"
        },
        "value": "costa-rica"
    },
    {
        "iso2": "HR",
        "iso3": "HRV",
        "label": {
            "en": "Croatia",
            "zh": "克羅埃西亞"
        },
        "value": "croatia"
    },
    {
        "iso2": "CU",
        "iso3": "CUB",
        "label": {
            "en": "Cuba",
            "zh": "古巴"
        },
        "value": "cuba"
    },
    {
        "iso2": "CY",
        "iso3": "CYP",
        "label": {
            "en": "Cyprus",
            "zh": "賽普勒斯"
        },
        "value": "cyprus"
    },
    {
        "iso2": "CZ",
        "iso3": "CZE",
        "label": {
            "en": "Czechia",
            "zh": "捷克"
        },
        "value": "czechia"
    },
    {
        "iso2": "CI",
        "iso3": "CIV",
        "label": {
            "en": "Côte d'Ivoire",
            "zh": "科特迪瓦"
        },
        "value": "cote-d-ivoire"
    },
    {
        "iso2": "KP",
        "iso3": "PRK",
        "label": {
            "en": "Democratic People's Republic of Korea",
            "zh": "朝鮮民主主義人民共和國"
        },
        "value": "democratic-people-s-republic-of-korea"
    },
    {
        "iso2": "CD",
        "iso3": "COD",
        "label": {
            "en": "Democratic Republic of the Congo",
            "zh": "剛果民主共和國"
        },
        "value": "democratic-republic-of-the-congo"
    },
    {
        "iso2": "DK",
        "iso3": "DNK",
        "label": {
            "en": "Denmark",
            "zh": "丹麥"
        },
        "value": "denmark"
    },
    {
        "iso2": "DJ",
        "iso3": "DJI",
        "label": {
            "en": "Djibouti",
            "zh": "吉布地"
        },
        "value": "djibouti"
    },
    {
        "iso2": "DM",
        "iso3": "DMA",
        "label": {
            "en": "Dominica",
            "zh": "多明尼加"
        },
        "value": "dominica"
    },
    {
        "iso2": "DO",
        "iso3": "DOM",
        "label": {
            "en": "Dominican Republic",
            "zh": "多明尼加共和國"
        },
        "value": "dominican-republic"
    },
    {
        "iso2": "EC",
        "iso3": "ECU",
        "label": {
            "en": "Ecuador",
            "zh": "厄瓜多"
        },
        "value": "ecuador"
    },
    {
        "iso2": "EG",
        "iso3": "EGY",
        "label": {
            "en": "Egypt",
            "zh": "埃及"
        },
        "value": "egypt"
    },
    {
        "iso2": "SV",
        "iso3": "SLV",
        "label": {
            "en": "El Salvador",
            "zh": "薩爾瓦多"
        },
        "value": "el-salvador"
    },
    {
        "iso2": "GQ",
        "iso3": "GNQ",
        "label": {
            "en": "Equatorial Guinea",
            "zh": "赤道幾內亞"
        },
        "value": "equatorial-guinea"
    },
    {
        "iso2": "ER",
        "iso3": "ERI",
        "label": {
            "en": "Eritrea",
            "zh": "厄利垂亞"
        },
        "value": "eritrea"
    },
    {
        "iso2": "EE",
        "iso3": "EST",
        "label": {
            "en": "Estonia",
            "zh": "愛沙尼亞"
        },
        "value": "estonia"
    },
    {
        "iso2": "SZ",
        "iso3": "SWZ",
        "label": {
            "en": "Eswatini",
            "zh": "史瓦帝尼"
        },
        "value": "eswatini"
    },
    {
        "iso2": "ET",
        "iso3": "ETH",
        "label": {
            "en": "Ethiopia",
            "zh": "衣索比亞"
        },
        "value": "ethiopia"
    },
    {
        "iso2": "FM",
        "iso3": "FSM",
        "label": {
            "en": "Federated States of Micronesia",
            "zh": "密克羅尼西亞聯邦"
        },
        "value": "federated-states-of-micronesia"
    },
    {
        "iso2": "FJ",
        "iso3": "FJI",
        "label": {
            "en": "Fiji",
            "zh": "斐濟"
        },
        "value": "fiji"
    },
    {
        "iso2": "FI",
        "iso3": "FIN",
        "label": {
            "en": "Finland",
            "zh": "芬蘭"
        },
        "value": "finland"
    },
    {
        "iso2": "FR",
        "iso3": "FRA",
        "label": {
            "en": "France",
            "zh": "法國"
        },
        "value": "france"
    },
    {
        "iso2": "GA",
        "iso3": "GAB",
        "label": {
            "en": "Gabon",
            "zh": "加彭"
        },
        "value": "gabon"
    },
    {
        "iso2": "GM",
        "iso3": "GMB",
        "label": {
            "en": "Gambia",
            "zh": "甘比亞"
        },
        "value": "gambia"
    },
    {
        "iso2": "GE",
        "iso3": "GEO",
        "label": {
            "en": "Georgia",
            "zh": "喬治亞州"
        },
        "value": "georgia"
    },
    {
        "iso2": "DE",
        "iso3": "DEU",
        "label": {
            "en": "Germany",
            "zh": "德國"
        },
        "value": "germany"
    },
    {
        "iso2": "GH",
        "iso3": "GHA",
        "label": {
            "en": "Ghana",
            "zh": "迦納"
        },
        "value": "ghana"
    },
    {
        "iso2": "GR",
        "iso3": "GRC",
        "label": {
            "en": "Greece",
            "zh": "希臘"
        },
        "value": "greece"
    },
    {
        "iso2": "GD",
        "iso3": "GRD",
        "label": {
            "en": "Grenada",
            "zh": "格瑞那達"
        },
        "value": "grenada"
    },
    {
        "iso2": "GT",
        "iso3": "GTM",
        "label": {
            "en": "Guatemala",
            "zh": "瓜地馬拉"
        },
        "value": "guatemala"
    },
    {
        "iso2": "GN",
        "iso3": "GIN",
        "label": {
            "en": "Guinea",
            "zh": "幾內亞"
        },
        "value": "guinea"
    },
    {
        "iso2": "GW",
        "iso3": "GNB",
        "label": {
            "en": "Guinea-Bissau",
            "zh": "幾內亞比紹"
        },
        "value": "guinea-bissau"
    },
    {
        "iso2": "GY",
        "iso3": "GUY",
        "label": {
            "en": "Guyana",
            "zh": "蓋亞那"
        },
        "value": "guyana"
    },
    {
        "iso2": "HT",
        "iso3": "HTI",
        "label": {
            "en": "Haiti",
            "zh": "海地"
        },
        "value": "haiti"
    },
    {
        "iso2": "HN",
        "iso3": "HND",
        "label": {
            "en": "Honduras",
            "zh": "宏都拉斯"
        },
        "value": "honduras"
    },
    {
        "iso2": "HU",
        "iso3": "HUN",
        "label": {
            "en": "Hungary",
            "zh": "匈牙利"
        },
        "value": "hungary"
    },
    {
        "iso2": "IS",
        "iso3": "ISL",
        "label": {
            "en": "Iceland",
            "zh": "冰島"
        },
        "value": "iceland"
    },
    {
        "iso2": "IN",
        "iso3": "IND",
        "label": {
            "en": "India",
            "zh": "印度"
        },
        "value": "india"
    },
    {
        "iso2": "ID",
        "iso3": "IDN",
        "label": {
            "en": "Indonesia",
            "zh": "印尼"
        },
        "value": "indonesia"
    },
    {
        "iso2": "IQ",
        "iso3": "IRQ",
        "label": {
            "en": "Iraq",
            "zh": "伊拉克"
        },
        "value": "iraq"
    },
    {
        "iso2": "IE",
        "iso3": "IRL",
        "label": {
            "en": "Ireland",
            "zh": "愛爾蘭"
        },
        "value": "ireland"
    },
    {
        "iso2": "IR",
        "iso3": "IRN",
        "label": {
            "en": "Islamic Republic of Iran",
            "zh": "伊朗伊斯蘭共和國"
        },
        "value": "islamic-republic-of-iran"
    },
    {
        "iso2": "IL",
        "iso3": "ISR",
        "label": {
            "en": "Israel",
            "zh": "以色列"
        },
        "value": "israel"
    },
    {
        "iso2": "IT",
        "iso3": "ITA",
        "label": {
            "en": "Italy",
            "zh": "義大利"
        },
        "value": "italy"
    },
    {
        "iso2": "JM",
        "iso3": "JAM",
        "label": {
            "en": "Jamaica",
            "zh": "牙買加"
        },
        "value": "jamaica"
    },
    {
        "iso2": "JP",
        "iso3": "JPN",
        "label": {
            "en": "Japan",
            "zh": "日本"
        },
        "value": "japan"
    },
    {
        "iso2": "JO",
        "iso3": "JOR",
        "label": {
            "en": "Jordan",
            "zh": "約旦"
        },
        "value": "jordan"
    },
    {
        "iso2": "KZ",
        "iso3": "KAZ",
        "label": {
            "en": "Kazakhstan[q]",
            "zh": "哈薩克[q]"
        },
        "value": "kazakhstan-q"
    },
    {
        "iso2": "KE",
        "iso3": "KEN",
        "label": {
            "en": "Kenya",
            "zh": "肯亞"
        },
        "value": "kenya"
    },
    {
        "iso2": "KI",
        "iso3": "KIR",
        "label": {
            "en": "Kiribati",
            "zh": "吉里巴斯"
        },
        "value": "kiribati"
    },
    {
        "iso2": "KW",
        "iso3": "KWT",
        "label": {
            "en": "Kuwait",
            "zh": "科威特"
        },
        "value": "kuwait"
    },
    {
        "iso2": "KG",
        "iso3": "KGZ",
        "label": {
            "en": "Kyrgyzstan",
            "zh": "吉爾吉斯斯坦"
        },
        "value": "kyrgyzstan"
    },
    {
        "iso2": "LA",
        "iso3": "LAO",
        "label": {
            "en": "Lao People's Democratic Republic",
            "zh": "寮國人民民主共和國"
        },
        "value": "lao-people-s-democratic-republic"
    },
    {
        "iso2": "LV",
        "iso3": "LVA",
        "label": {
            "en": "Latvia",
            "zh": "拉脫維亞"
        },
        "value": "latvia"
    },
    {
        "iso2": "LB",
        "iso3": "LBN",
        "label": {
            "en": "Lebanon",
            "zh": "黎巴嫩"
        },
        "value": "lebanon"
    },
    {
        "iso2": "LS",
        "iso3": "LSO",
        "label": {
            "en": "Lesotho",
            "zh": "賴索托"
        },
        "value": "lesotho"
    },
    {
        "iso2": "LR",
        "iso3": "LBR",
        "label": {
            "en": "Liberia",
            "zh": "賴比瑞亞"
        },
        "value": "liberia"
    },
    {
        "iso2": "LY",
        "iso3": "LBY",
        "label": {
            "en": "Libya[s]",
            "zh": "利比亞[s]"
        },
        "value": "libya-s"
    },
    {
        "iso2": "LI",
        "iso3": "LIE",
        "label": {
            "en": "Liechtenstein",
            "zh": "列支敦斯登"
        },
        "value": "liechtenstein"
    },
    {
        "iso2": "LT",
        "iso3": "LTU",
        "label": {
            "en": "Lithuania",
            "zh": "立陶宛"
        },
        "value": "lithuania"
    },
    {
        "iso2": "LU",
        "iso3": "LUX",
        "label": {
            "en": "Luxembourg",
            "zh": "盧森堡"
        },
        "value": "luxembourg"
    },
    {
        "iso2": "MG",
        "iso3": "MDG",
        "label": {
            "en": "Madagascar",
            "zh": "馬達加斯加"
        },
        "value": "madagascar"
    },
    {
        "iso2": "MW",
        "iso3": "MWI",
        "label": {
            "en": "Malawi",
            "zh": "馬拉威"
        },
        "value": "malawi"
    },
    {
        "iso2": "MY",
        "iso3": "MYS",
        "label": {
            "en": "Malaysia",
            "zh": "馬來西亞"
        },
        "value": "malaysia"
    },
    {
        "iso2": "MV",
        "iso3": "MDV",
        "label": {
            "en": "Maldives",
            "zh": "馬爾地夫"
        },
        "value": "maldives"
    },
    {
        "iso2": "ML",
        "iso3": "MLI",
        "label": {
            "en": "Mali",
            "zh": "馬裡"
        },
        "value": "mali"
    },
    {
        "iso2": "MT",
        "iso3": "MLT",
        "label": {
            "en": "Malta",
            "zh": "馬爾他"
        },
        "value": "malta"
    },
    {
        "iso2": "MH",
        "iso3": "MHL",
        "label": {
            "en": "Marshall Islands",
            "zh": "馬紹爾群島"
        },
        "value": "marshall-islands"
    },
    {
        "iso2": "MR",
        "iso3": "MRT",
        "label": {
            "en": "Mauritania",
            "zh": "茅利塔尼亞"
        },
        "value": "mauritania"
    },
    {
        "iso2": "MU",
        "iso3": "MUS",
        "label": {
            "en": "Mauritius",
            "zh": "模里西斯"
        },
        "value": "mauritius"
    },
    {
        "iso2": "MX",
        "iso3": "MEX",
        "label": {
            "en": "Mexico",
            "zh": "墨西哥"
        },
        "value": "mexico"
    },
    {
        "iso2": "MC",
        "iso3": "MCO",
        "label": {
            "en": "Monaco",
            "zh": "摩納哥"
        },
        "value": "monaco"
    },
    {
        "iso2": "MN",
        "iso3": "MNG",
        "label": {
            "en": "Mongolia",
            "zh": "蒙古"
        },
        "value": "mongolia"
    },
    {
        "iso2": "ME",
        "iso3": "MNE",
        "label": {
            "en": "Montenegro",
            "zh": "蒙特內哥羅"
        },
        "value": "montenegro"
    },
    {
        "iso2": "MA",
        "iso3": "MAR",
        "label": {
            "en": "Morocco",
            "zh": "摩洛哥"
        },
        "value": "morocco"
    },
    {
        "iso2": "MZ",
        "iso3": "MOZ",
        "label": {
            "en": "Mozambique",
            "zh": "莫三比克"
        },
        "value": "mozambique"
    },
    {
        "iso2": "MM",
        "iso3": "MMR",
        "label": {
            "en": "Myanmar",
            "zh": "緬甸"
        },
        "value": "myanmar"
    },
    {
        "iso2": "NA",
        "iso3": "NAM",
        "label": {
            "en": "Namibia",
            "zh": "納米比亞"
        },
        "value": "namibia"
    },
    {
        "iso2": "NR",
        "iso3": "NRU",
        "label": {
            "en": "Nauru",
            "zh": "諾魯"
        },
        "value": "nauru"
    },
    {
        "iso2": "NP",
        "iso3": "NPL",
        "label": {
            "en": "Nepal",
            "zh": "尼泊爾"
        },
        "value": "nepal"
    },
    {
        "iso2": "NL",
        "iso3": "NLD",
        "label": {
            "en": "Netherlands",
            "zh": "荷蘭"
        },
        "value": "netherlands"
    },
    {
        "iso2": "NZ",
        "iso3": "NZL",
        "label": {
            "en": "New Zealand",
            "zh": "紐西蘭"
        },
        "value": "new-zealand"
    },
    {
        "iso2": "NI",
        "iso3": "NIC",
        "label": {
            "en": "Nicaragua",
            "zh": "尼加拉瓜"
        },
        "value": "nicaragua"
    },
    {
        "iso2": "NE",
        "iso3": "NER",
        "label": {
            "en": "Niger",
            "zh": "尼日"
        },
        "value": "niger"
    },
    {
        "iso2": "NG",
        "iso3": "NGA",
        "label": {
            "en": "Nigeria",
            "zh": "奈及利亞"
        },
        "value": "nigeria"
    },
    {
        "iso2": "MK",
        "iso3": "MKD",
        "label": {
            "en": "North Macedonia",
            "zh": "北馬其頓"
        },
        "value": "north-macedonia"
    },
    {
        "iso2": "NO",
        "iso3": "NOR",
        "label": {
            "en": "Norway",
            "zh": "挪威"
        },
        "value": "norway"
    },
    {
        "iso2": "OM",
        "iso3": "OMN",
        "label": {
            "en": "Oman",
            "zh": "阿曼"
        },
        "value": "oman"
    },
    {
        "iso2": "PK",
        "iso3": "PAK",
        "label": {
            "en": "Pakistan",
            "zh": "巴基斯坦"
        },
        "value": "pakistan"
    },
    {
        "iso2": "PW",
        "iso3": "PLW",
        "label": {
            "en": "Palau",
            "zh": "帛琉"
        },
        "value": "palau"
    },
    {
        "iso2": "PA",
        "iso3": "PAN",
        "label": {
            "en": "Panama",
            "zh": "巴拿馬"
        },
        "value": "panama"
    },
    {
        "iso2": "PG",
        "iso3": "PNG",
        "label": {
            "en": "Papua New Guinea",
            "zh": "巴布亞紐幾內亞"
        },
        "value": "papua-new-guinea"
    },
    {
        "iso2": "PY",
        "iso3": "PRY",
        "label": {
            "en": "Paraguay",
            "zh": "巴拉圭"
        },
        "value": "paraguay"
    },
    {
        "iso2": "PE",
        "iso3": "PER",
        "label": {
            "en": "Peru",
            "zh": "秘魯"
        },
        "value": "peru"
    },
    {
        "iso2": "PH",
        "iso3": "PHL",
        "label": {
            "en": "Philippines",
            "zh": "菲律賓"
        },
        "value": "philippines"
    },
    {
        "iso2": "BO",
        "iso3": "BOL",
        "label": {
            "en": "Plurinational State of Bolivia",
            "zh": "玻利維亞多民族國"
        },
        "value": "plurinational-state-of-bolivia"
    },
    {
        "iso2": "PL",
        "iso3": "POL",
        "label": {
            "en": "Poland",
            "zh": "波蘭"
        },
        "value": "poland"
    },
    {
        "iso2": "PT",
        "iso3": "PRT",
        "label": {
            "en": "Portugal",
            "zh": "葡萄牙"
        },
        "value": "portugal"
    },
    {
        "iso2": "QA",
        "iso3": "QAT",
        "label": {
            "en": "Qatar",
            "zh": "卡達"
        },
        "value": "qatar"
    },
    {
        "iso2": "KR",
        "iso3": "KOR",
        "label": {
            "en": "Republic of Korea",
            "zh": "大韓民國"
        },
        "value": "republic-of-korea"
    },
    {
        "iso2": "MD",
        "iso3": "MDA",
        "label": {
            "en": "Republic of Moldova",
            "zh": "摩爾多瓦共和國"
        },
        "value": "republic-of-moldova"
    },
    {
        "iso2": "RO",
        "iso3": "ROU",
        "label": {
            "en": "Romania",
            "zh": "羅馬尼亞"
        },
        "value": "romania"
    },
    {
        "iso2": "RU",
        "iso3": "RUS",
        "label": {
            "en": "Russian Federation",
            "zh": "俄羅斯聯邦"
        },
        "value": "russian-federation"
    },
    {
        "iso2": "RW",
        "iso3": "RWA",
        "label": {
            "en": "Rwanda",
            "zh": "盧安達"
        },
        "value": "rwanda"
    },
    {
        "iso2": "KN",
        "iso3": "KNA",
        "label": {
            "en": "Saint Kitts and Nevis",
            "zh": "聖克里斯多福及尼維斯"
        },
        "value": "saint-kitts-and-nevis"
    },
    {
        "iso2": "LC",
        "iso3": "LCA",
        "label": {
            "en": "Saint Lucia",
            "zh": "聖露西亞"
        },
        "value": "saint-lucia"
    },
    {
        "iso2": "VC",
        "iso3": "VCT",
        "label": {
            "en": "Saint Vincent and the Grenadines",
            "zh": "聖文森及格瑞那丁"
        },
        "value": "saint-vincent-and-the-grenadines"
    },
    {
        "iso2": "WS",
        "iso3": "WSM",
        "label": {
            "en": "Samoa",
            "zh": "薩摩亞"
        },
        "value": "samoa"
    },
    {
        "iso2": "SM",
        "iso3": "SMR",
        "label": {
            "en": "San Marino",
            "zh": "聖馬利諾"
        },
        "value": "san-marino"
    },
    {
        "iso2": "SA",
        "iso3": "SAU",
        "label": {
            "en": "Saudi Arabia",
            "zh": "沙烏地阿拉伯"
        },
        "value": "saudi-arabia"
    },
    {
        "iso2": "SN",
        "iso3": "SEN",
        "label": {
            "en": "Senegal",
            "zh": "塞內加爾"
        },
        "value": "senegal"
    },
    {
        "iso2": "RS",
        "iso3": "SRB",
        "label": {
            "en": "Serbia",
            "zh": "塞爾維亞"
        },
        "value": "serbia"
    },
    {
        "iso2": "SC",
        "iso3": "SYC",
        "label": {
            "en": "Seychelles",
            "zh": "塞席爾"
        },
        "value": "seychelles"
    },
    {
        "iso2": "SL",
        "iso3": "SLE",
        "label": {
            "en": "Sierra Leone",
            "zh": "獅子山"
        },
        "value": "sierra-leone"
    },
    {
        "iso2": "SK",
        "iso3": "SVK",
        "label": {
            "en": "Slovakia",
            "zh": "斯洛伐克"
        },
        "value": "slovakia"
    },
    {
        "iso2": "SI",
        "iso3": "SVN",
        "label": {
            "en": "Slovenia",
            "zh": "斯洛維尼亞"
        },
        "value": "slovenia"
    },
    {
        "iso2": "SB",
        "iso3": "SLB",
        "label": {
            "en": "Solomon Islands",
            "zh": "索羅門群島"
        },
        "value": "solomon-islands"
    },
    {
        "iso2": "SO",
        "iso3": "SOM",
        "label": {
            "en": "Somalia",
            "zh": "索馬利亞"
        },
        "value": "somalia"
    },
    {
        "iso2": "ZA",
        "iso3": "ZAF",
        "label": {
            "en": "South Africa",
            "zh": "南非"
        },
        "value": "south-africa"
    },
    {
        "iso2": "SS",
        "iso3": "SSD",
        "label": {
            "en": "South Sudan",
            "zh": "南蘇丹"
        },
        "value": "south-sudan"
    },
    {
        "iso2": "ES",
        "iso3": "ESP",
        "label": {
            "en": "Spain",
            "zh": "西班牙"
        },
        "value": "spain"
    },
    {
        "iso2": "LK",
        "iso3": "LKA",
        "label": {
            "en": "Sri Lanka",
            "zh": "斯里蘭卡"
        },
        "value": "sri-lanka"
    },
    {
        "iso2": "SD",
        "iso3": "SDN",
        "label": {
            "en": "Sudan",
            "zh": "蘇丹"
        },
        "value": "sudan"
    },
    {
        "iso2": "SR",
        "iso3": "SUR",
        "label": {
            "en": "Suriname",
            "zh": "蘇利南"
        },
        "value": "suriname"
    },
    {
        "iso2": "SE",
        "iso3": "SWE",
        "label": {
            "en": "Sweden",
            "zh": "瑞典"
        },
        "value": "sweden"
    },
    {
        "iso2": "CH",
        "iso3": "CHE",
        "label": {
            "en": "Switzerland",
            "zh": "瑞士"
        },
        "value": "switzerland"
    },
    {
        "iso2": "SY",
        "iso3": "SYR",
        "label": {
            "en": "Syrian Arab Republic",
            "zh": "阿拉伯敘利亞共和國"
        },
        "value": "syrian-arab-republic"
    },
    {
        "iso2": "ST",
        "iso3": "STP",
        "label": {
            "en": "São Tomé and Príncipe",
            "zh": "聖多美和普林西比"
        },
        "value": "sao-tome-and-principe"
    },
    {
        "iso2": "TW",
        "iso3": "TWN",
        "label": {
            "en": "Taiwan",
            "zh": "中國台灣"
        },
        "value": "taiwan"
    },
    {
        "iso2": "TJ",
        "iso3": "TJK",
        "label": {
            "en": "Tajikistan",
            "zh": "塔吉克"
        },
        "value": "tajikistan"
    },
    {
        "iso2": "TH",
        "iso3": "THA",
        "label": {
            "en": "Thailand",
            "zh": "泰國"
        },
        "value": "thailand"
    },
    {
        "iso2": "TL",
        "iso3": "TLS",
        "label": {
            "en": "Timor-Leste",
            "zh": "東帝汶"
        },
        "value": "timor-leste"
    },
    {
        "iso2": "TG",
        "iso3": "TGO",
        "label": {
            "en": "Togo",
            "zh": "多哥"
        },
        "value": "togo"
    },
    {
        "iso2": "TO",
        "iso3": "TON",
        "label": {
            "en": "Tonga",
            "zh": "東加"
        },
        "value": "tonga"
    },
    {
        "iso2": "TT",
        "iso3": "TTO",
        "label": {
            "en": "Trinidad and Tobago",
            "zh": "千里達及托巴哥"
        },
        "value": "trinidad-and-tobago"
    },
    {
        "iso2": "TN",
        "iso3": "TUN",
        "label": {
            "en": "Tunisia",
            "zh": "突尼西亞"
        },
        "value": "tunisia"
    },
    {
        "iso2": "TM",
        "iso3": "TUR",
        "label": {
            "en": "Turkmenistan",
            "zh": "土庫曼"
        },
        "value": "turkmenistan"
    },
    {
        "iso2": "TV",
        "iso3": "TKM",
        "label": {
            "en": "Tuvalu",
            "zh": "吐瓦魯"
        },
        "value": "tuvalu"
    },
    {
        "iso2": "TR",
        "iso3": "TUV",
        "label": {
            "en": "Türkiye",
            "zh": "土耳其"
        },
        "value": "turkiye"
    },
    {
        "iso2": "UG",
        "iso3": "UGA",
        "label": {
            "en": "Uganda",
            "zh": "烏幹達"
        },
        "value": "uganda"
    },
    {
        "iso2": "UA",
        "iso3": "UKR",
        "label": {
            "en": "Ukraine",
            "zh": "烏克蘭"
        },
        "value": "ukraine"
    },
    {
        "iso2": "AE",
        "iso3": "ARE",
        "label": {
            "en": "United Arab Emirates",
            "zh": "阿拉伯聯合大公國"
        },
        "value": "united-arab-emirates"
    },
    {
        "iso2": "TZ",
        "iso3": "TZA",
        "label": {
            "en": "United Republic of Tanzania",
            "zh": "坦尚尼亞聯合共和國"
        },
        "value": "united-republic-of-tanzania"
    },
    {
        "iso2": "UY",
        "iso3": "URY",
        "label": {
            "en": "Uruguay",
            "zh": "烏拉圭"
        },
        "value": "uruguay"
    },
    {
        "iso2": "UZ",
        "iso3": "UZB",
        "label": {
            "en": "Uzbekistan",
            "zh": "烏茲別克"
        },
        "value": "uzbekistan"
    },
    {
        "iso2": "VU",
        "iso3": "VUT",
        "label": {
            "en": "Vanuatu",
            "zh": "萬那杜"
        },
        "value": "vanuatu"
    },
    {
        "iso2": "VN",
        "iso3": "VNM",
        "label": {
            "en": "Viet Nam",
            "zh": "越南"
        },
        "value": "viet-nam"
    },
    {
        "iso2": "YE",
        "iso3": "YEM",
        "label": {
            "en": "Yemen",
            "zh": "葉門"
        },
        "value": "yemen"
    },
    {
        "iso2": "ZM",
        "iso3": "ZMB",
        "label": {
            "en": "Zambia",
            "zh": "尚比亞"
        },
        "value": "zambia"
    },
    {
        "iso2": "ZW",
        "iso3": "ZWE",
        "label": {
            "en": "Zimbabwe",
            "zh": "辛巴威"
        },
        "value": "zimbabwe"
    }

];

const phoneRegionOptions = [
    { "country": "Hong Kong", "value": "+852", "label": "+852" },
    { "country": "Macau", "value": "+853", "label": "+853" },
    { "country": "Australia", "value": "+61", "label": "+61" },
    { "country": "Canada", "value": "+1", "label": "+1" },
    { "country": "China", "value": "+86", "label": "+86" },
    { "country": "Singapore", "value": "+65", "label": "+65" },
    { "country": "United States", "value": "+1", "label": "+1" },
    { "country": "United Kingdom", "value": "+44", "label": "+44" },
    { "country": "Isle of Man", "value": "+44-1624", "label": "+44-1624" },
    { "country": "Jersey", "value": "+44-1534", "label": "+44-1534" },
    { "country": "Guernsey", "value": "+44-1481", "label": "+44-1481" },
    { "country": "Jamaica", "value": "+1-876", "label": "+1-876" },
    { "country": "Saint Kitts and Nevis", "value": "+1-869", "label": "+1-869" },
    { "country": "Trinidad and Tobago", "value": "+1-868", "label": "+1-868" },
    { "country": "Dominican Republic", "value": "+1-809", "label": "+1-809" },
    { "country": "Dominican Republic", "value": "+1-829", "label": "+1-829" },
    { "country": "Dominican Republic", "value": "+1-849", "label": "+1-849" },
    { "country": "Puerto Rico", "value": "+1-787", "label": "+1-787" },
    { "country": "Puerto Rico", "value": "+1-939", "label": "+1-939" },
    { "country": "Saint Vincent and the Grenadines", "value": "+1-784", "label": "+1-784" },
    { "country": "Dominica", "value": "+1-767", "label": "+1-767" },
    { "country": "Saint Lucia", "value": "+1-758", "label": "+1-758" },
    { "country": "Sint Maarten", "value": "+1-721", "label": "+1-721" },
    { "country": "American Samoa", "value": "+1-684", "label": "+1-684" },
    { "country": "Guam", "value": "+1-671", "label": "+1-671" },
    { "country": "Northern Mariana Islands", "value": "+1-670", "label": "+1-670" },
    { "country": "Montserrat", "value": "+1-664", "label": "+1-664" },
    { "country": "Turks and Caicos Islands", "value": "+1-649", "label": "+1-649" },
    { "country": "Grenada", "value": "+1-473", "label": "+1-473" },
    { "country": "Bermuda", "value": "+1-441", "label": "+1-441" },
    { "country": "Cayman Islands", "value": "+1-345", "label": "+1-345" },
    { "country": "U.S. Virgin Islands", "value": "+1-340", "label": "+1-340" },
    { "country": "British Virgin Islands", "value": "+1-284", "label": "+1-284" },
    { "country": "Antigua and Barbuda", "value": "+1-268", "label": "+1-268" },
    { "country": "Anguilla", "value": "+1-264", "label": "+1-264" },
    { "country": "Barbados", "value": "+1-246", "label": "+1-246" },
    { "country": "Bahamas", "value": "+1-242", "label": "+1-242" },
    { "country": "Uzbekistan", "value": "+998", "label": "+998" },
    { "country": "Kyrgyzstan", "value": "+996", "label": "+996" },
    { "country": "Georgia", "value": "+995", "label": "+995" },
    { "country": "Azerbaijan", "value": "+994", "label": "+994" },
    { "country": "Turkmenistan", "value": "+993", "label": "+993" },
    { "country": "Tajikistan", "value": "+992", "label": "+992" },
    { "country": "Nepal", "value": "+977", "label": "+977" },
    { "country": "Mongolia", "value": "+976", "label": "+976" },
    { "country": "Bhutan", "value": "+975", "label": "+975" },
    { "country": "Qatar", "value": "+974", "label": "+974" },
    { "country": "Bahrain", "value": "+973", "label": "+973" },
    { "country": "Israel", "value": "+972", "label": "+972" },
    { "country": "United Arab Emirates", "value": "+971", "label": "+971" },
    { "country": "Palestine", "value": "+970", "label": "+970" },
    { "country": "Oman", "value": "+968", "label": "+968" },
    { "country": "Yemen", "value": "+967", "label": "+967" },
    { "country": "Saudi Arabia", "value": "+966", "label": "+966" },
    { "country": "Kuwait", "value": "+965", "label": "+965" },
    { "country": "Iraq", "value": "+964", "label": "+964" },
    { "country": "Syria", "value": "+963", "label": "+963" },
    { "country": "Jordan", "value": "+962", "label": "+962" },
    { "country": "Lebanon", "value": "+961", "label": "+961" },
    { "country": "Maldives", "value": "+960", "label": "+960" },
    { "country": "Taiwan", "value": "+886", "label": "+886" },
    { "country": "Bangladesh", "value": "+880", "label": "+880" },
    { "country": "Laos", "value": "+856", "label": "+856" },
    { "country": "Cambodia", "value": "+855", "label": "+855" },
    { "country": "North Korea", "value": "+850", "label": "+850" },
    { "country": "Marshall Islands", "value": "+692", "label": "+692" },
    { "country": "Micronesia", "value": "+691", "label": "+691" },
    { "country": "Tokelau", "value": "+690", "label": "+690" },
    { "country": "French Polynesia", "value": "+689", "label": "+689" },
    { "country": "Tuvalu", "value": "+688", "label": "+688" },
    { "country": "New Caledonia", "value": "+687", "label": "+687" },
    { "country": "Kiribati", "value": "+686", "label": "+686" },
    { "country": "Samoa", "value": "+685", "label": "+685" },
    { "country": "Niue", "value": "+683", "label": "+683" },
    { "country": "Cook Islands", "value": "+682", "label": "+682" },
    { "country": "Wallis and Futuna", "value": "+681", "label": "+681" },
    { "country": "Palau", "value": "+680", "label": "+680" },
    { "country": "Fiji", "value": "+679", "label": "+679" },
    { "country": "Vanuatu", "value": "+678", "label": "+678" },
    { "country": "Solomon Islands", "value": "+677", "label": "+677" },
    { "country": "Tonga", "value": "+676", "label": "+676" },
    { "country": "Papua New Guinea", "value": "+675", "label": "+675" },
    { "country": "Nauru", "value": "+674", "label": "+674" },
    { "country": "Brunei", "value": "+673", "label": "+673" },
    { "country": "Antarctica", "value": "+672", "label": "+672" },
    { "country": "East Timor", "value": "+670", "label": "+670" },
    { "country": "Curacao", "value": "+599", "label": "+599" },
    { "country": "Netherlands Antilles", "value": "+599", "label": "+599" },
    { "country": "Uruguay", "value": "+598", "label": "+598" },
    { "country": "Suriname", "value": "+597", "label": "+597" },
    { "country": "Paraguay", "value": "+595", "label": "+595" },
    { "country": "Ecuador", "value": "+593", "label": "+593" },
    { "country": "Guyana", "value": "+592", "label": "+592" },
    { "country": "Bolivia", "value": "+591", "label": "+591" },
    { "country": "Saint Barthelemy", "value": "+590", "label": "+590" },
    { "country": "Saint Martin", "value": "+590", "label": "+590" },
    { "country": "Haiti", "value": "+509", "label": "+509" },
    { "country": "Saint Pierre and Miquelon", "value": "+508", "label": "+508" },
    { "country": "Panama", "value": "+507", "label": "+507" },
    { "country": "Costa Rica", "value": "+506", "label": "+506" },
    { "country": "Nicaragua", "value": "+505", "label": "+505" },
    { "country": "Honduras", "value": "+504", "label": "+504" },
    { "country": "El Salvador", "value": "+503", "label": "+503" },
    { "country": "Guatemala", "value": "+502", "label": "+502" },
    { "country": "Belize", "value": "+501", "label": "+501" },
    { "country": "Falkland Islands", "value": "+500", "label": "+500" },
    { "country": "Liechtenstein", "value": "+423", "label": "+423" },
    { "country": "Slovakia", "value": "+421", "label": "+421" },
    { "country": "Czech Republic", "value": "+420", "label": "+420" },
    { "country": "Macedonia", "value": "+389", "label": "+389" },
    { "country": "Bosnia and Herzegovina", "value": "+387", "label": "+387" },
    { "country": "Slovenia", "value": "+386", "label": "+386" },
    { "country": "Croatia", "value": "+385", "label": "+385" },
    { "country": "Kosovo", "value": "+383", "label": "+383" },
    { "country": "Montenegro", "value": "+382", "label": "+382" },
    { "country": "Serbia", "value": "+381", "label": "+381" },
    { "country": "Ukraine", "value": "+380", "label": "+380" },
    { "country": "Vatican", "value": "+379", "label": "+379" },
    { "country": "San Marino", "value": "+378", "label": "+378" },
    { "country": "Monaco", "value": "+377", "label": "+377" },
    { "country": "Andorra", "value": "+376", "label": "+376" },
    { "country": "Belarus", "value": "+375", "label": "+375" },
    { "country": "Armenia", "value": "+374", "label": "+374" },
    { "country": "Moldova", "value": "+373", "label": "+373" },
    { "country": "Estonia", "value": "+372", "label": "+372" },
    { "country": "Latvia", "value": "+371", "label": "+371" },
    { "country": "Lithuania", "value": "+370", "label": "+370" },
    { "country": "Bulgaria", "value": "+359", "label": "+359" },
    { "country": "Finland", "value": "+358", "label": "+358" },
    { "country": "Cyprus", "value": "+357", "label": "+357" },
    { "country": "Malta", "value": "+356", "label": "+356" },
    { "country": "Albania", "value": "+355", "label": "+355" },
    { "country": "Iceland", "value": "+354", "label": "+354" },
    { "country": "Ireland", "value": "+353", "label": "+353" },
    { "country": "Luxembourg", "value": "+352", "label": "+352" },
    { "country": "Portugal", "value": "+351", "label": "+351" },
    { "country": "Gibraltar", "value": "+350", "label": "+350" },
    { "country": "Greenland", "value": "+299", "label": "+299" },
    { "country": "Faroe Islands", "value": "+298", "label": "+298" },
    { "country": "Aruba", "value": "+297", "label": "+297" },
    { "country": "Eritrea", "value": "+291", "label": "+291" },
    { "country": "Saint Helena", "value": "+290", "label": "+290" },
    { "country": "Comoros", "value": "+269", "label": "+269" },
    { "country": "Swaziland", "value": "+268", "label": "+268" },
    { "country": "Botswana", "value": "+267", "label": "+267" },
    { "country": "Lesotho", "value": "+266", "label": "+266" },
    { "country": "Malawi", "value": "+265", "label": "+265" },
    { "country": "Namibia", "value": "+264", "label": "+264" },
    { "country": "Zimbabwe", "value": "+263", "label": "+263" },
    { "country": "Mayotte", "value": "+262", "label": "+262" },
    { "country": "Reunion", "value": "+262", "label": "+262" },
    { "country": "Madagascar", "value": "+261", "label": "+261" },
    { "country": "Zambia", "value": "+260", "label": "+260" },
    { "country": "Mozambique", "value": "+258", "label": "+258" },
    { "country": "Burundi", "value": "+257", "label": "+257" },
    { "country": "Uganda", "value": "+256", "label": "+256" },
    { "country": "Tanzania", "value": "+255", "label": "+255" },
    { "country": "Kenya", "value": "+254", "label": "+254" },
    { "country": "Djibouti", "value": "+253", "label": "+253" },
    { "country": "Somalia", "value": "+252", "label": "+252" },
    { "country": "Ethiopia", "value": "+251", "label": "+251" },
    { "country": "Rwanda", "value": "+250", "label": "+250" },
    { "country": "Sudan", "value": "+249", "label": "+249" },
    { "country": "Seychelles", "value": "+248", "label": "+248" },
    { "country": "British Indian Ocean Territory", "value": "+246", "label": "+246" },
    { "country": "Guinea-Bissau", "value": "+245", "label": "+245" },
    { "country": "Angola", "value": "+244", "label": "+244" },
    { "country": "Democratic Republic of the Congo", "value": "+243", "label": "+243" },
    { "country": "Republic of the Congo", "value": "+242", "label": "+242" },
    { "country": "Gabon", "value": "+241", "label": "+241" },
    { "country": "Equatorial Guinea", "value": "+240", "label": "+240" },
    { "country": "Sao Tome and Principe", "value": "+239", "label": "+239" },
    { "country": "Cape Verde", "value": "+238", "label": "+238" },
    { "country": "Cameroon", "value": "+237", "label": "+237" },
    { "country": "Central African Republic", "value": "+236", "label": "+236" },
    { "country": "Chad", "value": "+235", "label": "+235" },
    { "country": "Nigeria", "value": "+234", "label": "+234" },
    { "country": "Ghana", "value": "+233", "label": "+233" },
    { "country": "Sierra Leone", "value": "+232", "label": "+232" },
    { "country": "Liberia", "value": "+231", "label": "+231" },
    { "country": "Mauritius", "value": "+230", "label": "+230" },
    { "country": "Benin", "value": "+229", "label": "+229" },
    { "country": "Togo", "value": "+228", "label": "+228" },
    { "country": "Niger", "value": "+227", "label": "+227" },
    { "country": "Burkina Faso", "value": "+226", "label": "+226" },
    { "country": "Ivory Coast", "value": "+225", "label": "+225" },
    { "country": "Guinea", "value": "+224", "label": "+224" },
    { "country": "Mali", "value": "+223", "label": "+223" },
    { "country": "Mauritania", "value": "+222", "label": "+222" },
    { "country": "Senegal", "value": "+221", "label": "+221" },
    { "country": "Gambia", "value": "+220", "label": "+220" },
    { "country": "Libya", "value": "+218", "label": "+218" },
    { "country": "Tunisia", "value": "+216", "label": "+216" },
    { "country": "Algeria", "value": "+213", "label": "+213" },
    { "country": "Morocco", "value": "+212", "label": "+212" },
    { "country": "Western Sahara", "value": "+212", "label": "+212" },
    { "country": "South Sudan", "value": "+211", "label": "+211" },
    { "country": "Iran", "value": "+98", "label": "+98" },
    { "country": "Myanmar", "value": "+95", "label": "+95" },
    { "country": "Sri Lanka", "value": "+94", "label": "+94" },
    { "country": "Afghanistan", "value": "+93", "label": "+93" },
    { "country": "Pakistan", "value": "+92", "label": "+92" },
    { "country": "India", "value": "+91", "label": "+91" },
    { "country": "Turkey", "value": "+90", "label": "+90" },
    { "country": "Vietnam", "value": "+84", "label": "+84" },
    { "country": "South Korea", "value": "+82", "label": "+82" },
    { "country": "Japan", "value": "+81", "label": "+81" },
    { "country": "Thailand", "value": "+66", "label": "+66" },
    { "country": "New Zealand", "value": "+64", "label": "+64" },
    { "country": "Pitcairn", "value": "+64", "label": "+64" },
    { "country": "Philippines", "value": "+63", "label": "+63" },
    { "country": "Indonesia", "value": "+62", "label": "+62" },
    { "country": "Christmas Island", "value": "+61", "label": "+61" },
    { "country": "Cocos Islands", "value": "+61", "label": "+61" },
    { "country": "Malaysia", "value": "+60", "label": "+60" },
    { "country": "Venezuela", "value": "+58", "label": "+58" },
    { "country": "Colombia", "value": "+57", "label": "+57" },
    { "country": "Chile", "value": "+56", "label": "+56" },
    { "country": "Brazil", "value": "+55", "label": "+55" },
    { "country": "Argentina", "value": "+54", "label": "+54" },
    { "country": "Cuba", "value": "+53", "label": "+53" },
    { "country": "Mexico", "value": "+52", "label": "+52" },
    { "country": "Peru", "value": "+51", "label": "+51" },
    { "country": "Germany", "value": "+49", "label": "+49" },
    { "country": "Poland", "value": "+48", "label": "+48" },
    { "country": "Norway", "value": "+47", "label": "+47" },
    { "country": "Svalbard and Jan Mayen", "value": "+47", "label": "+47" },
    { "country": "Sweden", "value": "+46", "label": "+46" },
    { "country": "Denmark", "value": "+45", "label": "+45" },
    { "country": "Austria", "value": "+43", "label": "+43" },
    { "country": "Switzerland", "value": "+41", "label": "+41" },
    { "country": "Romania", "value": "+40", "label": "+40" },
    { "country": "Italy", "value": "+39", "label": "+39" },
    { "country": "Hungary", "value": "+36", "label": "+36" },
    { "country": "Spain", "value": "+34", "label": "+34" },
    { "country": "France", "value": "+33", "label": "+33" },
    { "country": "Belgium", "value": "+32", "label": "+32" },
    { "country": "Netherlands", "value": "+31", "label": "+31" },
    { "country": "Greece", "value": "+30", "label": "+30" },
    { "country": "South Africa", "value": "+27", "label": "+27" },
    { "country": "Egypt", "value": "+20", "label": "+20" },
    { "country": "Kazakhstan", "value": "+7", "label": "+7" },
    { "country": "Russia", "value": "+7", "label": "+7" },
];

export default function CompanionForm(props) {
    const { t, form, showMediaDrawer, mediaLinkRenderer } = props;
    const [joinWhatsappGroup, setJoinWhatsappGroup] = useState(false);
    const joinWhatappGroupAction = (checked) => {
        setJoinWhatsappGroup(checked);
    }

    return <>
        <h2>Basic</h2>
        <Form.Item

            label={t('companion.field.lastName.label')}
            name={["basic", "lastName"]}
            required={true}
            rules={[
                {
                    required: true,
                    message: t('companion.field.lastName.required-message'),
                }
            ]}
        >
            <Input placeholder={t('companion.field.lastName.placeholder')} />
        </Form.Item>
        <Form.Item
            label={t('companion.field.firstName.label')}
            name={["basic", "firstName"]}
            required={true}
            rules={[
                {
                    required: true,
                    message: t('companion.field.firstName.required-message'),
                }
            ]}
        >
            <Input placeholder={t('companion.field.firstName.placeholder')} />
        </Form.Item>
        <Form.Item
            label={t('companion.field.email.label')}
            name={["basic", "email"]}
            required={true}
            rules={[
                {
                    required: true,
                    message: t('companion.field.email.required-message'),
                },
                {
                    type: 'email',
                    message: t('companion.field.email.invalid-message'),
                }
            ]}
        >
            <Input placeholder={t('companion.field.email.placeholder')} />
        </Form.Item>
        <Form.Item
            label={t('companion.field.lastName_zh.label')}
            name={["basic", "lastName_zh"]}
            required={true}
            rules={[
                {
                    required: true,
                    message: t('companion.field.lastName_zh.required-message'),
                }
            ]}
        >
            <Input placeholder={t('companion.field.lastName_zh.placeholder')} />
        </Form.Item>
        <Form.Item
            label={t('companion.field.firstName_zh.label')}
            name={["basic", "firstName_zh"]}
            required={true}
            rules={[
                {
                    required: true,
                    message: t('companion.field.firstName_zh.required-message'),
                }
            ]}
        >
            <Input placeholder={t('companion.field.firstName_zh.placeholder')} />
        </Form.Item>
        <Form.Item
            label={t('companion.field.name.label')}
            name={["basic", "name"]}
        >
            <Input placeholder={t('companion.field.name.placeholder')} />
        </Form.Item>
        <Form.Item
            label={t('companion.field.gender.label')}
            name={["basic", "gender"]}
            required={true}
            rules={[
                {
                    required: true,
                    message: t('companion.field.gender.required-message'),
                }
            ]}
        >
            <Select allowClear placeholder={t('companion.field.gender.placeholder')}>
                <Select.Option value="male">Male</Select.Option>
                <Select.Option value="femal">Female</Select.Option>
            </Select>
        </Form.Item>
        <Form.Item
            name={["basic", "dob"]}
            label={t('companion.field.dob.label')}
        >
            <DatePicker disabledTime={true} placeholder={t('companion.field.dob.placeholder')} />
        </Form.Item>
        <Form.Item
            label={t('companion.field.birthPlace.label')}
            name={["basic", "birthPlace"]}
        >
            <Input placeholder={t('companion.field.birthPlace.placeholder')} />
        </Form.Item>
        <Form.Item
            label={t('companion.field.phoneRegion.label')}
            name={["basic", "phoneRegion"]}
            required={true}
            rules={[
                {
                    required: true,
                    message: t('companion.field.phoneRegion.required-message'),
                }
            ]}
        >
            <Select showSearch filterOption={(input, option) => {
                let optionString = option.children;
                if (Array.isArray(optionString)) {
                    optionString = optionString.join('');
                }
                return optionString.toLowerCase().indexOf(input.toLowerCase()) >= 0;
            }} allowClear placeholder={t('companion.field.phoneRegion.placeholder')}>
                {phoneRegionOptions.map((option) => {
                    return <Select.Option key={`${option.value}${option.country}`} value={option.value}>{option.label} - {option.country}</Select.Option>
                })}
            </Select>
        </Form.Item>
        <Form.Item
            label={t('companion.field.phoneNumber.label')}
            name={["basic", "phoneNumber"]}
            required={true}
            rules={[
                {
                    required: true,
                    message: t('companion.field.phoneNumber.required-message'),
                }
            ]}
        >
            <Input placeholder={t('companion.field.phoneNumber.placeholder')} />
        </Form.Item>
        <Form.Item
            label={t('companion.field.address.label')}
            name={["basic", "address"]}
            required={true}
            rules={[
                {
                    required: true,
                    message: t('companion.field.address.required-message'),
                }
            ]}
        >
            <Input.TextArea autoSize={{ minRows: 5, maxRows: 10 }} placeholder={t('companion.field.address.placeholder')} />
        </Form.Item>
        <Form.Item
            label={t('companion.field.jobTitle.label')}
            name={["basic", "jobTitle"]}
        >
            <Input placeholder={t('companion.field.jobTitle.placeholder')} />
        </Form.Item>
        <Form.Item
            label={t('companion.field.companyOrSchool.label')}
            name={["basic", "companyOrSchool"]}
        >
            <Input placeholder={t('companion.field.companyOrSchool.placeholder')} />
        </Form.Item>
        <Form.Item
            label={t('companion.field.companyOrSchoolAddress.label')}
            name={["basic", "companyOrSchoolAddress"]}
        >
            <Input.TextArea autoSize={{ minRows: 5, maxRows: 10 }} placeholder={t('companion.field.companyOrSchoolAddress.placeholder')} />
        </Form.Item>
        <h2>Passport</h2>
        <Form.Item
            label={t('companion.field.nationality.label')}
            name={["passport", "nationality"]}
        >
            <Select allowClear placeholder={t('companion.field.nationality.placeholder')}
                showSearch filterOption={(input, option) => {
                    let optionString = option.children;
                    if (Array.isArray(optionString)) {
                        optionString = optionString.join('');
                    }
                    return optionString.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }}>
                {nationalityOptions.map((option) => {
                    return <Select.Option key={`${option.value}${option.iso3}`} value={option.value}>{option.label.en} {option.label.zh} - {option.iso3}</Select.Option>
                })}
            </Select>
        </Form.Item>
        <Form.Item
            label={t('companion.field.originalNationality.label')}
            name={["passport", "originalNationality"]}
        >
            <Select allowClear placeholder={t('companion.field.originalNationality.placeholder')}
                showSearch filterOption={(input, option) => {
                    let optionString = option.children;
                    if (Array.isArray(optionString)) {
                        optionString = optionString.join('');
                    }
                    return optionString.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }}>
                {nationalityOptions.map((option) => {
                    return <Select.Option key={`${option.value}${option.iso3}`} value={option.value}>{option.label.en} {option.label.zh} - {option.iso3}</Select.Option>
                })}
            </Select>
        </Form.Item>
        <Form.Item
            label={t('companion.field.passportNumber.label')}
            name={["passport", "passportNumber"]}
        >
            <Input placeholder={t('companion.field.passportNumber.placeholder')} />
        </Form.Item>
        <Form.Item
            label={t('companion.field.passportIssuedDate.label')}
            name={["passport", "passportIssuedDate"]}
        >
            <DatePicker disabledTime={true} placeholder={t('companion.field.passportIssuedDate.placeholder')} />
        </Form.Item>
        <Form.Item
            label={t('companion.field.passportExpiredDate.label')}
            name={["passport", "passportExpiredDate"]}
        >
            <DatePicker disabledTime={true} placeholder={t('companion.field.passportExpiredDate.placeholder')} />
        </Form.Item>
        <Form.Item
            label={t('companion.field.chinaRelatedNumber.label')}
            name={["passport", "chinaRelatedNumber"]}
        >
            <Input placeholder={t('companion.field.chinaRelatedNumber.placeholder')} />
        </Form.Item>
        <Form.Item
            label={t('companion.field.chinaRelatedIssuedDate.label')}
            name={["passport", "chinaRelatedIssuedDate"]}
        >
            <DatePicker disabledTime={true} placeholder={t('companion.field.chinaRelatedIssuedDate.placeholder')} />
        </Form.Item>
        <Form.Item
            label={t('companion.field.chinaRelatedExpiredDate.label')}
            name={["passport", "chinaRelatedExpiredDate"]}
        >
            <DatePicker disabledTime={true} placeholder={t('companion.field.chinaRelatedExpiredDate.placeholder')} />
        </Form.Item>
        <Form.List name={["passport", "mediaDocuments"]} style={{ width: '100%' }}>
            {(fields, { add, remove, move }) => (
                <>
                    <h1>{t('companion.field.mediaDocuments.label')}</h1>
                    {fields.map(({ key, name, ...restField }, fieldPosition) => {
                        return <div key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                            <MediaSelect showLink={mediaLinkRenderer ? mediaLinkRenderer : false} showMediaDrawer={showMediaDrawer} form={form} wrapper={{
                                wrapperCol: 16,
                                required: true,
                                style: { marginBottom: 8, width: 'calc(100% - 100px)' }
                            }} inner={{
                                name: [name],
                                rules: [
                                    {
                                        required: true,
                                        message: t('companion.field.mediaDocuments.required')
                                    }
                                ]
                            }}
                                fieldName={["passport", "mediaDocuments", name]}
                                placeholder={t('companion.field.mediaDocuments.placeholder')} />
                            <div style={{ width: 100 }}>
                                {(fields.length > 1 && fieldPosition !== 0) && <UpCircleOutlined onClick={() => move(fieldPosition, fieldPosition - 1)} style={{ marginLeft: 8 }} />}
                                {(fields.length > 1 && fieldPosition !== (fields.length - 1)) && <DownCircleOutlined onClick={() => move(fieldPosition, fieldPosition + 1)} style={{ marginLeft: 8 }} />}
                                <MinusCircleOutlined onClick={() => remove(name)} style={{ marginLeft: 8 }} />
                            </div>
                        </div>
                    })}
                    <Form.Item wrapperCol={{ span: 24 }}>
                        <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                            {t('companion.field.mediaDocuments.add')}
                        </Button>
                    </Form.Item>
                </>
            )}
        </Form.List>
        <h2>Contact</h2>
        <Form.Item
            label={t('companion.field.joinWhatsappGroup.label')}
            name={["contact", "joinWhatsappGroup"]}
            valuePropName="checked"
        >
            <Switch onChange={joinWhatappGroupAction} />
        </Form.Item>
        <Form.Item
            label={t('companion.field.whatsappNumber.label')}
            name={["contact", "whatsappNumber"]}
            required={joinWhatsappGroup}
            rules={joinWhatsappGroup ? [
                {
                    required: joinWhatsappGroup,
                    message: t('companion.field.whatsappNumber.required-message'),
                }
            ] : []}
        >
            <Input placeholder={t('companion.field.whatsappNumber.placeholder')} />
        </Form.Item>
        <Form.Item
            label={t('companion.field.emergencyFirstname.label')}
            name={["contact", "emergencyFirstname"]}
        >
            <Input placeholder={t('companion.field.emergencyFirstname.placeholder')} />
        </Form.Item>
        <Form.Item
            label={t('companion.field.emergencyLastname.label')}
            name={["contact", "emergencyLastname"]}
        >
            <Input placeholder={t('companion.field.emergencyLastname.placeholder')} />
        </Form.Item>
        <Form.Item
            label={t('companion.field.emergencyPhoneRegion.label')}
            name={["contact", "emergencyPhoneRegion"]}
        >
            <Select showSearch filterOption={(input, option) => {
                let optionString = option.children;
                if (Array.isArray(optionString)) {
                    optionString = optionString.join('');
                }
                return optionString.toLowerCase().indexOf(input.toLowerCase()) >= 0;
            }} allowClear placeholder={t('companion.field.emergencyPhoneRegion.placeholder')}>
                {phoneRegionOptions.map((option) => {
                    return <Select.Option key={`${option.value}${option.country}`} value={option.value}>{option.label} - {option.country}</Select.Option>
                })}
            </Select>
        </Form.Item>
        <Form.Item
            label={t('companion.field.emergencyPhoneNumber.label')}
            name={["contact", "emergencyPhoneNumber"]}
        >
            <Input placeholder={t('companion.field.emergencyPhoneNumber.placeholder')} />
        </Form.Item>
    </>
}