import React from 'react';
import { Layout } from 'antd';
import MemberSidebar from './components/member.sidebar.component';
import MemberFooter from './components/member.footer.component';
import { useSelector } from 'react-redux';
import { redirect } from "react-router-dom";
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function EntityDashboard() {
    const { t } = useTranslation();
    const { Content } = Layout;
    const entities = useSelector(state => state.entity.entities);
    const params = useParams();
    let entity = null;
    if (typeof params.entityId === 'undefined') {
        // Redirect to switch entity page
        return redirect('/dashboard');
    } else {
        if (entities && entities.length > 0) {
            entity = entities.find(entity => entity.id === params.entityId);
        }
    }
    return <>
        <MemberSidebar />
        <Layout className="site-layout">
            <Content style={{ margin: '0 16px' }}>
                <div className="site-layout-background">
                    {entity && <h1 style={{ marginBottom: 20 }}>{t('entityDashboard.title')} {entity.name}!!</h1>}
                </div>
            </Content>
            <MemberFooter />
        </Layout>
    </>;
}

export default EntityDashboard;