import React, { useCallback, useEffect, useState } from 'react';
import { Layout, Popconfirm, message, Button, Table, Space, Descriptions, Input } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import MemberSidebar from './components/member.sidebar.component';
import MemberFooter from './components/member.footer.component';
import { useDispatch } from 'react-redux';
import { readUsers, countUsers, deleteUser, enableUser, disableUser } from '../actions/entity.user';
import { PageHeader } from '@ant-design/pro-components';
import { useTranslation } from 'react-i18next';
import { green, gold } from '@ant-design/colors';
import { defaultPageSize, withFilterForm } from '../components/withFilterForm';

const layout = {
    labelCol: {
        span: 24,
    },
    wrapperCol: {
        span: 24,
    },
};

const ExpandedUserInfo = (props) => {
    const { t } = useTranslation();
    let { record } = props;
    let content = [];

    content.push(<Descriptions title={t('users.basic.title')} layout="horizontal" column={1} size="small" bordered={true}>
        {typeof record.uuid !== 'undefined' && <Descriptions.Item label={t('users.basic.title.uuid')}>{record.uuid}</Descriptions.Item>}
    </Descriptions>);
    if (typeof record.google !== 'undefined') {
        content.push(<Descriptions title={t('users.google.title')} layout="horizontal" column={1} size="small" bordered={true}>
            {typeof record.google.id !== 'undefined' && <Descriptions.Item label={t('users.google.title.id')}>{record.google.id}</Descriptions.Item>}
            {typeof record.google.displayName !== 'undefined' && <Descriptions.Item label={t('users.google.title.displayName')}>{record.google.displayName}</Descriptions.Item>}
            {typeof record.google.emails !== 'undefined' && <Descriptions.Item label={t('users.google.title.emails')}>{
                record.google.emails.map((item, index) => {
                    return <div key={index}>{item.value}&lt;{item.verified ? t('users.google.verified') : t('users.google.not_verified')}&gt;</div>
                })
            }</Descriptions.Item>}
            {typeof record.google.name !== 'undefined' && typeof record.google.name.familyName !== 'undefined' && <Descriptions.Item label={t('users.google.title.familyName')}>{record.google.name.familyName}</Descriptions.Item>}
            {typeof record.google.name !== 'undefined' && typeof record.google.name.givenName !== 'undefined' && <Descriptions.Item label={t('users.google.title.givenName')}>{record.google.name.givenName}</Descriptions.Item>}
            {typeof record.google.photos !== 'undefined' && <Descriptions.Item label={t('users.google.title.photos')}><img src={record.google.photos[0].value} alt={record.google.displayName} /></Descriptions.Item>}
        </Descriptions>);
    }
    return <div>{content}</div>;
}

function Users(props) {
    const { form, searchParams, setPage, setPageSize } = props;
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const params = useParams();
    const { Content } = Layout;
    const [dataSource, setDataSource] = useState([]);
    const [total, setTotal] = useState(0);

    const disableUserAction = (id) => {
        dispatch(disableUser(params.entityId, id)).then((response) => {
            message.success(t('entities.message.disabled-success'));
            setDataSource(dataSource.map(item => {
                if (item.id === id) {
                    item.status = false;
                }
                return item;
            }));
        }).catch(e => {
            console.log(e);
            message.error(t('entities.message.disabled-failed'));
        });
    }
    const enableUserAction = (id) => {
        dispatch(enableUser(params.entityId, id)).then((response) => {
            message.success(t('users.message.enabled-success'));
            setDataSource(dataSource.map(item => {
                if (item.id === id) {
                    item.status = true;
                }
                return item;
            }));
        }).catch(e => {
            console.log(e);
            message.error(t('users.message.enabled-failed'));
        });
    }
    const deleteUserAction = (id) => {
        dispatch(deleteUser(params.entityId, id)).then((response) => {
            message.success(t('users.message.delete-success'));
            setDataSource(dataSource.filter(item => item.id !== id));
        }).catch(e => {
            console.log(e);
            message.error(t('users.message.delete-failed'));
        });
    }

    const columns = [
        {
            title: t('users.columns.id'),
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: t('users.columns.name'),
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => {
                let name = [];
                if (typeof record.lastName !== 'undefined') {
                    name.push(record.lastName);
                }
                if (typeof record.firstName !== 'undefined') {
                    name.push(record.firstName);
                }
                if (name.length === 0) {
                    return '-';
                } else {
                    return name.join(', ');
                }
            }
        },
        {
            title: t('users.columns.email'),
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: t('users.columns.keyIsVerified'),
            dataIndex: 'keyIsVerified',
            key: 'keyIsVerified',
            render: (text, record) => {
                if (typeof record.keyIsVerified !== 'undefined' && record.keyIsVerified === true) {
                    return t('users.columns.keyIsVerified.true');
                } else {
                    return t('users.columns.keyIsVerified.false');
                }
            }
        },
        // {
        //     title: t('users.columns.hasSentInvitation'),
        //     dataIndex: 'hasSentInvitation',
        //     key: 'hasSentInvitation',
        //     render: (text, record) => {
        //         if (typeof record.hasSentInvitation !== 'undefined' && record.hasSentInvitation === true) {
        //             return t('users.columns.hasSentInvitation.true');
        //         } else {
        //             return t('users.columns.hasSentInvitation.false');
        //         }
        //     }
        // },
        // {
        //     title: t('users.columns.needChangePassword'),
        //     dataIndex: 'needChangePassword',
        //     key: 'needChangePassword',
        //     render: (text, record) => {
        //         if (typeof record.needChangePassword !== 'undefined' && record.needChangePassword === true) {
        //             return t('users.columns.needChangePassword.true');
        //         } else {
        //             return t('users.columns.needChangePassword.false');
        //         }
        //     }
        // },
        {
            title: t('users.columns.created'),
            dataIndex: 'createdAt',
            key: 'createdAt'
        },
        {
            title: t('users.columns.actions'),
            dataIndex: 'actions',
            key: 'actions',
            render: (text, record) => <Space>
                <Button size="small" type="primary" href={`/entity/${params.entityId}/user/${record.id}`}>{t('users.actions.edit')}</Button>
                <Button size="small" type="primary" style={{ backgroundColor: green[5] }} href={`/entity/${params.entityId}/orders?user=${record.id}`}>{t('users.actions.orders')}</Button>
                <Button size="small" type="primary" style={{ backgroundColor: gold[5] }} href={`/entity/${params.entityId}/companions?user=${record.id}`}>{t('users.actions.companions')}</Button>
                <Popconfirm
                    title={record.status ? t('users.actions.disable-confirm') : t('users.actions.enable-confirm')}
                    onConfirm={() => record.status ? disableUserAction(record.id) : enableUserAction(record.id)}
                    okText={record.status ? t('users.actions.disable-ok') : t('users.actions.enable-ok')}
                    cancelText={record.status ? t('users.actions.disable-cancel') : t('users.actions.enable-cancel')}
                >
                    <Button size="small" type="primary" style={{ backgroundColor: !record.status ? green[5] : '' }} danger={record.status ? true : false}>{record.status ? t('users.actions.disable') : t('users.actions.enable')}</Button>
                </Popconfirm>
                <Popconfirm
                    title={t('users.actions.delete-confirm')}
                    onConfirm={() => deleteUserAction(record.id)}
                    okText={t('users.actions.delete-ok')}
                    cancelText={t('users.actions.delete-cancel')}
                >
                    <Button size="small" type="primary" danger>{t('users.actions.delete')}</Button>
                </Popconfirm>
            </Space>

        }
    ];

    const loadUsers = useCallback(() => {
        const filters = {};
        if (typeof searchParams.get('firstname') !== 'undefined' && searchParams.get('firstname') && searchParams.get('firstname') !== '') {
            filters['firstname'] = searchParams.get('firstname');
        }
        if (typeof searchParams.get('lastname') !== 'undefined' && searchParams.get('lastname') && searchParams.get('lastname') !== '') {
            filters['lastname'] = searchParams.get('lastname');
        }
        if (typeof searchParams.get('email') !== 'undefined' && searchParams.get('email') && searchParams.get('email') !== '') {
            filters['email'] = searchParams.get('email');
        }
        const filtersWithPagination = { ...filters, page: searchParams.get('page'), pageSize: searchParams.get('pageSize') };

        dispatch(countUsers(params.entityId, filters)).then((data) => {
            setTotal(data.result);
        }).catch((error) => {
            try {
                let responseMessage = error.response.data.message;
                console.log(responseMessage);
                message.error(responseMessage);
            } catch (e) {
                console.log('Failed:', e);
                message.error(JSON.stringify(e));
            }
        });
        dispatch(readUsers(params.entityId, filtersWithPagination)).then((data) => {
            setDataSource(data.result);
        }).catch((error) => {
            try {
                setDataSource([]);
                let responseMessage = error.response.data.message;
                console.log(responseMessage);
                message.error(responseMessage);
            } catch (e) {
                console.log('Failed:', e);
                message.error(JSON.stringify(e));
            }
        });
    }, [dispatch, searchParams]);

    useEffect(() => {
        loadUsers();
    }, [loadUsers]);

    return <>
        <MemberSidebar />
        <Layout className="site-layout">
            <Content style={{ margin: '0 16px' }}>
                <div className="site-layout-background">
                    <PageHeader
                        className="site-page-header"
                        onBack={() => navigate(`/entity/${params.entityId}/dashboard`)}
                        title={t('users.title')}
                        subTitle={t('users.subtitle')}
                        extra={[
                            <Button key="create" type="primary" href={`/entity/${params.entityId}/user`}>{t('users.actions.new')}</Button>
                        ]}
                    />
                    {form}
                    <Table
                        size='small'
                        rowKey={record => record.id}
                        rowClassName={(record, index) => {
                            if (record.status === true) return 'row-enabled';
                            return 'row-disabled';
                        }}
                        expandable={{
                            expandedRowRender: (record) => <ExpandedUserInfo record={record} />,
                            rowExpandable: () => true,
                        }}
                        dataSource={dataSource}
                        columns={columns}
                        pagination={{
                            total: total,
                            onChange(page, pageSize) {
                                setPage(page);
                                setPageSize(pageSize);
                            },
                            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                            defaultCurrent: searchParams.get('page') ? parseInt(searchParams.get('page')) : 1,
                            defaultPageSize: searchParams.get('pageSize') ? parseInt(searchParams.get('pageSize')) : defaultPageSize,
                            showSizeChanger: true,
                            pageSizeOptions: [defaultPageSize.toString(), '250', '500', '1000'],
                            position: ['topRight', 'bottomRight']
                        }}
                    />
                </div>
            </Content>
            <MemberFooter />
        </Layout>
    </>;
}

export default withFilterForm(Users, [
    { name: 'firstname', label: 'Firstname', formItem: <Input placeholder='Firstname' /> },
    { name: 'lastname', label: 'Lastname', formItem: <Input placeholder='Lastname' /> },
    { name: 'email', label: 'Email', formItem: <Input placeholder='Email' /> },
], null, null, null, defaultPageSize, 1, ['firstname', 'lastname', 'email']);