import authHeader from './auth-header';
import axios from "axios";
import qs from 'qs';
import { API_ENTITY_COMPANION_URL } from "./config";

// API to get companion by id
const readCompanion = (entityId, id) => {
    return axios.get(API_ENTITY_COMPANION_URL(entityId) + id, {
        headers: authHeader()
    }).then(res => res.data);
}

// API to count companions 
const countCompanions = (entityId, params) => {
    let filters = {};
    if (typeof params.user !== 'undefined' && params.user !== null) {
        filters.user = params.user;
    }
    return axios.get(API_ENTITY_COMPANION_URL(entityId) + 'count?' + qs.stringify(filters), {
        headers: authHeader()
    }).then(res => res.data);
}

// API to get all companions
const readCompanions = (entityId, params) => {
    let filters = params;
    if (typeof filters.user === 'undefined' || filters.user === null) {
        delete filters.user;
    }
    return axios.get(API_ENTITY_COMPANION_URL(entityId) + '?' + qs.stringify(filters), {
        headers: authHeader()
    }).then(res => res.data);
}


// API to create companion
const createCompanion = (entityId, companion) => {
    return axios
        .post(API_ENTITY_COMPANION_URL(entityId), companion, {
            headers: authHeader()
        }).then((response) => {
            return response.data;
        });
};

// API to update companion
const updateCompanion = (entityId, id, companion) => {
    return axios
        .put(API_ENTITY_COMPANION_URL(entityId) + id, companion, {
            headers: authHeader()
        }).then((response) => {
            return response.data;
        });
};

// API to delete companion
const deleteCompanion = (entityId, id) => {
    return axios
        .delete(API_ENTITY_COMPANION_URL(entityId) + id, {
            headers: authHeader()
        }).then((response) => {
            return response.data;
        });
};

const companions = {
    createCompanion,
    readCompanion,
    readCompanions,
    countCompanions,
    updateCompanion,
    deleteCompanion
};

export default companions;