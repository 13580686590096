import React, { useEffect, useRef, useState } from 'react';
import { Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import MediaSelect from './mediaSelect.field';

const defaultlayout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 10,
    },
};

function SeoFields(props) {
    const { t } = useTranslation();
    const { excludes, name, layout, form, showMediaDrawer } = props;
    let prefixNameArray = [];
    let excludesArray = [];
    let finalLayout = { ...defaultlayout, ...layout };
    if (Array.isArray(excludes)) {
        excludesArray = excludes;
    }
    if (Array.isArray(name)) {
        prefixNameArray = name;
    } else if (typeof prefixName === 'string') {
        prefixNameArray = [name];
    }
    return (<>
        <h1>Meta Fields</h1>
        {/** Meta Title */}
        {!excludesArray.includes('metaTitle') && <Form.Item
            label={t('meta.field.metaTitle.label')}
            name={[].concat(prefixNameArray.concat(['metaTitle']))}
            {...finalLayout}
        >
            <Input placeholder={t('meta.field.metaTitle.placeholder')} />
        </Form.Item>}
        {/** Meta Description */}
        {!excludesArray.includes('metaDescription') && <Form.Item
            label={t('meta.field.metaDescription.label')}
            name={[].concat(prefixNameArray.concat(['metaDescription']))}
            {...finalLayout}
        >
            <Input.TextArea placeholder={t('meta.field.metaDescription.placeholder')} />
        </Form.Item>}
        {/** Meta Image */}
        {!excludesArray.includes('metaImage') && <MediaSelect showMediaDrawer={showMediaDrawer} form={form} wrapper={{
            wrapperCol: 16,
            label: t('meta.field.metaImage.label')
        }} inner={{
            name: [].concat(prefixNameArray.concat(['metaImage'])),
        }} />}
    </>)
}

export default SeoFields;