import authHeader from './auth-header';
import axios from "axios";
import { API_ENTITY_TRIP_TOPIC_URL } from "./config";

// API to get active trip topic translations
const readAllActiveTripTopicsTranslations = (entityId) => {
    return axios.get(API_ENTITY_TRIP_TOPIC_URL(entityId) + 'translations', {
        headers: authHeader()
    }).then(res => res.data);
}

// API to get entity trip by id
const readTripTopic = (entityId, id) => {
    return axios.get(API_ENTITY_TRIP_TOPIC_URL(entityId) + id, {
        headers: authHeader()
    }).then(res => res.data);
}

// API to get all entity trips
const readTripTopics = (entityId) => {
    return axios.get(API_ENTITY_TRIP_TOPIC_URL(entityId), {
        headers: authHeader()
    }).then(res => res.data);
}


// API to create entity trip
const createTripTopic = (entityId, tripTopic) => {
    return axios
        .post(API_ENTITY_TRIP_TOPIC_URL(entityId), tripTopic, {
            headers: authHeader()
        }).then((response) => {
            return response.data;
        });
};

// API to update entity trip
const updateTripTopic = (entityId, id, tripTopic) => {
    return axios
        .put(API_ENTITY_TRIP_TOPIC_URL(entityId) + id, tripTopic, {
            headers: authHeader()
        }).then((response) => {
            return response.data;
        });
};

// API to delete entity trip
const deleteTripTopic = (entityId, id) => {
    return axios
        .delete(API_ENTITY_TRIP_TOPIC_URL(entityId) + id, {
            headers: authHeader()
        }).then((response) => {
            return response.data;
        });
};

const tripTopics = {
    createTripTopic,
    readTripTopic,
    readTripTopics,
    updateTripTopic,
    deleteTripTopic,
    readAllActiveTripTopicsTranslations
};

export default tripTopics;