import OrderService from "../services/entity.order.service";

export const addCustomAddonOnOrder = (entityId, orderId, customAddon) => (dispatch) => {
    return OrderService.addCustomAddonOnOrder(entityId, orderId, customAddon).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to update order traveler by orderId and travelerId
export const updateOrderTraveler = (entityId, orderId, travelerId, traveler) => (dispatch) => {
    return OrderService.updateOrderTraveler(entityId, orderId, travelerId, traveler).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to get entity by id
export const readOrder = (entityId, id) => (dispatch) => {
    return OrderService.readOrder(entityId, id).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to send trip confirmation email
export const sendTripConfirmationEmail = (entityId, orderId, email) => (dispatch) => {
    return OrderService.sendTripConfirmationEmail(entityId, orderId, email).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to send order confirmation email
export const sendOrderConfirmationEmail = (entityId, orderId, email) => (dispatch) => {
    return OrderService.sendOrderConfirmationEmail(entityId, orderId, email).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });

}

// Action to complete the order
export const completeOrder = (entityId, id) => (dispatch) => {
    return OrderService.completeOrder(entityId, id).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to get all entity orders
export const readOrders = (entityId, params) => (dispatch) => {
    return OrderService.readOrders(entityId, params).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to count all entity orders
export const countOrders = (entityId, params) => (dispatch) => {
    return OrderService.countOrders(entityId, params).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to create the entity order
export const createOrder = (entityId, order) => (dispatch) => {
    return OrderService.createOrder(entityId, order).then((data) => {
        console.log(data);
        return Promise.resolve(data);
    }, (error) => {
        console.log(error.message);
        return Promise.reject(error);
    });
}

// Action to update the entity order
export const updateOrder = (entityId, id, order) => (dispatch) => {
    return OrderService.updateOrder(entityId, id, order).then((data) => {
        console.log(data);
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}