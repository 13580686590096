import authHeader from './auth-header';
import axios from "axios";
import { API_ENTITY_TRIP_TAG_URL } from "./config";

// API to get active trip tag translations
const readAllActiveTripTagsTranslations = (entityId) => {
    return axios.get(API_ENTITY_TRIP_TAG_URL(entityId) + 'translations', {
        headers: authHeader()
    }).then(res => res.data);
}

// API to get trip tag by id
const readTripTag = (entityId, id) => {
    return axios.get(API_ENTITY_TRIP_TAG_URL(entityId) + id, {
        headers: authHeader()
    }).then(res => res.data);
}

// API to get all trip tags
const readTripTags = (entityId) => {
    return axios.get(API_ENTITY_TRIP_TAG_URL(entityId), {
        headers: authHeader()
    }).then(res => res.data);
}


// API to create trip tag
const createTripTag = (entityId, entityTripTag) => {
    return axios
        .post(API_ENTITY_TRIP_TAG_URL(entityId), entityTripTag, {
            headers: authHeader()
        }).then((response) => {
            return response.data;
        });
};

// API to update trip tag
const updateTripTag = (entityId, id, entityTripTag) => {
    return axios
        .put(API_ENTITY_TRIP_TAG_URL(entityId) + id, entityTripTag, {
            headers: authHeader()
        }).then((response) => {
            return response.data;
        });
};

// API to delete trip tag
const deleteTripTag = (entityId, id) => {
    return axios
        .delete(API_ENTITY_TRIP_TAG_URL(entityId) + id, {
            headers: authHeader()
        }).then((response) => {
            return response.data;
        });
};

const entityTripTags = {
    createTripTag,
    readTripTag,
    readTripTags,
    updateTripTag,
    deleteTripTag,
    readAllActiveTripTagsTranslations
};

export default entityTripTags;