import EntityPageService from "../services/entity.page.service";

// Action to get entity by id
export const readEntityPage = (entityId, id) => (dispatch) => {
    return EntityPageService.readEntityPage(entityId, id).then((data) => {
        console.log(data);
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to get all entity pages
export const readEntityPages = (entityId) => (dispatch) => {
    return EntityPageService.readEntityPages(entityId).then((data) => {
        // if (data.result) {
        //     dispatch({
        //         type: READ_ENTITY_REGIONS_SUCCESS,
        //         payload: data.result
        //     });
        // }
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to create the entity page
export const createEntityPage = (entityId, entity) => (dispatch) => {
    return EntityPageService.createEntityPage(entityId, entity).then((data) => {
        console.log(data);
        return Promise.resolve(data);
    }, (error) => {
        console.log(error.message);
        return Promise.reject(error);
    });
}

// Action to update the entity page
export const updateEntityPage = (entityId, id, entityPage) => (dispatch) => {
    return EntityPageService.updateEntityPage(entityId, id, entityPage).then((data) => {
        console.log(data);
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to delete the entity page
export const deleteEntityPage = (entityId, id) => (dispatch) => {
    return EntityPageService.deleteEntityPage(entityId, id).then((data) => {
        // dispatch({
        //     type: DELETE_ENTITY_REGION_SUCCESS,
        //     payload: id
        // });
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}