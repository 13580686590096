import authHeader from './auth-header';
import axios from "axios";
import { API_ENTITY_ARTICLE_CATEGORY_URL } from "./config";

// API to get active article categories translations
const readAllActiveArticleCategoriesTranslations = (entityId) => {
    return axios.get(API_ENTITY_ARTICLE_CATEGORY_URL(entityId) + 'translations', {
        headers: authHeader()
    }).then(res => res.data);
}

// API to get entity article category by id
const readArticleCategory = (entityId, id) => {
    return axios.get(API_ENTITY_ARTICLE_CATEGORY_URL(entityId) + id, {
        headers: authHeader()
    }).then(res => res.data);
}

// API to get all entity article categories
const readArticleCategories = (entityId) => {
    return axios.get(API_ENTITY_ARTICLE_CATEGORY_URL(entityId), {
        headers: authHeader()
    }).then(res => res.data);
}


// API to create entity article category
const createArticleCategory = (entityId, entityArticleCategory) => {
    return axios
        .post(API_ENTITY_ARTICLE_CATEGORY_URL(entityId), entityArticleCategory, {
            headers: authHeader()
        }).then((response) => {
            return response.data;
        });
};

// API to update entity article category
const updateArticleCategory = (entityId, id, entityArticleCategory) => {
    return axios
        .put(API_ENTITY_ARTICLE_CATEGORY_URL(entityId) + id, entityArticleCategory, {
            headers: authHeader()
        }).then((response) => {
            return response.data;
        });
};

// API to delete entity article category
const deleteArticleCategory = (entityId, id) => {
    return axios
        .delete(API_ENTITY_ARTICLE_CATEGORY_URL(entityId) + id, {
            headers: authHeader()
        }).then((response) => {
            return response.data;
        });
};

// API to get root entity article categories
const readRootArticleCategories = (entityId) => {
    return axios.get(API_ENTITY_ARTICLE_CATEGORY_URL(entityId) + 'roots', {
        headers: authHeader()
    }).then(res => res.data);
}



const entityArticleCategories = {
    createArticleCategory,
    readArticleCategory,
    readArticleCategories,
    readRootArticleCategories,
    updateArticleCategory,
    deleteArticleCategory,
    readAllActiveArticleCategoriesTranslations
};

export default entityArticleCategories;