import React, { useEffect, useState } from 'react';
import { Layout, message, Button, Form, Input, Switch, Select } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import MemberSidebar from './components/member.sidebar.component';
import MemberFooter from './components/member.footer.component';
import { useDispatch, useSelector } from 'react-redux';
import { createAdmin, readAdmin, updateAdmin } from '../actions/admin';
// import { readRoles } from '../actions/role';
import { isRoot } from '../helpers/user';
import { PageHeader } from '@ant-design/pro-components';

const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 8,
    },
};

function Admin() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const currentUser = useSelector(state => state.auth.user);
    const params = useParams();
    const { Content } = Layout;
    const [form] = Form.useForm();
    // const roles = useSelector(state => state.role.roles);

    // console.log(roles);
    // useEffect(() => {
    //     if (!roles || (Array.isArray(roles) && roles.length === 0)) {
    //         dispatch(readRoles());
    //     }
    // }, [roles, dispatch]);

    const [adminTitle, setAdminTitle] = useState('');
    useEffect(() => {
        if (typeof params.id !== 'undefined') {
            setAdminTitle('Edit Admin');
            dispatch(readAdmin(params.id)).then((response) => {
                if (typeof response.result !== 'undefined' && typeof response.result.id !== 'undefined') {
                    setAdminTitle('Edit Admin - ' + response.result.name);
                    let tempResult = response.result;
                    // tempResult.roles = tempResult.roles.map(role => {
                    //     return role._id;
                    // });
                    console.log(tempResult);
                    form.setFieldsValue({
                        name: tempResult.name,
                        email: tempResult.email,
                        status: tempResult.status,
                        isRoot: tempResult.isRoot,
                        isTopAdmin: tempResult.isTopAdmin,
                        // roles: tempResult.roles
                    });
                } else {
                    message.error('Error reading admin');
                    navigate('/settings/admins');
                }
            }).catch(e => {
                message.error('Error reading admin');
                navigate('/settings/admins');
            });
        } else {
            setAdminTitle('New Admin')
        }
    }, [params.id, dispatch, navigate, form]);

    const onFinish = (values) => {
        if (typeof values.status === 'undefined') {
            values.status = false;
        }
        if (typeof values.isRoot === 'undefined') {
            values.isRoot = false;
        }
        if (typeof values.isTopAdmin === 'undefined') {
            values.isTopAdmin = false;
        }
        // if (typeof values.roles === 'undefined') {
        //     values.roles = [];
        // }
        if (typeof params.id !== 'undefined') {
            // Update the existing admin
            dispatch(updateAdmin(params.id, values)).then((response) => {
                console.log(response);
                if (response.result) {
                    setAdminTitle('Edit Admin - ' + response.result.name);
                    form.setFieldsValue({
                        name: response.result.name,
                        email: response.result.email,
                        status: response.result.status,
                        isRoot: response.result.isRoot,
                        isTopAdmin: response.result.isTopAdmin,
                        // roles: response.result.roles
                    });
                }
                message.success('Admin updated successfully');
            }).catch(error => {
                try {
                    let responseMessage = error.response.data.message;
                    console.log(responseMessage);
                    message.error(responseMessage);
                } catch (e) {
                    console.log('Failed:', e);
                    message.error(JSON.stringify(e));
                }
            });
        } else {
            // Create new admin
            dispatch(createAdmin(values)).then((data) => {
                message.success('Admin created successfully');
                if (data.result) {
                    navigate('/settings/admin/' + data.result.id);
                } else {
                    navigate('/settings/admins');
                }
            }).catch(error => {
                try {
                    let responseMessage = error.response.data.message;
                    console.log(responseMessage);
                    message.error(responseMessage);
                } catch (e) {
                    console.log('Failed:', e);
                    message.error(JSON.stringify(e));
                }
            });
        }
    };

    return <>
        <MemberSidebar />
        <Layout className="site-layout">
            <Content style={{ margin: '0 16px' }}>
                <div className="site-layout-background">
                    <Form {...layout} form={form} name="admin" onFinish={onFinish} initialValues={{
                        'status': true
                    }}>
                        <PageHeader
                            className="site-page-header"
                            onBack={() => navigate('/settings/admins')}
                            title={adminTitle}
                            extra={[
                                <Button key="cancel" type="primary" danger href={'/settings/admins'}>Cancel</Button>,
                                <Button key="save" type="primary" htmlType="submit">{params.id ? `Update` : `Save`}</Button>
                            ]}
                        />
                        <Form.Item name="status" label="Status" valuePropName="checked">
                            <Switch />
                        </Form.Item>
                        <Form.Item
                            name="name"
                            label="Name"
                            rules={[{ required: true, message: 'Please input admin name!' }]}
                        >
                            <Input placeholder="Admin Name" />
                        </Form.Item>
                        <Form.Item
                            name="email"
                            label="Email"
                            rules={[
                                { required: true, message: 'Please input admin email!' },
                                {
                                    type: 'email',
                                    message: 'The input is not valid E-mail!',
                                }
                            ]}
                        >
                            <Input placeholder="Admin Email" />
                        </Form.Item>
                        {isRoot(currentUser) && <Form.Item name="isRoot" label="Is Root?" valuePropName="checked">
                            <Switch />
                        </Form.Item>}
                        {isRoot(currentUser) && <Form.Item name="isTopAdmin" label="Is Top Admin?" valuePropName="checked">
                            <Switch />
                        </Form.Item>}
                        {/* <Form.Item
                            name="roles"
                            label="Roles"
                        >
                            <Select mode="multiple" placeholder="Roles for admin">
                                {roles && roles.map(role => <Select.Option key={role.id} value={role.id}>{role.name}</Select.Option>)}
                            </Select>
                        </Form.Item> */}
                    </Form>
                </div>
            </Content>
            <MemberFooter />
        </Layout>
    </>;
}

export default Admin;