import authHeader from './auth-header';
import axios from "axios";
import { API_ENTITY_COMMENT_URL } from "./config";

// API to get comment by id
const readComment = (entityId, discussionId, id) => {
    return axios.get(API_ENTITY_COMMENT_URL(entityId, discussionId) + id, {
        headers: authHeader()
    }).then(res => res.data);
}

// API to get all comments
const readComments = (entityId, discussionId) => {
    return axios.get(API_ENTITY_COMMENT_URL(entityId, discussionId), {
        headers: authHeader()
    }).then(res => res.data);
}

// API to hide comment
const hideComment = (entityId, discussionId, id) => {
    return axios
        .post(API_ENTITY_COMMENT_URL(entityId, discussionId) + id + '/hide', {}, {
            headers: authHeader()
        }).then((response) => {
            return response.data;
        });
};

// API to unhide comment
const unhideComment = (entityId, discussionId, id) => {
    return axios
        .post(API_ENTITY_COMMENT_URL(entityId, discussionId) + id + '/unhide', {}, {
            headers: authHeader()
        }).then((response) => {
            return response.data;
        });
};

// API to approve comment
const approveComment = (entityId, discussionId, id) => {
    return axios
        .post(API_ENTITY_COMMENT_URL(entityId, discussionId) + id + '/approve', {}, {
            headers: authHeader()
        }).then((response) => {
            return response.data;
        });
};

// API to disapprove comment
const disapproveComment = (entityId, discussionId, id) => {
    return axios
        .post(API_ENTITY_COMMENT_URL(entityId, discussionId) + id + '/disapprove', {}, {
            headers: authHeader()
        }).then((response) => {
            return response.data;
        });
};

// API to create comment
const createComment = (entityId, discussionId, comment) => {
    return axios
        .post(API_ENTITY_COMMENT_URL(entityId, discussionId), comment, {
            headers: authHeader()
        }).then((response) => {
            return response.data;
        });
};

// API to update comment
const updateComment = (entityId, discussionId, id, comment) => {
    return axios
        .put(API_ENTITY_COMMENT_URL(entityId, discussionId) + id, comment, {
            headers: authHeader()
        }).then((response) => {
            return response.data;
        });
};

// API to delete comment
const deleteComment = (entityId, discussionId, id) => {
    return axios
        .delete(API_ENTITY_COMMENT_URL(entityId, discussionId) + id, {
            headers: authHeader()
        }).then((response) => {
            return response.data;
        });
};

const comments = {
    createComment,
    readComment,
    readComments,
    updateComment,
    deleteComment,
    hideComment,
    unhideComment,
    approveComment,
    disapproveComment
};

export default comments;