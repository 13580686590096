import React, { useEffect, useState } from 'react';
import { MinusCircleOutlined, PlusOutlined, UpCircleOutlined, DownCircleOutlined } from '@ant-design/icons';
import { Layout, message, Button, Form, Input, Switch, Rate, Select, Drawer } from 'antd';
import { PageHeader } from '@ant-design/pro-components';
import { useNavigate, useParams } from 'react-router-dom';
import MemberSidebar from './components/member.sidebar.component';
import MemberFooter from './components/member.footer.component';
import { useDispatch, useSelector } from 'react-redux';
import {
    readComment,
    createComment,
    updateComment
} from '../actions/entity.comment'
import { userIsReadOnlyInEntity } from '../helpers/user';
import { useTranslation } from 'react-i18next';
import { readDiscussion } from '../actions/entity.discussion';
import MediaSelect from './components/mediaSelect.field';
import dot from 'dot-object';
import { API_CDN_URL } from '../services/config';
import EntityMedia from './entity.media.page';

const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 10,
    },
};

function Comment() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const params = useParams();
    const entities = useSelector(state => state.entity.entities);
    const currentEntity = entities ? entities.find(entity => entity.id === params.entityId) : null;
    const { Content } = Layout;
    const [form] = Form.useForm();
    const [commentTitle, setCommentTitle] = useState('');
    const currentUser = useSelector(state => state.auth.user);
    const userIsReadOnly = userIsReadOnlyInEntity(currentUser, currentEntity);
    const [discussion, setDiscussion] = useState(null);
    const [commentData, setCommentData] = useState(null);
    const [mediaDrawerVisible, setMediaDrawerVisible] = useState(false);
    const [currentMediaFieldName, setCurrentMediaFieldName] = useState(null);

    const showMediaDrawer = (fieldName) => {
        setCurrentMediaFieldName(fieldName);
        setMediaDrawerVisible(true);
    };

    const onMediaDrawerClose = () => {
        setMediaDrawerVisible(false);
    };

    const onSelectMedia = (media) => {
        setMediaDrawerVisible(false);
        if (currentMediaFieldName && currentEntity) {
            let url = API_CDN_URL(currentEntity) + media.path;
            let newValue = {
                [currentMediaFieldName.join('.')]: url
            };
            let formValues = form.getFieldsValue();
            // Use dot to update partial values
            newValue = dot.dot(newValue);
            formValues = dot.dot(formValues);
            newValue = { ...formValues, ...newValue };
            newValue = dot.object(newValue);
            form.setFieldsValue(newValue);
        }
    }


    const onFinish = (values) => {
        if (userIsReadOnly) {
            message.error(t('comment.message.read-only'));
            return;
        }
        if (typeof params.commentId !== 'undefined') {
            // Update the existing comment
            dispatch(updateComment(params.entityId, params.discussionId, params.commentId, values)).then((response) => {
                if (typeof response.result !== 'undefined' && typeof response.result.id !== 'undefined') {
                    setCommentData(response.result);
                    form.setFieldsValue(response.result);
                }
                message.success(t('comment.message.update-success'));
            }).catch(error => {
                try {
                    let responseMessage = error.response.data.message;
                    message.error(responseMessage);
                } catch (e) {
                    console.log('Failed:', e);
                    message.error(JSON.stringify(e));
                }
            });
        } else {
            // Create new campaign
            dispatch(createComment(params.entityId, params.discussionId, values)).then((data) => {
                message.success(t('comment.message.create-success'));
                if (data.result) {
                    navigate(`/entity/${params.entityId}/discussion/${params.discussionId}/comment/${data.result.id}`);
                } else {
                    navigate(`/entity/${params.entityId}/discussion/${params.discussionId}/comments`);
                }
            }).catch(error => {
                try {
                    let responseMessage = error.response.data.message;
                    message.error(responseMessage);
                } catch (e) {
                    console.log('Failed:', e);
                    message.error(JSON.stringify(e));
                }
            });
        }
    }

    useEffect(() => {
        let title = '';
        if (discussion) {
            title = discussion.name + ' - ';
        }
        if (params.commentId) {
            if (commentData) {
                title += t('comment.title.edit') + ' - ' + commentData.id;
            }
        } else {
            title += t('comment.title.new');
        }
        setCommentTitle(title);
    }, [params.commentId, commentData, discussion]);

    useEffect(() => {
        if (typeof params.entityId !== 'undefined' && typeof params.discussionId !== 'undefined') {
            dispatch(readDiscussion(params.entityId, params.discussionId)).then((response) => {
                console.log('123');
                if (typeof response.result !== 'undefined' && typeof response.result.id !== 'undefined') {
                    setDiscussion(response.result);
                }
            }).catch(e => {
                message.error(t('discussion.message.error-reading'));
                navigate(`/entity/${params.entityId}/discussions`);
            });
        }
        if (typeof params.commentId !== 'undefined' && typeof params.entityId !== 'undefined' && typeof params.discussionId !== 'undefined') {
            dispatch(readComment(params.entityId, params.discussionId, params.commentId)).then((response) => {
                if (typeof response.result !== 'undefined' && typeof response.result.id !== 'undefined') {
                    setCommentData(response.result);
                    form.setFieldsValue(response.result);
                } else {
                    message.error(t('comment.message.error-reading'));
                    navigate(`/entity/${params.entityId}/discussion/${params.discussionId}/comments`);
                }
            }).catch(e => {
                message.error(t('comment.message.error-reading'));
                navigate(`/entity/${params.entityId}/discussion/${params.discussionId}/comments`);
            });
        }
    }, [params.commentId, params.entityId, params.discussionId, dispatch, navigate, form]);

    const onFinishFailed = (errorInfo) => {
        if (typeof errorInfo.errorFields !== 'undefined') {
            errorInfo.errorFields.forEach(field => {
                message.error(field.errors[0]);
            });
        }
    }

    let extraButtons = [
        <Button key="cancel" type="primary" danger href={`/entity/${params.entityId}/discussion/${params.discussionId}/comments`}>{t('comment.actions.cancel')}</Button>
    ];
    if (!userIsReadOnly) {
        extraButtons.push(<Button key="save" type="primary" htmlType="submit">{params.commentId ? t('comment.actions.update') : t('comment.actions.save')}</Button>);
    }
    return <>
        <MemberSidebar />
        <Layout className="site-layout">
            <Content style={{ margin: '0 16px' }}>
                <div className="site-layout-background">
                    <Form
                        {...layout}
                        form={form}
                        name="entity-region"
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        initialValues={{
                            rating: 2.5,
                            personModel: 'Admin',
                            hidden: false,
                            approved: true,
                            person: currentUser?.id
                        }}>
                        <PageHeader
                            className="site-page-header"
                            onBack={() => navigate(`/entity/${params.entityId}/discussion/${params.discussionId}/comments`)}
                            title={commentTitle}
                            extra={extraButtons}
                        />
                        <>
                            <Form.Item
                                label={t('comment.field.rating.label')}
                                name="rating"
                                required={true}
                                rules={
                                    [
                                        {
                                            required: true,
                                            message: t('comment.field.rating.required')
                                        }
                                    ]
                                }
                            >
                                <Rate allowHalf />
                            </Form.Item>
                            <Form.Item
                                label={t('comment.field.comment.label')}
                                name="comment"
                            >
                                <Input.TextArea autoSize={{ minRows: 5, maxRows: 10 }} placeholder={t('comment.field.comment.placeholder')} />
                            </Form.Item>
                            <Form.List name={["images"]} style={{ width: '100%' }}>
                                {(fields, { add, remove, move }) => (
                                    <>
                                        <h1>{t('comment.field.images.label')}</h1>
                                        {fields.map(({ key, name, ...restField }, fieldPosition) => {
                                            return <div key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                                <MediaSelect showMediaDrawer={showMediaDrawer} form={form} wrapper={{
                                                    wrapperCol: 16,
                                                    required: true,
                                                    style: { marginBottom: 8, width: 'calc(100% - 100px)' }
                                                }} inner={{
                                                    name: [name],
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: t('comment.field.images.required')
                                                        }
                                                    ]
                                                }}
                                                    fieldName={["images", name]}
                                                    placeholder={t('comment.field.images.placeholder')} />
                                                <div style={{ width: 100 }}>
                                                    {(fields.length > 1 && fieldPosition !== 0) && <UpCircleOutlined onClick={() => move(fieldPosition, fieldPosition - 1)} style={{ marginLeft: 8 }} />}
                                                    {(fields.length > 1 && fieldPosition !== (fields.length - 1)) && <DownCircleOutlined onClick={() => move(fieldPosition, fieldPosition + 1)} style={{ marginLeft: 8 }} />}
                                                    <MinusCircleOutlined onClick={() => remove(name)} style={{ marginLeft: 8 }} />
                                                </div>
                                            </div>
                                        })}
                                        <Form.Item wrapperCol={{ span: 24 }}>
                                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                                {t('comment.field.images.add')}
                                            </Button>
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>
                            <Form.Item
                                label={t('comment.field.personModel.label')}
                                name="personModel"
                            >
                                <Select>
                                    <Select.Option value="Admin">Admin</Select.Option>
                                    <Select.Option value="User">User</Select.Option>
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label={t('comment.field.person.label')}
                                name="person"
                                required={true}
                                rules={
                                    [
                                        {
                                            required: true,
                                            message: t('comment.field.person.required')
                                        }
                                    ]
                                }
                            >
                                <Input placeholder={t('comment.field.person.placeholder')} />
                            </Form.Item>
                            <Form.Item
                                label={t('comment.field.hidden.label')}
                                name="hidden"
                                valuePropName="checked"
                            >
                                <Switch />
                            </Form.Item>
                            <Form.Item
                                label={t('comment.field.approved.label')}
                                name="approved"
                                valuePropName="checked"
                            >
                                <Switch />
                            </Form.Item>
                        </>
                    </Form>
                    <Drawer contentWrapperStyle={{ width: '1000px', maxWidth: '100%', minWidth: '50%' }} title={t('mediaDrawer.title')} placement="right" onClose={onMediaDrawerClose} open={mediaDrawerVisible}>
                        <EntityMedia entityId={params.entityId} selectMedia={onSelectMedia} />
                    </Drawer>
                </div>
            </Content>
            <MemberFooter />
        </Layout>
    </>;
}

export default Comment;