import authHeader from './auth-header';
import axios from "axios";
import { API_ENTITY_ARTICLE_URL } from "./config";

// API to get entity article by id
const readArticle = (entityId, id) => {
    return axios.get(API_ENTITY_ARTICLE_URL(entityId) + id, {
        headers: authHeader()
    }).then(res => res.data);
}

// API to get all entity articles
const readArticles = (entityId) => {
    return axios.get(API_ENTITY_ARTICLE_URL(entityId), {
        headers: authHeader()
    }).then(res => res.data);
}


// API to create entity article
const createArticle = (entityId, entityArticle) => {
    return axios
        .post(API_ENTITY_ARTICLE_URL(entityId), entityArticle, {
            headers: authHeader()
        }).then((response) => {
            return response.data;
        });
};

// API to update entity article
const updateArticle = (entityId, id, entityArticle) => {
    return axios
        .put(API_ENTITY_ARTICLE_URL(entityId) + id, entityArticle, {
            headers: authHeader()
        }).then((response) => {
            return response.data;
        });
};

// API to delete entity article
const deleteArticle = (entityId, id) => {
    return axios
        .delete(API_ENTITY_ARTICLE_URL(entityId) + id, {
            headers: authHeader()
        }).then((response) => {
            return response.data;
        });
};

// API to get active article translations
const readAllActiveArticleTranslations = (entityId) => {
    return axios.get(API_ENTITY_ARTICLE_URL(entityId) + 'translations', {
        headers: authHeader()
    }).then(res => res.data);
}

const entityArticles = {
    createArticle,
    readAllActiveArticleTranslations,
    readArticle,
    readArticles,
    updateArticle,
    deleteArticle
};

export default entityArticles;