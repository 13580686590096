import { Input, Form, Row, Col, Button } from "antd";
import { PlusOutlined, MinusCircleOutlined, UpCircleOutlined, DownCircleOutlined } from '@ant-design/icons';
import MediaSelect from "./mediaSelect.field";

export default function AboutPageComponents({ t, language, form, showMediaDrawer }) {
    return <>
        <div style={{ padding: 16, backgroundColor: '#eeeeee', marginBottom: 16 }}>
            <h2>{t('entityPage.field.about.teamList.sectionTitle')}</h2>
            <Form.List name={[language.value, 'data', 'about', 'teamList']} style={{ width: '100%' }}>
                {(fields, { add, remove, move }) => (
                    <>
                        {fields.map(({ key, name, ...restField }, fieldPosition) => {
                            return <div key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                <Row gutter={[16, 0]} style={{ width: 'calc(100% - 100px)' }}>
                                    <Col span={12}>
                                        <Form.Item
                                            {...restField}
                                            label={t('entityPage.field.about.teamList.name.label')}
                                            name={[name, 'name']}
                                            required={true}
                                            wrapperCol={{ span: 24 }}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: t('entityPage.field.about.teamList.name.required')
                                                }
                                            ]}
                                        >
                                            <Input placeholder={t('entityPage.field.about.teamList.name.placeholder')} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            {...restField}
                                            label={t('entityPage.field.about.teamList.title.label')}
                                            name={[name, 'title']}
                                            wrapperCol={{ span: 24 }}
                                        >
                                            <Input placeholder={t('entityPage.field.about.teamList.title.placeholder')} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <MediaSelect showMediaDrawer={showMediaDrawer} form={form} wrapper={{
                                            wrapperCol: 16,
                                            required: true,
                                        }} inner={{
                                            name: [name, 'image'],
                                            rules: [
                                                {
                                                    required: true,
                                                    message: t('entityPage.field.about.teamList.image.required')
                                                }
                                            ]
                                        }}
                                            fieldName={[language.value, 'data', 'about', 'teamList', name, 'image']}
                                            placeholder={t('entityPage.field.about.teamList.image.placeholder')} />
                                    </Col>
                                </Row>
                                <div style={{ width: 100 }}>
                                    {(fields.length > 1 && fieldPosition !== 0) && <UpCircleOutlined onClick={() => move(fieldPosition, fieldPosition - 1)} style={{ marginLeft: 8 }} />}
                                    {(fields.length > 1 && fieldPosition !== (fields.length - 1)) && <DownCircleOutlined onClick={() => move(fieldPosition, fieldPosition + 1)} style={{ marginLeft: 8 }} />}
                                    <MinusCircleOutlined onClick={() => remove(name)} style={{ marginLeft: 8 }} />
                                </div>
                            </div>
                        })}
                        <Form.Item wrapperCol={{ span: 24 }}>
                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                {t('entityPage.field.about.teamList.add')}
                            </Button>
                        </Form.Item>
                    </>
                )}
            </Form.List>
            <Form.Item
                label={t('entityPage.field.about.teamDescription.label')}
                name={[language.value, 'data', 'about', 'teamDescription']}
            >
                <Input.TextArea placeholder={t('entityPage.field.about.teamDescription.placeholder')} />
            </Form.Item>
            <Form.Item
                label={t('entityPage.field.about.teamCtaText.label')}
                name={[language.value, 'data', 'about', 'teamCtaText']}
            >
                <Input placeholder={t('entityPage.field.about.teamCtaText.placeholder')} />
            </Form.Item>
            <Form.Item
                label={t('entityPage.field.about.teamCtaLink.label')}
                name={[language.value, 'data', 'about', 'teamCtaLink']}
            >
                <Input placeholder={t('entityPage.field.about.teamCtaLink.placeholder')} />
            </Form.Item>
        </div>
    </>
}