import EntityService from "../services/entity.service";
import {
    READ_ENTITIES_SUCCESS,
    DELETE_ENTITY_SUCCESS
} from './types';

// Action to get entity by id
export const readEntity = (id) => (dispatch) => {
    return EntityService.readEntity(id).then((data) => {
        console.log(data);
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to get all entities
export const readEntities = () => (dispatch) => {
    return EntityService.readEntities().then((data) => {
        if (data.result) {
            dispatch({
                type: READ_ENTITIES_SUCCESS,
                payload: data.result
            });
        }
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to create the entity
export const createEntity = (entity) => (dispatch) => {
    return EntityService.createEntity(entity).then((data) => {
        console.log(data);
        return Promise.resolve(data);
    }, (error) => {
        console.log(error.message);
        return Promise.reject(error);
    });
}

// Action to update the entity
export const updateEntity = (id, entity) => (dispatch) => {
    return EntityService.updateEntity(id, entity).then((data) => {
        console.log(data);
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to delete the entity
export const deleteEntity = (id) => (dispatch) => {
    return EntityService.deleteEntity(id).then((data) => {
        dispatch({
            type: DELETE_ENTITY_SUCCESS,
            payload: id
        });
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}