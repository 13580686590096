import authHeader from './auth-header';
import axios from "axios";
import { API_ENTITY_URL } from "./config";

// API to get entity by id
const readEntity = (id) => {
    return axios.get(API_ENTITY_URL + id, {
        headers: authHeader()
    }).then(res => res.data);
}

// API to get all entities
const readEntities = () => {
    return axios.get(API_ENTITY_URL, {
        headers: authHeader()
    }).then(res => res.data);
}


// API to create entity
const createEntity = (entity) => {
    return axios
        .post(API_ENTITY_URL, entity, {
            headers: authHeader()
        }).then((response) => {
            return response.data;
        });
};

// API to update entity
const updateEntity = (id, entity) => {
    return axios
        .put(API_ENTITY_URL + id, entity, {
            headers: authHeader()
        }).then((response) => {
            return response.data;
        });
};

// API to delete entity
const deleteEntity = (id) => {
    return axios
        .delete(API_ENTITY_URL + id, {
            headers: authHeader()
        }).then((response) => {
            return response.data;
        });
};

const entity = {
    createEntity,
    readEntity,
    readEntities,
    updateEntity,
    deleteEntity
};

export default entity;