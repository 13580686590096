import authHeader from './auth-header';
import axios from "axios";
import { API_ENTITY_TRIP_COUNTRY_URL } from "./config";

// API to get active trip country translations
const readAllActiveTripCountriesTranslations = (entityId) => {
    return axios.get(API_ENTITY_TRIP_COUNTRY_URL(entityId) + 'translations', {
        headers: authHeader()
    }).then(res => res.data);
}

// API to get trip country by id
const readTripCountry = (entityId, id) => {
    return axios.get(API_ENTITY_TRIP_COUNTRY_URL(entityId) + id, {
        headers: authHeader()
    }).then(res => res.data);
}

// API to get all trip countries
const readTripCountries = (entityId) => {
    return axios.get(API_ENTITY_TRIP_COUNTRY_URL(entityId), {
        headers: authHeader()
    }).then(res => res.data);
}


// API to create trip country
const createTripCountry = (entityId, entityCountry) => {
    return axios
        .post(API_ENTITY_TRIP_COUNTRY_URL(entityId), entityCountry, {
            headers: authHeader()
        }).then((response) => {
            return response.data;
        });
};

// API to update trip country
const updateTripCountry = (entityId, id, entityCountry) => {
    return axios
        .put(API_ENTITY_TRIP_COUNTRY_URL(entityId) + id, entityCountry, {
            headers: authHeader()
        }).then((response) => {
            return response.data;
        });
};

// API to delete trip country
const deleteTripCountry = (entityId, id) => {
    return axios
        .delete(API_ENTITY_TRIP_COUNTRY_URL(entityId) + id, {
            headers: authHeader()
        }).then((response) => {
            return response.data;
        });
};

const entityCountries = {
    readAllActiveTripCountriesTranslations,
    createTripCountry,
    readTripCountry,
    readTripCountries,
    updateTripCountry,
    deleteTripCountry
};

export default entityCountries;