import { useTranslation } from 'react-i18next';
import { Descriptions } from "antd";
import { API_MEDIA_URL, API_USER_MEDIA_URL } from '../../services/config';

const basicFields = [
    { label: "First Name", key: "firstName" },
    { label: "Last Name", key: "lastName" },
    { label: "First Name ZH", key: "firstName_zh" },
    { label: "Last Name ZH", key: "lastName_zh" },
    { label: "Name", key: "name" },
    { label: "Gender", key: "gender" },
    { label: "Birth Place", key: "birthPlace" },
    { label: "Birth Date", key: "dob" },
    { label: "Phone Region", key: "phoneRegion" },
    { label: "Phone Number", key: "phoneNumber" },
    { label: "Email", key: "email" },
    { label: "Address", key: "address" },
    { label: "Job Title", key: "jobtitle" },
    { label: "Company Or School", key: "companyOrSchool" },
    { label: "Company Or School Address", key: "companyOrSchoolAddress" }
];
const passportFields = [
    { label: "Nationality", key: "nationality", },
    { label: "Original Nationality", key: "originalNationality", },
    { label: "Passport Number", key: "passportNumber", },
    { label: "Passport Issued Date", key: "passportIssuedDate", },
    { label: "Passport Expired Date", key: "passportExpiredDate", },
    { label: "China Related Number", key: "chinaRelatedNumber", },
    { label: "China Related Issued Date", key: "chinaRelatedIssuedDate", },
    { label: "China Related Expired Date", key: "chinaRelatedExpiredDate", },
    { label: "Media Documents", key: "mediaDocuments", },
];
const contactFields = [
    { label: "Join Whatsapp Group", key: "joinWhatsappGroup" },
    { label: "Whatsapp Number", key: "whatsappNumber" },
    { label: "Emergency First Name", key: "emergencyFirstname" },
    { label: "Emergency Last Name", key: "emergencyLastname" },
    { label: "Emergency Phone Region", key: "emergencyPhoneRegion" },
    { label: "Emergency Phone Number", key: "emergencyPhoneNumber" },
];

export default function CompanionDetail(props) {
    const { t } = useTranslation();
    const { shortToken } = props;
    return <>
        <Descriptions title="Basic" column={2}>
            {basicFields.map(field => {
                return <Descriptions.Item label={field.label} key={field.key}>{props.basic[field.key]}</Descriptions.Item>
            })}
        </Descriptions>
        {props.passport && <Descriptions title="Passport" column={2}>
            {passportFields.map(field => {
                if (field.key === 'mediaDocuments') {
                    return <Descriptions.Item label={field.label} key={field.key}><ul>{props.passport[field.key].map(document => {
                        return <li>{document._id ? <a target="_blank" href={API_USER_MEDIA_URL(document.entity) + document._id + '?token=' + (shortToken ? shortToken : '')}>{document._id}</a> : document}</li>
                    })}</ul></Descriptions.Item>
                } else {
                    return <Descriptions.Item label={field.label} key={field.key}>{props.passport[field.key]}</Descriptions.Item>
                }
            })}
        </Descriptions>}
        {props.contact && <Descriptions title="Contact" column={2}>
            {contactFields.map(field => {
                return <Descriptions.Item label={field.label} key={field.key}>{props.contact[field.key]}</Descriptions.Item>
            })}
        </Descriptions>}
    </>
}