import moment from "moment-timezone";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('Asia/Hong_Kong');


function formatDate(value, format) {
    if (typeof format === 'undefined') {
        format = 'YYYY-MM-DD';
    }
    return moment(value).format(format);
}

function changeToMommentObject(value) {
    console.log(moment(value));
    return moment(value);
}

function changeToDayJsObject(value, key) {
    dayjs.tz.setDefault('Asia/Hong_Kong');
    console.log(dayjs(value).tz());
    return dayjs(value).tz();
}

function changeToDateString(value, key) {
    console.log('123');
    return value.toISOString();
}

function changeToDateUTC(value) {
    return value.toISOString();
}

function changeToDateTimeUTC(value) {
    return value.toISOString();
    // convert to UTC
    // let date = value.format('YYYY-MM-DDTHH:mm:ss+08:00');
    // return moment(date).toDate();
}

export {
    formatDate,
    changeToDayJsObject,
    changeToMommentObject,
    changeToDateString,
    changeToDateUTC,
    changeToDateTimeUTC
}