import React, { useEffect, useState } from 'react';
import { Layout, Popconfirm, message, Button, Table, Space } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import MemberSidebar from './components/member.sidebar.component';
import MemberFooter from './components/member.footer.component';
import { useDispatch } from 'react-redux';
import { readDiscussions, deleteDiscussion } from '../actions/entity.discussion';
import { PageHeader } from '@ant-design/pro-components';
import { useTranslation } from 'react-i18next';

function Discussions(props) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const params = useParams();
    const { Content } = Layout;
    const [dataSource, setDataSource] = useState([]);

    const deleteDiscussionAction = (id) => {
        dispatch(deleteDiscussion(params.entityId, id)).then((response) => {
            message.success(t('entities.message.delete-success'));
            setDataSource(dataSource.filter(item => item.id !== id));
        }).catch(e => {
            console.log(e);
            message.error(t('entities.message.delete-failed'));
        });
    }

    const columns = [
        {
            title: t('discussions.columns.name'),
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: t('discussions.columns.status'),
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => {
                if (typeof record.status !== 'undefined' && record.status === true) {
                    return t('discussions.columns.status.enabled');
                } else {
                    return t('discussions.columns.status.disabled');
                }
            }
        },
        {
            title: t('discussions.columns.created'),
            dataIndex: 'createdAt',
            key: 'createdAt'
        },
        {
            title: t('discussions.columns.actions'),
            dataIndex: 'actions',
            key: 'actions',
            render: (text, record) => <Space>
                <Button size="small" type="primary" href={`/entity/${params.entityId}/discussion/${record.id}`}>{t('discussions.actions.edit')}</Button>
                <Button size="small" type="primary" href={`/entity/${params.entityId}/discussion/${record.id}/comments`}>{t('discussions.actions.view-comment')}</Button>
                <Popconfirm
                    title={t('discussions.actions.delete-confirm')}
                    onConfirm={() => deleteDiscussionAction(record.id)}
                    okText={t('discussions.actions.delete-ok')}
                    cancelText={t('discussions.actions.delete-cancel')}
                >
                    <Button size="small" type="primary" danger>{t('discussions.actions.delete')}</Button>
                </Popconfirm>
            </Space>

        }
    ];

    useEffect(() => {
        dispatch(readDiscussions(params.entityId)).then((response) => {
            if (typeof response.result !== 'undefined') {
                setDataSource(response.result);
            } else {
                setDataSource([]);
            }
        }).catch((error) => {
            try {
                setDataSource([]);
                let responseMessage = error.response.data.message;
                console.log(responseMessage);
                message.error(responseMessage);
            } catch (e) {
                console.log('Failed:', e);
                message.error(JSON.stringify(e));
            }
        });
    }, [dispatch, params.entityId]);

    return <>
        <MemberSidebar />
        <Layout className="site-layout">
            <Content style={{ margin: '0 16px' }}>
                <div className="site-layout-background">
                    <PageHeader
                        className="site-page-header"
                        onBack={() => navigate(`/entity/${params.entityId}/dashboard`)}
                        title={t('discussions.title')}
                        subTitle={t('discussions.subtitle')}
                        extra={[
                            <Button key="create" type="primary" href={`/entity/${params.entityId}/discussion`}>{t('discussions.actions.new')}</Button>
                        ]}
                    />
                    <Table
                        size='small'
                        rowKey={record => record.id}
                        dataSource={dataSource}
                        columns={columns} />
                </div>
            </Content>
            <MemberFooter />
        </Layout>
    </>;
}

export default Discussions;