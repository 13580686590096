import authHeader from './auth-header';
import axios from "axios";
import { API_ENTITY_DISCUSSION_URL } from "./config";
import qs from 'qs';

// API to search discussion by name
const searchDiscussion = (entityId, name) => {
    return axios
        .get(API_ENTITY_DISCUSSION_URL(entityId) + "search?name=" + name, {
            headers: authHeader()
        }).then((response) => {
            return response.data;
        });
};

// API to count all comments in the discussion
const countComments = (entityId, id) => {
    return axios.get(API_ENTITY_DISCUSSION_URL(entityId) + id + 'count', {
        headers: authHeader()
    }).then(res => res.data);
}

// API to get discussion by id
const readDiscussion = (entityId, id, params) => {
    return axios.get(API_ENTITY_DISCUSSION_URL(entityId) + id + '?' + qs.stringify(params), {
        headers: authHeader()
    }).then(res => res.data);
}

// API to get all discussions
const readDiscussions = (entityId) => {
    return axios.get(API_ENTITY_DISCUSSION_URL(entityId), {
        headers: authHeader()
    }).then(res => res.data);
}


// API to create discussion
const createDiscussion = (entityId, discussion) => {
    return axios
        .post(API_ENTITY_DISCUSSION_URL(entityId), discussion, {
            headers: authHeader()
        }).then((response) => {
            return response.data;
        });
};

// API to update discussion
const updateDiscussion = (entityId, id, discussion) => {
    return axios
        .put(API_ENTITY_DISCUSSION_URL(entityId) + id, discussion, {
            headers: authHeader()
        }).then((response) => {
            return response.data;
        });
};

// API to delete discussion
const deleteDiscussion = (entityId, id) => {
    return axios
        .delete(API_ENTITY_DISCUSSION_URL(entityId) + id, {
            headers: authHeader()
        }).then((response) => {
            return response.data;
        });
};

const discussions = {
    searchDiscussion,
    createDiscussion,
    readDiscussion,
    countComments,
    readDiscussions,
    updateDiscussion,
    deleteDiscussion
};

export default discussions;