import React, { useEffect, useState } from 'react';
import { Layout, message, Button, Form, Input, Switch, Select, Tabs, Drawer } from 'antd';
import { PageHeader } from '@ant-design/pro-components';
import { useNavigate, useParams } from 'react-router-dom';
import MemberSidebar from './components/member.sidebar.component';
import MemberFooter from './components/member.footer.component';
import { useDispatch, useSelector } from 'react-redux';
import { green, gold } from '@ant-design/colors';
import {
    readUser,
    createUser,
    updateUser
} from '../actions/entity.user'
import { userIsReadOnlyInEntity } from '../helpers/user';
import { useTranslation } from 'react-i18next';

const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 10,
    },
};

function User() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const params = useParams();
    const entities = useSelector(state => state.entity.entities);
    const currentEntity = entities ? entities.find(entity => entity.id === params.entityId) : null;
    const { Content } = Layout;
    const [form] = Form.useForm();
    const [userTitle, setUserTitle] = useState('');
    const currentUser = useSelector(state => state.auth.user);
    const userIsReadOnly = userIsReadOnlyInEntity(currentUser, currentEntity);
    const requiredTripSupportAllLanguages = currentEntity && currentEntity.requiredTripSupportAllLanguages;
    const [addedLanguages, setAddedLanguages] = useState([]);
    const [mediaDrawerVisible, setMediaDrawerVisible] = useState(false);
    const [currentMediaFieldName, setCurrentMediaFieldName] = useState(null);


    const onFinish = (values) => {
        if (userIsReadOnly) {
            message.error(t('user.message.read-only'));
            return;
        }
        if (typeof params.userId !== 'undefined') {
            // Update the existing user
            dispatch(updateUser(params.entityId, params.userId, values)).then((response) => {
                if (typeof response.result !== 'undefined' && typeof response.result.id !== 'undefined') {
                    setUserTitle(`${t('user.title.edit')} - ${response.result.id}`);
                    form.setFieldsValue(response.result);
                }
                message.success(t('user.message.update-success'));
            }).catch(error => {
                try {
                    let responseMessage = error.response.data.message;
                    message.error(responseMessage);
                } catch (e) {
                    console.log('Failed:', e);
                    message.error(JSON.stringify(e));
                }
            });
        } else {
            // Create new campaign
            dispatch(createUser(params.entityId, values)).then((data) => {
                message.success(t('user.message.create-success'));
                if (data.result) {
                    navigate(`/entity/${params.entityId}/user/${data.result.id}`);
                } else {
                    navigate(`/entity/${params.entityId}/users`);
                }
            }).catch(error => {
                try {
                    let responseMessage = error.response.data.message;
                    message.error(responseMessage);
                } catch (e) {
                    console.log('Failed:', e);
                    message.error(JSON.stringify(e));
                }
            });
        }
    }

    useEffect(() => {
        if (typeof params.userId !== 'undefined') {
            setUserTitle(t('user.title.edit'));
            dispatch(readUser(params.entityId, params.userId)).then((response) => {
                if (typeof response.result !== 'undefined' && typeof response.result.id !== 'undefined') {
                    setUserTitle(`${t('user.title.edit')} - ${response.result.id}`);
                    form.setFieldsValue(response.result);
                } else {
                    message.error(t('user.message.error-reading'));
                    navigate(`/entity/${params.entityId}/users`);
                }
            }).catch(e => {
                message.error(t('user.message.error-reading'));
                navigate(`/entity/${params.entityId}/users`);
            });
        } else {
            setUserTitle(t('user.title.new'));
        }
    }, [params.userId, params.entityId, dispatch, navigate, form]);

    const onFinishFailed = (errorInfo) => {
        if (typeof errorInfo.errorFields !== 'undefined') {
            errorInfo.errorFields.forEach(field => {
                message.error(field.errors[0]);
            });
        }
    }

    let extraButtons = [
        <Button key="cancel" type="primary" danger href={`/entity/${params.entityId}/users`}>{t('user.actions.cancel')}</Button>,
        <Button key="orders" type="primary" style={{ backgroundColor: green[5] }} href={`/entity/${params.entityId}/orders?user=${params.userId}`}>{t('user.actions.orders')}</Button>,
        <Button key="companion" type="primary" style={{ backgroundColor: gold[5] }} href={`/entity/${params.entityId}/companions?user=${params.userId}`}>{t('user.actions.companions')}</Button>
    ];
    if (!userIsReadOnly) {
        extraButtons.push(<Button key="save" type="primary" htmlType="submit">{params.userId ? t('user.actions.update') : t('user.actions.save')}</Button>);
    }
    return <>
        <MemberSidebar />
        <Layout className="site-layout">
            <Content style={{ margin: '0 16px' }}>
                <div className="site-layout-background">
                    <Form
                        {...layout}
                        form={form}
                        name="entity-user"
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        initialValues={{
                            keyIsVerified: true,
                            status: true,
                            // hasSentInvitation: false,
                            // needChangePassword: true
                        }}>
                        <PageHeader
                            className="site-page-header"
                            onBack={() => navigate(`/entity/${params.entityId}/users`)}
                            title={userTitle}
                            extra={extraButtons}
                        />
                        <>
                            <Form.Item
                                label={t('user.field.uuid.label')}
                                name="uuid"
                                disabled={true}
                            >
                                <Input disabled={true} />
                            </Form.Item>
                            <Form.Item
                                label={t('user.field.status.label')}
                                name="status"
                                valuePropName="checked"
                            >
                                <Switch />
                            </Form.Item>
                            {/* <Form.Item
                                label={t('user.field.keyIsVerified.label')}
                                name="keyIsVerified"
                                valuePropName="checked"
                            >
                                <Switch />
                            </Form.Item> */}
                            {/* <Form.Item
                                label={t('user.field.hasSentInvitation.label')}
                                name="hasSentInvitation"
                                valuePropName="checked"
                            >
                                <Switch />
                            </Form.Item>
                            <Form.Item
                                label={t('user.field.needChangePassword.label')}
                                name="needChangePassword"
                                valuePropName="checked"
                            >
                                <Switch />
                            </Form.Item> */}
                            <Form.Item
                                label={t('user.field.email.label')}
                                name="email"
                                required={true}
                                rules={[
                                    {
                                        required: true,
                                        message: t('user.field.email.required-message'),
                                    },
                                    {
                                        type: 'email',
                                        message: t('user.field.email.invalid-message'),
                                    }
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label={t('user.field.firstName.label')}
                                name="firstName"
                                required={true}
                                rules={[
                                    {
                                        required: true,
                                        message: t('user.field.firstName.required-message'),
                                    }
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item

                                label={t('user.field.lastName.label')}
                                name="lastName"
                                required={true}
                                rules={[
                                    {
                                        required: true,
                                        message: t('user.field.lastName.required-message'),
                                    }
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </>
                    </Form>
                </div>
            </Content>
            <MemberFooter />
        </Layout>
    </>;
}

export default User;