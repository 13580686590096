import ArticleService from "../services/entity.article.service";

// Action to get entity by id
export const readArticle = (entityId, id) => (dispatch) => {
    return ArticleService.readArticle(entityId, id).then((data) => {
        console.log(data);
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to get all active entity article translations by entity id
export const readAllActiveArticleTranslations = (entityId) => (dispatch) => {
    return ArticleService.readAllActiveArticleTranslations(entityId).then((data) => {
        console.log(data);
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to get all entity articles
export const readArticles = (entityId) => (dispatch) => {
    return ArticleService.readArticles(entityId).then((data) => {
        // if (data.result) {
        //     dispatch({
        //         type: READ_ENTITY_REGIONS_SUCCESS,
        //         payload: data.result
        //     });
        // }
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to create the entity article
export const createArticle = (entityId, article) => (dispatch) => {
    return ArticleService.createArticle(entityId, article).then((data) => {
        console.log(data);
        return Promise.resolve(data);
    }, (error) => {
        console.log(error.message);
        return Promise.reject(error);
    });
}

// Action to update the entity article
export const updateArticle = (entityId, id, article) => (dispatch) => {
    return ArticleService.updateArticle(entityId, id, article).then((data) => {
        console.log(data);
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to delete the entity article
export const deleteArticle = (entityId, id) => (dispatch) => {
    return ArticleService.deleteArticle(entityId, id).then((data) => {
        // dispatch({
        //     type: DELETE_ENTITY_REGION_SUCCESS,
        //     payload: id
        // });
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}