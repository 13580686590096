import UserService from "../services/entity.user.service";

// Action to get entity by id
export const readUser = (entityId, id) => (dispatch) => {
    return UserService.readUser(entityId, id).then((data) => {
        console.log(data);
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to count all users
export const countUsers = (entityId, params) => (dispatch) => {
    return UserService.countUsers(entityId, params).then((data) => {
        console.log(data);
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to get all users
export const readUsers = (entityId, params) => (dispatch) => {
    return UserService.readUsers(entityId, params).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to enable user
export const enableUser = (entityId, id) => (dispatch) => {
    return UserService.enableUser(entityId, id).then((data) => {
        console.log(data);
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to disable user
export const disableUser = (entityId, id) => (dispatch) => {
    return UserService.disableUser(entityId, id).then((data) => {
        console.log(data);
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to create the user
export const createUser = (entityId, user) => (dispatch) => {
    return UserService.createUser(entityId, user).then((data) => {
        console.log(data);
        return Promise.resolve(data);
    }, (error) => {
        console.log(error.message);
        return Promise.reject(error);
    });
}

// Action to update the user
export const updateUser = (entityId, id, user) => (dispatch) => {
    return UserService.updateUser(entityId, id, user).then((data) => {
        console.log(data);
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to delete the user
export const deleteUser = (entityId, id) => (dispatch) => {
    return UserService.deleteUser(entityId, id).then((data) => {
        // dispatch({
        //     type: DELETE_ENTITY_REGION_SUCCESS,
        //     payload: id
        // });
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}