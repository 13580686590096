import TripHolidayService from "../services/entity.trip.holiday.service";

// Action to get all active trip holiday translations by entity id
export const readAllActiveTripHolidaysTranslations = (entityId) => (dispatch) => {
    return TripHolidayService.readAllActiveTripHolidaysTranslations(entityId).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to get entity by id
export const readTripHoliday = (entityId, id) => (dispatch) => {
    return TripHolidayService.readTripHoliday(entityId, id).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to get all trip holidays
export const readTripHolidays = (entityId) => (dispatch) => {
    return TripHolidayService.readTripHolidays(entityId).then((data) => {
        // if (data.result) {
        //     dispatch({
        //         type: READ_ENTITY_REGIONS_SUCCESS,
        //         payload: data.result
        //     });
        // }
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to create the trip holiday
export const createTripHoliday = (entityId, tripHoliday) => (dispatch) => {
    return TripHolidayService.createTripHoliday(entityId, tripHoliday).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        console.log(error.message);
        return Promise.reject(error);
    });
}

// Action to update the trip holiday
export const updateTripHoliday = (entityId, id, tripHoliday) => (dispatch) => {
    return TripHolidayService.updateTripHoliday(entityId, id, tripHoliday).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to delete the trip holiday
export const deleteTripHoliday = (entityId, id) => (dispatch) => {
    return TripHolidayService.deleteTripHoliday(entityId, id).then((data) => {
        // dispatch({
        //     type: DELETE_ENTITY_REGION_SUCCESS,
        //     payload: id
        // });
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}