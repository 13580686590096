import React, { useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from 'react-redux';
import { Layout, message } from 'antd';
import { useDispatch } from 'react-redux';
import { currentUser as getCurrentUser } from "../actions/auth";
import { isRootOrTopAdmin, isRegionalAdminOrHigher } from '../helpers/user';
import {
    Routes,
    Route
} from "react-router-dom";
import Dashboard from './dashboard.page';
import Profile from './profile.page';
import Entities from './entities.page';
import Entity from './entity.page';
import EntityDashboard from './entity.dashboard.page';
import EntityMedia from './entity.media.page';
import EntityUserMedia from './entity.user.media.page';
import SettingDashboard from './setting.dashboard.page';
import Trips from './entity.trips.page';
import Trip from './entity.trip.page';
import TripRegions from './entity.trip.regions.page';
import TripRegion from './entity.trip.region.page';
import TripCountries from './entity.trip.countries.page';
import TripCountry from './entity.trip.country.page';
import TripHolidays from './entity.trip.holidays.page';
import TripHoliday from './entity.trip.holiday.page';
import TripTags from './entity.trip.tags.page';
import TripTag from './entity.trip.tag.page';
import TripCollections from './entity.trip.collections.page';
import TripCollection from './entity.trip.collection.page';
import TripTopics from './entity.trip.topics.page';
import TripTopic from './entity.trip.topic.page';
import TripTypes from './entity.trip.types.page';
import TripType from './entity.trip.type.page';

import Discussions from './entity.discussions.page';
import Discussion from './entity.discussion.page';
import Comments from './entity.comments.page';
import Comment from './entity.comment.page';

import EntityPages from './entity.pages.page';
import EntityPage from './entity.page.page';

import ArticleTags from './entity.article.tags.page';
import ArticleTag from './entity.article.tag.page';
import ArticleCategories from './entity.article.categories.page';
import ArticleCategory from './entity.article.category.page';
import Articles from './entity.articles.page';
import Article from './entity.article.page';

import Companions from './entity.companions.page';
import Companion from './entity.companion.page';
import Users from './entity.users.page';
import User from './entity.user.page';
import Orders from './entity.orders.page';
import Order from './entity.order.page';
import Admins from './admins.page';
import Admin from './admin.page';


function MemberSection() {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const isLoggedIn = useSelector(state => state.auth.isLoggedIn);
    const currentUser = useSelector(state => state.auth.user);

    // Check whether should recirect to login
    // And also need to redirect to change password first
    useEffect(() => {
        if (!isLoggedIn || !currentUser) {
            navigate('/login');
        } else {
            if (typeof currentUser.needChangePassword === 'undefined' || currentUser.needChangePassword === null || currentUser.needChangePassword === true) {
                navigate('/update-password');
            }
        }
    }, [isLoggedIn, currentUser, navigate]);

    // Every restricted page should check whether the access token is stil valid.
    useEffect(() => {
        dispatch(getCurrentUser()).then(() => {

        }).catch((error) => {
            try {
                let responseMessage = error.response.data.message;
                message.error(responseMessage);
            } catch (e) {
                console.log('Failed:', e);
                message.error(JSON.stringify(e));
            }
        });
    }, [location.pathname, dispatch]);

    // Undetermined state need to render empty content
    if (!isLoggedIn || !currentUser) {
        return null;
    } else {
        return <Layout className="crm-layout" style={{ minHeight: '100vh' }}>
            <Routes>
                {
                    isRegionalAdminOrHigher(currentUser) &&
                    <>
                        <Route path="/entity/:entityId/dashboard" element={<EntityDashboard />} />
                        <Route path="entity/:entityId/media" element={<EntityMedia />} />
                        <Route path="entity/:entityId/user-media" element={<EntityUserMedia />} />

                        <Route path="/entity/:entityId/trips" element={<Trips />} />
                        <Route path="/entity/:entityId/trip" element={<Trip />} />
                        <Route path="/entity/:entityId/trip/:tripId" element={<Trip />} />

                        <Route path="/entity/:entityId/trip-regions" element={<TripRegions />} />
                        <Route path="/entity/:entityId/trip-region" element={<TripRegion />} />
                        <Route path="/entity/:entityId/trip-region/:regionId" element={<TripRegion />} />

                        <Route path="/entity/:entityId/trip-countries" element={<TripCountries />} />
                        <Route path="/entity/:entityId/trip-country" element={<TripCountry />} />
                        <Route path="/entity/:entityId/trip-country/:countryId" element={<TripCountry />} />

                        <Route path="/entity/:entityId/trip-holidays" element={<TripHolidays />} />
                        <Route path="/entity/:entityId/trip-holiday" element={<TripHoliday />} />
                        <Route path="/entity/:entityId/trip-holiday/:holidayId" element={<TripHoliday />} />

                        <Route path="/entity/:entityId/trip-tags" element={<TripTags />} />
                        <Route path="/entity/:entityId/trip-tag" element={<TripTag />} />
                        <Route path="/entity/:entityId/trip-tag/:tripTagId" element={<TripTag />} />

                        <Route path="/entity/:entityId/trip-collections" element={<TripCollections />} />
                        <Route path="/entity/:entityId/trip-collection" element={<TripCollection />} />
                        <Route path="/entity/:entityId/trip-collection/:collectionId" element={<TripCollection />} />

                        <Route path="/entity/:entityId/trip-topics" element={<TripTopics />} />
                        <Route path="/entity/:entityId/trip-topic" element={<TripTopic />} />
                        <Route path="/entity/:entityId/trip-topic/:topicId" element={<TripTopic />} />

                        <Route path="/entity/:entityId/trip-types" element={<TripTypes />} />
                        <Route path="/entity/:entityId/trip-type" element={<TripType />} />
                        <Route path="/entity/:entityId/trip-type/:typeId" element={<TripType />} />

                        <Route path="/entity/:entityId/discussions" element={<Discussions />} />
                        <Route path="/entity/:entityId/discussion/:discussionId/comments" element={<Comments />} />
                        <Route path="/entity/:entityId/discussion/:discussionId/comment" element={<Comment />} />
                        <Route path="/entity/:entityId/discussion/:discussionId/comment/:commentId" element={<Comment />} />
                        <Route path="/entity/:entityId/discussion" element={<Discussion />} />
                        <Route path="/entity/:entityId/discussion/:discussionId" element={<Discussion />} />

                        <Route path="/entity/:entityId/fixed-pages" element={<EntityPages />} />
                        <Route path="/entity/:entityId/fixed-page" element={<EntityPage />} />
                        <Route path="/entity/:entityId/fixed-page/:pageId" element={<EntityPage />} />

                        <Route path="/entity/:entityId/pages" element={<EntityPages />} />
                        <Route path="/entity/:entityId/page" element={<EntityPage />} />
                        <Route path="/entity/:entityId/page/:pageId" element={<EntityPage />} />

                        <Route path="/entity/:entityId/article-tags" element={<ArticleTags />} />
                        <Route path="/entity/:entityId/article-tag" element={<ArticleTag />} />
                        <Route path="/entity/:entityId/article-tag/:articleTagId" element={<ArticleTag />} />

                        <Route path="/entity/:entityId/article-categories" element={<ArticleCategories />} />
                        <Route path="/entity/:entityId/article-category" element={<ArticleCategory />} />
                        <Route path="/entity/:entityId/article-category/:articleCategoryId" element={<ArticleCategory />} />

                        <Route path="/entity/:entityId/articles" element={<Articles />} />
                        <Route path="/entity/:entityId/article" element={<Article />} />
                        <Route path="/entity/:entityId/article/:articleId" element={<Article />} />

                        <Route path="/entity/:entityId/orders" element={<Orders />} />
                        <Route path="/entity/:entityId/order/:orderId" element={<Order />} />

                        <Route path="/entity/:entityId/users" element={<Users />} />
                        <Route path="/entity/:entityId/user" element={<User />} />
                        <Route path="/entity/:entityId/user/:userId" element={<User />} />

                        <Route path="/entity/:entityId/companions" element={<Companions />} />
                        <Route path="/entity/:entityId/companion" element={<Companion />} />
                        <Route path="/entity/:entityId/companion/:companionId" element={<Companion />} />
                    </>
                }
                {isRootOrTopAdmin(currentUser) &&
                    <>
                        <Route path="/settings/entities" element={<Entities />} />
                        <Route path="/settings/entity" element={<Entity />} />
                        <Route path="/settings/entity/:id" element={<Entity />} />
                        <Route path="/settings/admins" element={<Admins />} />
                        <Route path="/settings/admin" element={<Admin />} />
                        <Route path="/settings/admin/:id" element={<Admin />} />
                        {/* <Route path="/settings/logs" element={<Logs />} />
                        <Route path="/settings/roles" element={<Roles />} />
                        <Route path="/settings/role" element={<Role />} />
                        <Route path="/settings/role/:id" element={<Role />} />
                        <Route path="/settings/admins" element={<Admins />} />
                        <Route path="/settings/admin" element={<Admin />} />
                        <Route path="/settings/admin/:id" element={<Admin />} /> */}
                    </>
                }
                <Route path="settings" element={<SettingDashboard />} />
                <Route path="profile" element={<Profile />} />
                <Route path="*" element={<Dashboard />} />
            </Routes>
        </Layout>;
    }
}

export default MemberSection;