import TripRegionService from "../services/entity.trip.region.service";

// Action to get entity by id
export const readTripRegion = (entityId, id) => (dispatch) => {
    return TripRegionService.readTripRegion(entityId, id).then((data) => {
        console.log(data);
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to get all trip regions
export const readTripRegions = (entityId) => (dispatch) => {
    return TripRegionService.readTripRegions(entityId).then((data) => {
        // if (data.result) {
        //     dispatch({
        //         type: READ_ENTITY_REGIONS_SUCCESS,
        //         payload: data.result
        //     });
        // }
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to create the trip region
export const createTripRegion = (entityId, tripRegion) => (dispatch) => {
    return TripRegionService.createTripRegion(entityId, tripRegion).then((data) => {
        console.log(data);
        return Promise.resolve(data);
    }, (error) => {
        console.log(error.message);
        return Promise.reject(error);
    });
}

// Action to update the trip region
export const updateTripRegion = (entityId, id, tripRegion) => (dispatch) => {
    return TripRegionService.updateTripRegion(entityId, id, tripRegion).then((data) => {
        console.log(data);
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to delete the trip region
export const deleteTripRegion = (entityId, id) => (dispatch) => {
    return TripRegionService.deleteTripRegion(entityId, id).then((data) => {
        // dispatch({
        //     type: DELETE_ENTITY_REGION_SUCCESS,
        //     payload: id
        // });
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}