import ArticleTagService from "../services/entity.article.tag.service";

// Action to get all active entity article tag translations by entity id
export const readAllActiveArticleTagsTranslations = (entityId) => (dispatch) => {
    return ArticleTagService.readAllActiveArticleTagsTranslations(entityId).then((data) => {
        console.log(data);
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to get entity by id
export const readArticleTag = (entityId, id) => (dispatch) => {
    return ArticleTagService.readArticleTag(entityId, id).then((data) => {
        console.log(data);
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to get all entity article tags
export const readArticleTags = (entityId) => (dispatch) => {
    return ArticleTagService.readArticleTags(entityId).then((data) => {
        // if (data.result) {
        //     dispatch({
        //         type: READ_ENTITY_REGIONS_SUCCESS,
        //         payload: data.result
        //     });
        // }
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to create the entity article tag
export const createArticleTag = (entityId, articleTag) => (dispatch) => {
    return ArticleTagService.createArticleTag(entityId, articleTag).then((data) => {
        console.log(data);
        return Promise.resolve(data);
    }, (error) => {
        console.log(error.message);
        return Promise.reject(error);
    });
}

// Action to update the entity article tag
export const updateArticleTag = (entityId, id, articleTag) => (dispatch) => {
    return ArticleTagService.updateArticleTag(entityId, id, articleTag).then((data) => {
        console.log(data);
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to delete the entity article tag
export const deleteArticleTag = (entityId, id) => (dispatch) => {
    return ArticleTagService.deleteArticleTag(entityId, id).then((data) => {
        // dispatch({
        //     type: DELETE_ENTITY_REGION_SUCCESS,
        //     payload: id
        // });
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}