import TripCollectionService from "../services/entity.trip.collection.service";

// Action to get entity by id
export const readTripCollection = (entityId, id) => (dispatch) => {
    return TripCollectionService.readTripCollection(entityId, id).then((data) => {
        console.log(data);
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to get all entity collections
export const readTripCollections = (entityId) => (dispatch) => {
    return TripCollectionService.readTripCollections(entityId).then((data) => {
        // if (data.result) {
        //     dispatch({
        //         type: READ_ENTITY_REGIONS_SUCCESS,
        //         payload: data.result
        //     });
        // }
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to create the entity collection
export const createTripCollection = (entityId, tripCollection) => (dispatch) => {
    return TripCollectionService.createTripCollection(entityId, tripCollection).then((data) => {
        console.log(data);
        return Promise.resolve(data);
    }, (error) => {
        console.log(error.message);
        return Promise.reject(error);
    });
}

// Action to update the entity collection
export const updateTripCollection = (entityId, id, tripCollection) => (dispatch) => {
    return TripCollectionService.updateTripCollection(entityId, id, tripCollection).then((data) => {
        console.log(data);
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to delete the entity collection
export const deleteTripCollection = (entityId, id) => (dispatch) => {
    return TripCollectionService.deleteTripCollection(entityId, id).then((data) => {
        // dispatch({
        //     type: DELETE_ENTITY_REGION_SUCCESS,
        //     payload: id
        // });
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}