import authHeader from './auth-header';
import axios from "axios";
import { API_ENTITY_TRIP_REGION_URL } from "./config";

// API to get trip region by id
const readTripRegion = (entityId, id) => {
    return axios.get(API_ENTITY_TRIP_REGION_URL(entityId) + id, {
        headers: authHeader()
    }).then(res => res.data);
}

// API to get all trip regions
const readTripRegions = (entityId) => {
    return axios.get(API_ENTITY_TRIP_REGION_URL(entityId), {
        headers: authHeader()
    }).then(res => res.data);
}


// API to create trip region
const createTripRegion = (entityId, tripRegion) => {
    return axios
        .post(API_ENTITY_TRIP_REGION_URL(entityId), tripRegion, {
            headers: authHeader()
        }).then((response) => {
            return response.data;
        });
};

// API to update trip region
const updateTripRegion = (entityId, id, tripRegion) => {
    return axios
        .put(API_ENTITY_TRIP_REGION_URL(entityId) + id, tripRegion, {
            headers: authHeader()
        }).then((response) => {
            return response.data;
        });
};

// API to delete trip region
const deleteTripRegion = (entityId, id) => {
    return axios
        .delete(API_ENTITY_TRIP_REGION_URL(entityId) + id, {
            headers: authHeader()
        }).then((response) => {
            return response.data;
        });
};

const tripRegions = {
    createTripRegion,
    readTripRegion,
    readTripRegions,
    updateTripRegion,
    deleteTripRegion
};

export default tripRegions;