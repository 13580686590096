import UserMediaService from "../services/user-media.service";

// Action to get all user-media
export const readUserMedia = (entityId, params) => (dispatch) => {
    return UserMediaService.readUserMedia(entityId, params).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}


// Action to count all user-media
export const countUserMedia = (entityId, params) => (dispatch) => {
    return UserMediaService.countUserMedia(entityId, params).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}