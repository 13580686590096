import CommentService from "../services/entity.comment.service";

// Action to get entity by id
export const readComment = (entityId, discussionId, id) => (dispatch) => {
    return CommentService.readComment(entityId, discussionId, id).then((data) => {
        console.log(data);
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to get all comments
export const readComments = (entityId, discussionId) => (dispatch) => {
    return CommentService.readComments(entityId, discussionId).then((data) => {
        // if (data.result) {
        //     dispatch({
        //         type: READ_ENTITY_REGIONS_SUCCESS,
        //         payload: data.result
        //     });
        // }
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to create the comment
export const createComment = (entityId, discussionId, entity) => (dispatch) => {
    return CommentService.createComment(entityId, discussionId, entity).then((data) => {
        console.log(data);
        return Promise.resolve(data);
    }, (error) => {
        console.log(error.message);
        return Promise.reject(error);
    });
}

// Action to update the comment
export const updateComment = (entityId, discussionId, id, comment) => (dispatch) => {
    return CommentService.updateComment(entityId, discussionId, id, comment).then((data) => {
        console.log(data);
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to delete the comment
export const deleteComment = (entityId, discussionId, id) => (dispatch) => {
    return CommentService.deleteComment(entityId, discussionId, id).then((data) => {
        // dispatch({
        //     type: DELETE_ENTITY_REGION_SUCCESS,
        //     payload: id
        // });
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to hide the comment
export const hideComment = (entityId, discussionId, id) => (dispatch) => {
    return CommentService.hideComment(entityId, discussionId, id).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to unhide the comment
export const unhideComment = (entityId, discussionId, id) => (dispatch) => {
    return CommentService.unhideComment(entityId, discussionId, id).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to approve the comment
export const approveComment = (entityId, discussionId, id) => (dispatch) => {
    return CommentService.approveComment(entityId, discussionId, id).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to disapprove the comment
export const disapproveComment = (entityId, discussionId, id) => (dispatch) => {
    return CommentService.disapproveComment(entityId, discussionId, id).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}