import React, { useEffect, useState } from 'react';
import { Layout, message, Button, Form, Input, Switch, Tabs, Select, Drawer } from 'antd';
import { MinusCircleOutlined, PlusOutlined, UpCircleOutlined, DownCircleOutlined } from '@ant-design/icons';
import { PageHeader } from '@ant-design/pro-components';
import { useNavigate, useParams, Link } from 'react-router-dom';
import MemberSidebar from './components/member.sidebar.component';
import MemberFooter from './components/member.footer.component';
import { useDispatch } from 'react-redux';
import { createEntity, readEntities, readEntity, updateEntity } from '../actions/entity';
import { useTranslation } from 'react-i18next';
import MediaSelect from './components/mediaSelect.field';
import dot from 'dot-object';
import { API_CDN_URL } from '../services/config';
import EntityMedia from './entity.media.page';

const { TabPane } = Tabs;
const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 8,
    },
};
const supportedLanguages = [
    { id: 'en-US', name: 'English ( en-US )' },
    { id: 'zh-HK', name: 'Traditional Chinese ( zh-HK )' },
];
const { Option } = Select;
const defaultRobotsTxtContent = 'User-agent: *\nDisallow: /';

function Entity() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const params = useParams();
    const { Content } = Layout;
    const [form] = Form.useForm();
    const [enabledCart, setEnabledCart] = useState(true);
    const [mediaDrawerVisible, setMediaDrawerVisible] = useState(false);
    const [enabledCheckout, setEnabledCheckout] = useState(false);
    const [currentMediaFieldName, setCurrentMediaFieldName] = useState(null);
    const [dataSource, setDataSource] = useState([]);

    const [entityTitle, setEntityTitle] = useState('');

    const showMediaDrawer = (fieldName) => {
        setCurrentMediaFieldName(fieldName);
        setMediaDrawerVisible(true);
    };
    const onMediaDrawerClose = () => {
        setMediaDrawerVisible(false);
    };

    const onSelectMedia = (media) => {
        setMediaDrawerVisible(false);
        if (currentMediaFieldName && dataSource) {
            console.log(currentMediaFieldName);
            let url = API_CDN_URL(dataSource) + media.path;
            let newValue = {
                [currentMediaFieldName.join('.')]: url
            };
            let formValues = form.getFieldsValue();
            // Use dot to update partial values
            newValue = dot.dot(newValue);
            formValues = dot.dot(formValues);
            newValue = { ...formValues, ...newValue };
            newValue = dot.object(newValue);
            form.setFieldsValue(newValue);
        }
    }


    useEffect(() => {
        if (typeof params.id !== 'undefined') {
            setEntityTitle('Edit Entity');
            dispatch(readEntity(params.id)).then((response) => {
                if (typeof response.result !== 'undefined' && typeof response.result.id !== 'undefined') {
                    setEntityTitle('Edit Entity - ' + response.result.name);
                    form.setFieldsValue(response.result);
                    setDataSource(response.result);
                    setEnabledCart(response.result.enabledCart);
                    setEnabledCheckout(response.result.enabledCheckout);
                } else {
                    message.error(t('entity.message.read-error'));
                    navigate('/entities');
                }
            }).catch(e => {
                message.error(t('entity.message.read-error'));
                navigate('/entities');
            });
        } else {
            setEntityTitle(t('entity.title.new-entity'));
        }
    }, [params.id, dispatch, navigate, form]);

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
        if (typeof errorInfo.errorFields !== 'undefined') {
            errorInfo.errorFields.forEach((error) => {
                message.error(error.errors[0]);
            });
        }
    };

    const onFinish = (values) => {
        if (typeof values.website !== 'undefined') {
            if (typeof values.website.needKeyIsVerified === 'undefined') {
                values.website.needKeyIsVerified = false;
            }
            if (typeof values.website.needSendInvitation === 'undefined') {
                values.website.needSendInvitation = false;
            }
        }
        if (typeof values.path !== 'undefined') {
            if (values.path === null) {
                values.path = '';
            } else {
                values.path = values.path.replace(/^\/|\/$/g, '');
            }
        } else {
            values.path = '';
        }
        if (typeof values.languages !== 'undefined' && Array.isArray(values.languages)) {
            values.languages = values.languages.map(language => {
                console.log(language);
                if (typeof language.status === 'undefined') {
                    language.status = false;
                }
                return language;
            });
        }
        if (typeof params.id !== 'undefined') {
            // Update the existing entity
            dispatch(updateEntity(params.id, values)).then((response) => {
                if (response.result) {
                    setEntityTitle('Edit Entity - ' + response.result.name);
                    form.setFieldsValue(response.result);
                    setDataSource(response.result);
                    setEnabledCart(response.result.enabledCart);
                    setEnabledCheckout(response.result.enabledCheckout);
                    dispatch(readEntities());
                }
                message.success(t('entity.message.updated-successfully'));
            }).catch(error => {
                try {
                    let responseMessage = error.response.data.message;
                    console.log(responseMessage);
                    message.error(responseMessage);
                } catch (e) {
                    console.log('Failed:', e);
                    message.error(JSON.stringify(e));
                }
            });

        } else {
            // Create new entity
            dispatch(createEntity(values)).then((data) => {
                console.log(data.result);
                message.success(t('entity.message.created-successfully'));
                if (data.result) {
                    navigate('/entity/' + data.result.id);
                } else {
                    navigate('/entities');
                }
            }).catch(error => {
                try {
                    let responseMessage = error.response.data.message;
                    console.log(responseMessage);
                    message.error(responseMessage);
                } catch (e) {
                    console.log('Failed:', e);
                    message.error(JSON.stringify(e));
                }
            });
        }
    };

    return <>
        <MemberSidebar />
        <Layout className="site-layout">
            <Content style={{ margin: '0 16px' }}>
                <div className="site-layout-background">
                    <Form {...layout} form={form} name="entity" onFinish={onFinish} onFinishFailed={onFinishFailed} initialValues={{
                        description: '',
                        status: true,
                        protocol: 'https',
                        cdnPath: '',
                        cdnBucketPath: '',
                        cdnUploadPath: '',
                        currency: {
                            code: 'HKD',
                            name: 'Hong Kong Dollar',
                            symbol: 'HK$',
                        },
                        enabledCart: true,
                        enabledCheckout: true,
                        website: {
                            robotsTxtContent: defaultRobotsTxtContent,
                            enabledRegistration: true,
                            enabledLogin: true
                        },
                        requiredArticleSupportAllLanguages: false,
                        requiredPageSupportAllLanguages: false,
                        requiredTripSupportAllLanguages: false
                    }}>
                        <PageHeader
                            className="site-page-header"
                            onBack={() => navigate('/entities')}
                            title={entityTitle}
                            extra={[
                                <Button key="cancel" type="primary" danger href={'/entities'}>{t('entity.actions.cancel')}</Button>,
                                <Button key="tracking" type="primary"><Link to={`/entity/${params.id}/tracking-dimensions`}>{t('entity.actions.tracking')}</Link></Button>,
                                <Button key="save" type="primary" htmlType="submit">{params.id ? t('entity.actions.update') : t('entity.actions.save')}</Button>
                            ]}
                        />

                        <Tabs defaultActiveKey="basic" size="large">
                            <TabPane tab={t('entity.tabs.basic')} key="basic" forceRender>
                                <Form.Item
                                    name="name"
                                    label={t('entity.field.name.label')}
                                    rules={[{ required: true, message: t('entity.field.name.required') }]}
                                >
                                    <Input placeholder={t('entity.field.name.placeholder')} />
                                </Form.Item>
                                <Form.Item
                                    name="slug"
                                    label={t('entity.field.slug.label')}
                                    rules={[
                                        { required: true, message: t('entity.field.slug.required') },
                                        { pattern: /^[a-zA-Z0-9]+$/, message: t('entity.field.slug.required-pattern') }
                                    ]}
                                >
                                    <Input placeholder={t('entity.field.slug.placeholder')} disabled={params.id ? true : false} />
                                </Form.Item>
                                <Form.Item
                                    label={t('entity.field.description.label')}
                                >
                                    <Form.Item
                                        name={['description']}
                                        noStyle>
                                        <Input.TextArea autoSize={{ minRows: 5, maxRows: 10 }} />
                                    </Form.Item>
                                </Form.Item>
                                <Form.Item name="status" label={t('entity.field.status.label')} valuePropName="checked">
                                    <Switch />
                                </Form.Item>
                                <Form.Item
                                    name="protocol"
                                    label={t('entity.field.protocol.label')}
                                    rules={[
                                        { required: true, message: 'Please select entity protocol!' }
                                    ]}
                                >
                                    <Select
                                        style={{ width: '100%' }}
                                        placeholder="Please select"
                                    >
                                        <Option value="http">HTTP</Option>
                                        <Option value="https">HTTPS</Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    name="host"
                                    label={t('entity.field.host.label')}
                                    rules={[
                                        { required: true, message: t('entity.field.host.required') },
                                        {
                                            pattern: /^[a-zA-Z0-9-_.:]+$/,
                                            message: t('entity.field.host.required-pattern'),
                                        }
                                    ]}
                                >
                                    <Input placeholder={t('entity.field.host.placeholder')} />
                                </Form.Item>
                                <Form.Item label={t('entity.field.path.label')}>
                                    <Form.Item
                                        name="path"
                                        noStyle
                                        rules={
                                            [{
                                                pattern: /^[a-z0-9-_]+$/,
                                                message: t('entity.field.path.required-pattern'),
                                            }]
                                        }
                                    >
                                        <Input placeholder={t('entity.field.path.placeholder')} />
                                    </Form.Item>
                                    <span>{t('entity.field.path.instruction')}</span>
                                </Form.Item>
                                <Form.Item
                                    label={t('entity.field.cdnBucketPath.label')}>
                                    <Form.Item name="cdnBucketPath" noStyle>
                                        <Input placeholder={t('entity.field.cdnBucketPath.placeholder')} />
                                    </Form.Item>
                                    <div>{t('entity.field.cdnBucketPath.instruction1')}</div>
                                    <div>{t('entity.field.cdnBucketPath.instruction2')}</div>
                                </Form.Item>
                                <Form.Item
                                    label={t('entity.field.cdnpath.label')}>
                                    <Form.Item name="cdnPath" noStyle>
                                        <Input placeholder={t('entity.field.cdnpath.placeholder')} />
                                    </Form.Item>
                                    <div>{t('entity.field.cdnpath.instruction1')}</div>
                                    <div>{t('entity.field.cdnpath.instruction2')}</div>
                                </Form.Item>
                                <Form.Item
                                    label={t('entity.field.cdnuploadpath.label')}>
                                    <Form.Item name="cdnUploadPath" noStyle>
                                        <Input placeholder={t('entity.field.cdnuploadpath.placeholder')} />
                                    </Form.Item>
                                    <div>{t('entity.field.cdnuploadpath.instruction1')}</div>
                                    <div>{t('entity.field.cdnuploadpath.instruction2')}</div>
                                </Form.Item>
                                <Form.Item
                                    label={t('entity.field.currencyname.label')}
                                    name={["currency", "name"]}
                                    rules={[{ required: true, message: t('entity.field.currencyname.required') }]}
                                >
                                    <Input placeholder={t('entity.field.currencyname.placeholder')} />
                                </Form.Item>
                                <Form.Item
                                    label={t('entity.field.currencycode.label')}
                                    required={true}
                                >
                                    <Form.Item
                                        name={["currency", "code"]}
                                        rules={[{ required: true, message: t('entity.field.currencycode.required') }]}
                                        noStyle>
                                        <Input placeholder={t('entity.field.currencycode.placeholder')} />
                                    </Form.Item>
                                </Form.Item>
                                <Form.Item
                                    required={true}
                                    label={t('entity.field.currencysymbol.label')}
                                    name={["currency", "symbol"]}
                                    rules={[{ required: true, message: t('entity.field.currencysymbol.required') }]}
                                >
                                    <Input placeholder={t('entity.field.currencysymbol.placeholder')} />
                                </Form.Item>

                                <Form.Item name="enabledCart" label={t('entity.field.enabledcart.label')} valuePropName="checked">
                                    <Switch onChange={(value) => { setEnabledCart(value) }} />
                                </Form.Item>
                                <Form.Item name="enabledCheckout" label={t('entity.field.enabledcart.label')} valuePropName="checked">
                                    <Switch onChange={(value) => { setEnabledCheckout(value) }} />
                                </Form.Item>
                                {/* <Form.Item name="languages" label="Languages" rules={[{ required: true, message: 'Please select entity languages!' }]}>
                                    <Select
                                        style={{ width: '100%' }}
                                        placeholder="Please select"
                                    >
                                        {
                                            supportedLanguages.map(language => {
                                                return <Option key={language.id} value={language.id}>{language.name}</Option>
                                            })
                                        }
                                    </Select>
                                </Form.Item> */}
                            </TabPane>
                            {false && enabledCheckout && <TabPane tab={t('entity.tabs.translation')} key="translation" forceRender>

                            </TabPane>}
                            <TabPane tab={t('entity.tabs.languages')} key="languages" forceRender>
                                <Form.Item name={['requiredArticleSupportAllLanguages']} label={t('entity.field.requiredArticleSupportAllLanguages.label')} valuePropName="checked">
                                    <Switch />
                                </Form.Item>
                                <Form.Item name={['requiredTripSupportAllLanguages']} label={t('entity.field.requiredTripSupportAllLanguages.label')} valuePropName="checked">
                                    <Switch />
                                </Form.Item>
                                <Form.Item name={['requiredPageSupportAllLanguages']} label={t('entity.field.requiredPageSupportAllLanguages.label')} valuePropName="checked">
                                    <Switch />
                                </Form.Item>
                                <Form.List name="languages" style={{ width: '100%' }}>
                                    {(fields, { add, remove, move }) => (
                                        <>
                                            <div style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                                <div style={{ width: 'calc(20% - 30px)', marginRight: 10 }}><strong>Name</strong></div>
                                                <div style={{ width: 'calc(20% - 30px)', marginRight: 10 }}><strong>Code</strong></div>
                                                <div style={{ width: 'calc(20% - 30px)', marginRight: 10 }}><strong>Locale</strong></div>
                                                <div style={{ width: 'calc(20% - 30px)', marginRight: 10 }}><strong>Status</strong></div>
                                                <div style={{ width: 'calc(20% - 30px)', marginRight: 10 }}><strong>Default</strong></div>
                                            </div>
                                            {fields.map(({ key, name, ...restField }, fieldPosition) => {
                                                return <div key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                                    <Form.Item
                                                        {...restField}
                                                        style={{ width: 'calc(20% - 20px)', marginRight: 10 }}
                                                        wrapperCol={{ span: 24 }}
                                                        name={[name, 'name']}
                                                        rules={[{ required: true, message: 'Missing name' }]}
                                                    >
                                                        <Input placeholder="Name" />
                                                    </Form.Item>
                                                    <Form.Item
                                                        {...restField}
                                                        style={{ width: 'calc(20% - 20px)', marginRight: 10 }}
                                                        wrapperCol={{ span: 24 }}
                                                        name={[name, 'code']}
                                                        rules={[
                                                            { required: true, message: 'Missing code' },
                                                            { pattern: /^[a-z0-9-_]+$/, message: 'code must be lowercase alphanumeric with dashes / underscore' }
                                                        ]}
                                                    >
                                                        <Input placeholder="Code" />
                                                    </Form.Item>
                                                    <Form.Item
                                                        {...restField}
                                                        style={{ width: 'calc(20% - 20px)', marginRight: 10 }}
                                                        wrapperCol={{ span: 24 }}
                                                        name={[name, 'locale']}
                                                        rules={[
                                                            { required: true, message: 'Missing locale' },
                                                            { pattern: /^[a-zA-Z0-9-]+$/, message: 'locale must be alphanumeric with dashes' }
                                                        ]}
                                                    >
                                                        <Input placeholder="Locale" />
                                                    </Form.Item>
                                                    <Form.Item
                                                        {...restField}
                                                        style={{ width: 'calc(20% - 20px)', marginRight: 10 }}
                                                        wrapperCol={{ span: 24 }}
                                                        name={[name, 'status']}
                                                        valuePropName="checked"
                                                    >
                                                        <Switch />
                                                    </Form.Item>
                                                    <Form.Item
                                                        {...restField}
                                                        style={{ width: 'calc(20% - 20px)', marginRight: 10 }}
                                                        wrapperCol={{ span: 24 }}
                                                        name={[name, 'isDefault']}
                                                        valuePropName="checked"
                                                    >
                                                        <Switch />
                                                    </Form.Item>
                                                    <div style={{ width: 100 }}>
                                                        {(fields.length > 1 && fieldPosition !== 0) && <UpCircleOutlined onClick={() => move(fieldPosition, fieldPosition - 1)} style={{ marginLeft: 8 }} />}
                                                        {(fields.length > 1 && fieldPosition !== (fields.length - 1)) && <DownCircleOutlined onClick={() => move(fieldPosition, fieldPosition + 1)} style={{ marginLeft: 8 }} />}
                                                        <MinusCircleOutlined onClick={() => remove(name)} style={{ marginLeft: 8 }} />
                                                    </div>
                                                </div>
                                            })}
                                            <Form.Item wrapperCol={{ span: 24 }}>
                                                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                                    {t('entity.field.languages.add')}
                                                </Button>
                                            </Form.Item>
                                        </>
                                    )}
                                </Form.List>
                            </TabPane>
                            <TabPane tab={t('entity.tabs.seo')} key="seo" forceRender>
                                <Form.Item
                                    required={true}
                                    label={t('entity.field.robotsTxtContent.label')}
                                    rules={[{ required: true, message: t('entity.field.robotsTxtContent.required') }]}
                                >
                                    <Form.Item
                                        name={['website', 'robotsTxtContent']}
                                        noStyle>
                                        <Input.TextArea autoSize={{ minRows: 5, maxRows: 10 }} />
                                    </Form.Item>
                                    <div>{t('entity.field.robotsTxtContent.instruction')}</div>
                                </Form.Item>
                                <Form.Item
                                    label={t('entity.field.seoTitle.label')}
                                    name={['seo', 'title']}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label={t('entity.field.seoDescription.label')}
                                    name={['seo', 'description']}
                                >
                                    <Input />
                                </Form.Item>
                                <MediaSelect showMediaDrawer={showMediaDrawer} form={form} wrapper={{
                                    wrapperCol: 16,
                                    label: t('entity.field.seoImage.label')
                                }} inner={{
                                    name: ['seo', 'image'],
                                }} />
                            </TabPane>
                            <TabPane tab={t('entity.tabs.email')} key="email" forceRender>
                                <Form.Item
                                    required={true}
                                    name={['email', 'emailDomain']}
                                    label={t('entity.field.emailDomain.label')}
                                    rules={[{ required: true, message: t('entity.field.emailDomain.required') }]}
                                >
                                    <Input placeholder={t('entity.field.emailDomain.placeholder')} />
                                </Form.Item>
                                <Form.Item
                                    required={true}
                                    name={['email', 'emailFromAddress']}
                                    label={t('entity.field.emailFromAddress.label')}
                                    rules={[{ required: true, message: t('entity.field.emailFromAddress.required') }]}
                                >
                                    <Input />
                                </Form.Item>
                            </TabPane>
                            <TabPane tab={t('entity.tabs.website')} key="website" forceRender>
                                <h1>General</h1>
                                <Form.Item name={['website', 'enabledRegistration']} label={t('entity.field.enabledRegistration.label')} valuePropName="checked">
                                    <Switch />
                                </Form.Item>
                                <Form.Item name={['website', 'enabledLogin']} label={t('entity.field.enabledLogin.label')} valuePropName="checked">
                                    <Switch />
                                </Form.Item>
                                <h1>Emails Settings</h1>
                                <Form.Item name={['website', 'needKeyIsVerified']} label={t('entity.field.needKeyIsVerified.label')} valuePropName="checked">
                                    <Switch />
                                </Form.Item>
                                <Form.Item name={['website', 'needSendInvitation']} label={t('entity.field.needSendInvitation.label')} valuePropName="checked">
                                    <Switch />
                                </Form.Item>
                                <Form.Item
                                    name={['website', 'welcomeEmailFromAddress']}
                                    label={t('entity.field.welcomeEmailFromAddress.label')}
                                    rules={[
                                        { required: true, message: t('entity.field.welcomeEmailFromAddress.required') },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    name={['website', 'forgetPasswordEmailFromAddress']}
                                    label={t('entity.field.forgetPasswordEmailFromAddress.label')}
                                    rules={[
                                        { required: true, message: t('entity.field.forgetPasswordEmailFromAddress.required') },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                                <h1>Scripts</h1>
                                <Form.Item
                                    label={t('entity.field.globalHeadScripts.label')}
                                >
                                    <Form.Item name={['website', 'globalHeadScripts']} noStyle>
                                        <Input.TextArea autoSize={{ minRows: 5, maxRows: 10 }} />
                                    </Form.Item>
                                </Form.Item>
                                <Form.Item
                                    label={t('entity.field.globalAfterBodyStartScripts.label')}
                                >
                                    <Form.Item name={['website', 'globalAfterBodyStartScripts']} noStyle>
                                        <Input.TextArea autoSize={{ minRows: 5, maxRows: 10 }} />
                                    </Form.Item>
                                </Form.Item>
                                <Form.Item
                                    label={t('entity.field.globalBeforeBodyEndScripts.label')}
                                >
                                    <Form.Item name={['website', 'globalBeforeBodyEndScripts']} noStyle>
                                        <Input.TextArea autoSize={{ minRows: 5, maxRows: 10 }} />
                                    </Form.Item>
                                </Form.Item>
                                <div><strong>{t('entity.website.note.title')}</strong></div>
                                <div>{t('entity.website.note.instruction1')}</div>
                                <div>{t('entity.website.note.instruction2')}</div>
                                <div>{t('entity.website.note.instruction3')}</div>
                            </TabPane>
                        </Tabs>
                    </Form>
                    <Drawer contentWrapperStyle={{ width: '1000px', maxWidth: '100%', minWidth: '50%' }} title={t('mediaDrawer.title')} placement="right" onClose={onMediaDrawerClose} open={mediaDrawerVisible}>
                        <EntityMedia entityId={params.id} selectMedia={onSelectMedia} />
                    </Drawer>
                </div>
            </Content>
            <MemberFooter />
        </Layout>
    </>;
}

export default Entity;