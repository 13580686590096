import React, { useEffect, useState } from 'react';
import { Layout, Popconfirm, message, Button, Table, Space } from 'antd';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import MemberSidebar from './components/member.sidebar.component';
import MemberFooter from './components/member.footer.component';
import { useDispatch } from 'react-redux';
import {
    readComments,
    deleteComment,
    hideComment,
    unhideComment,
    approveComment,
    disapproveComment,
} from '../actions/entity.comment.js';
import { PageHeader } from '@ant-design/pro-components';
import { useTranslation } from 'react-i18next';
import { readDiscussion, countComments } from '../actions/entity.discussion';

function Comments(props) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const params = useParams();
    const { Content } = Layout;
    const [discussion, setDiscussion] = useState(null);
    const [dataSource, setDataSource] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const [dataSourceCount, setDataSourceCount] = useState(0);
    const defaultPerPageState = searchParams.get('perPage') ? parseInt(searchParams.get('perPage')) : 40;
    const [perPage, setPerPage] = useState(defaultPerPageState);
    const defaultPageState = searchParams.get('page') ? parseInt(searchParams.get('page')) : 1;
    const [page, setPage] = useState(defaultPageState);

    const hideCommentAction = (id) => {
        dispatch(hideComment(params.entityId, params.discussionId, id)).then((response) => {
            message.success(t('entities.message.hide-success'));
            setDataSource(dataSource.map(item => {
                if (item.id === id) {
                    item.hidden = true;
                }
                return item;
            }));
        }).catch(e => {
            console.log(e);
            message.error(t('entities.message.hide-failed'));
        });
    }

    const unhideCommentAction = (id) => {
        dispatch(unhideComment(params.entityId, params.discussionId, id)).then((response) => {
            message.success(t('entities.message.unhide-success'));
            setDataSource(dataSource.map(item => {
                if (item.id === id) {
                    item.hidden = false;
                }
                return item;
            }));
        }).catch(e => {
            console.log(e);
            message.error(t('entities.message.unhide-failed'));
        });
    }

    const approveCommentAction = (id) => {
        dispatch(approveComment(params.entityId, params.discussionId, id)).then((response) => {
            message.success(t('entities.message.approve-success'));
            setDataSource(dataSource.map(item => {
                if (item.id === id) {
                    item.approved = true;
                }
                return item;
            }));
        }).catch(e => {
            console.log(e);
            message.error(t('entities.message.approve-failed'));
        });
    }

    const disapproveCommentAction = (id) => {
        dispatch(disapproveComment(params.entityId, params.discussionId, id)).then((response) => {
            message.success(t('entities.message.unapprove-success'));
            setDataSource(dataSource.map(item => {
                if (item.id === id) {
                    item.approved = false;
                }
                return item;
            }));
        }).catch(e => {
            console.log(e);
            message.error(t('entities.message.unapprove-failed'));
        });
    }


    const deleteCommentAction = (id) => {
        dispatch(deleteComment(params.entityId, params.discussionId, id)).then((response) => {
            message.success(t('entities.message.delete-success'));
            setDataSource(dataSource.filter(item => item.id !== id));
        }).catch(e => {
            console.log(e);
            message.error(t('entities.message.delete-failed'));
        });
    }

    const columns = [
        {
            title: t('comments.columns.rating'),
            dataIndex: 'rating',
            key: 'rating',
        },
        {
            title: t('comments.columns.comment'),
            dataIndex: 'comment',
            key: 'comment',
        },
        {
            title: t('comments.columns.person'),
            dataIndex: 'person',
            key: 'person',
            render: (text, record) => {
                if (record.personModel === 'User') {
                    return <Link to={`/entity/${params.entityId}/user/${record.person._id}`}>{record.person.lastName}, {record.person.firstName} &lt;{record.person._id}&gt;</Link>;
                } else {
                    return <>Admin &lt;{record.person._id}&gt;</>
                }
            }
        },
        {
            title: t('comments.columns.hidden'),
            dataIndex: 'hidden',
            key: 'hidden',
            render: (text, record) => {
                if (record.hidden) return t('comments.columns.hidden-yes');
                else return t('comments.columns.hidden-no');
            }
        },
        {
            title: t('comments.columns.approved'),
            dataIndex: 'approved',
            key: 'approved',
            render: (text, record) => {
                if (record.approved) return t('comments.columns.approved-yes');
                else return t('comments.columns.approved-no');
            }
        },
        {
            title: t('comments.columns.created'),
            dataIndex: 'createdAt',
            key: 'createdAt'
        },
        {
            title: t('comments.columns.actions'),
            dataIndex: 'actions',
            key: 'actions',
            render: (text, record) => <Space>
                <Button type="primary" href={`/entity/${params.entityId}/discussion/${params.discussionId}/comment/${record.id}`}>{t('comments.actions.edit')}</Button>
                {record.hidden ? <Popconfirm
                    title={t('comments.actions.unhide-confirm')}
                    onConfirm={() => unhideCommentAction(record.id)}
                    okText={t('comments.actions.unhide-ok')}
                    cancelText={t('comments.actions.unhide-cancel')}
                >
                    <Button type="primary">{t('comments.actions.unhide')}</Button>
                </Popconfirm> :
                    <Popconfirm
                        title={t('comments.actions.hide-confirm')}
                        onConfirm={() => hideCommentAction(record.id)}
                        okText={t('comments.actions.hide-ok')}
                        cancelText={t('comments.actions.hide-cancel')}
                    >
                        <Button type="primary">{t('comments.actions.hide')}</Button>
                    </Popconfirm>
                }
                {
                    record.approved ? <Popconfirm
                        title={t('comments.actions.unapprove-confirm')}
                        onConfirm={() => disapproveCommentAction(record.id)}
                        okText={t('comments.actions.unapprove-ok')}
                        cancelText={t('comments.actions.unapprove-cancel')}
                    >
                        <Button type="primary">{t('comments.actions.unapprove')}</Button>
                    </Popconfirm> : <Popconfirm
                        title={t('comments.actions.approve-confirm')}
                        onConfirm={() => approveCommentAction(record.id)}
                        okText={t('comments.actions.approve-ok')}
                        cancelText={t('comments.actions.approve-cancel')}
                    >
                        <Button type="primary">{t('comments.actions.approve')}</Button>
                    </Popconfirm>
                }
                <Popconfirm
                    title={t('comments.actions.delete-confirm')}
                    onConfirm={() => deleteCommentAction(record.id)}
                    okText={t('comments.actions.delete-ok')}
                    cancelText={t('comments.actions.delete-cancel')}
                >
                    <Button type="primary" danger>{t('comments.actions.delete')}</Button>
                </Popconfirm>
            </Space>

        }
    ];

    useEffect(() => {
        dispatch(countComments(params.entityId, params.discussionId)).then((response) => {
            if (typeof response.result !== 'undefined') {
                setDataSourceCount(response.result);
            }
        }).catch((error) => {
            try {
                setDataSourceCount(0);
                let responseMessage = error.response.data.message;
                console.log(responseMessage);
            } catch (e) {
                console.log('Failed:', e);
            }
        });
        dispatch(readDiscussion(params.entityId, params.discussionId, { page, perPage })).then((response) => {
            if (typeof response.result !== 'undefined') {
                setDiscussion(response.result);
            } else {
                setDiscussion(null);
            }
        }).catch((error) => {
            try {
                setDiscussion(null);
                let responseMessage = error.response.data.message;
                console.log(responseMessage);
                message.error(responseMessage);
            } catch (e) {
                console.log('Failed:', e);
                message.error(JSON.stringify(e));
            }
        });
        dispatch(readComments(params.entityId, params.discussionId)).then((response) => {
            if (typeof response.result !== 'undefined') {
                console.log(response.result);
                setDataSource(response.result);
            } else {
                setDataSource([]);
            }
        }).catch((error) => {
            try {
                setDataSource([]);
                let responseMessage = error.response.data.message;
                console.log(responseMessage);
                message.error(responseMessage);
            } catch (e) {
                console.log('Failed:', e);
                message.error(JSON.stringify(e));
            }
        });
    }, [dispatch, params.entityId, params.discussionId, perPage, page, setSearchParams]);

    useEffect(() => {
        setSearchParams({ page, perPage });
    }, [perPage, page, setSearchParams]);

    return <>
        <MemberSidebar />
        <Layout className="site-layout">
            <Content style={{ margin: '0 16px' }}>
                <div className="site-layout-background">
                    <PageHeader
                        className="site-page-header"
                        onBack={() => navigate(`/entity/${params.entityId}/discussion/${params.discussionId}`)}
                        title={`${t('comments.title')} - ${discussion ? discussion.name : ''}`}
                        subTitle={t('comments.subtitle')}
                        extra={[
                            <Button key="create" type="primary" href={`/entity/${params.entityId}/discussion/${params.discussionId}/comment`}>{t('comments.actions.new')}</Button>
                        ]}
                    />
                    <Table
                        rowKey={record => record.id}
                        dataSource={dataSource}
                        columns={columns}
                        pagination={{
                            position: ['topRight', 'bottomRight'],
                            total: dataSourceCount,
                            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                            showSizeChanger: true,
                            pageSize: perPage,
                            current: page,
                            onChange: (page, pageSize) => {
                                setPage(page);
                                setPerPage(pageSize);
                            }
                        }}
                    />
                </div>
            </Content>
            <MemberFooter />
        </Layout>
    </>;
}

export default Comments;