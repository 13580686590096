export const API_HOST = process.env.REACT_APP_API_HOST ? process.env.REACT_APP_API_HOST : 'http://localhost:3000/api';
export const API_AUTH_URL = API_HOST + '/auth/';
export const API_ENTITY_URL = API_HOST + '/entity/';
export const API_LOG_URL = API_HOST + '/log/';
export const API_ROLE_URL = API_HOST + '/role/';
export const API_ADMIN_URL = API_HOST + '/admin/';
export const API_ENTITY_ARTICLE_TAG_URL = (entityId) => API_HOST + '/entity/' + entityId + '/article-tag/';
export const API_ENTITY_ARTICLE_CATEGORY_URL = (entityId) => API_HOST + '/entity/' + entityId + '/article-category/';
export const API_ENTITY_PAGE_URL = (entityId) => API_HOST + '/entity/' + entityId + '/page/';
export const API_ENTITY_USER_URL = (entityId) => API_HOST + '/entity/' + entityId + '/user/';
export const API_ENTITY_COMPANION_URL = (entityId) => API_HOST + '/entity/' + entityId + '/companion/';
export const API_ENTITY_ARTICLE_URL = (entityId) => API_HOST + '/entity/' + entityId + '/article/';
export const API_ENTITY_ORDER_URL = (entityId) => API_HOST + '/entity/' + entityId + '/order/';
export const API_MEDIA_URL = (entityId) => API_HOST + '/entity/' + entityId + '/media/';
export const API_USER_MEDIA_URL = (entityId) => API_HOST + '/entity/' + entityId + '/user-media/';

export const API_ENTITY_TRIP_URL = (entityId) => API_HOST + '/entity/' + entityId + '/trip/';
export const API_ENTITY_TRIP_COLLECTION_URL = (entityId) => API_HOST + '/entity/' + entityId + '/trip-collection/';
export const API_ENTITY_TRIP_TYPE_URL = (entityId) => API_HOST + '/entity/' + entityId + '/trip-type/';
export const API_ENTITY_TRIP_REGION_URL = (entityId) => API_HOST + '/entity/' + entityId + '/trip-region/';
export const API_ENTITY_TRIP_COUNTRY_URL = (entityId) => API_HOST + '/entity/' + entityId + '/trip-country/';
export const API_ENTITY_TRIP_HOLIDAY_URL = (entityId) => API_HOST + '/entity/' + entityId + '/trip-holiday/';
export const API_ENTITY_TRIP_TAG_URL = (entityId) => API_HOST + '/entity/' + entityId + '/trip-tag/';
export const API_ENTITY_TRIP_TOPIC_URL = (entityId) => API_HOST + '/entity/' + entityId + '/trip-topic/';

export const API_ENTITY_DISCUSSION_URL = (entityId) => API_HOST + '/entity/' + entityId + '/discussion/';
export const API_ENTITY_COMMENT_URL = (entityId, discussionId) => API_HOST + '/entity/' + entityId + '/discussion/' + discussionId + '/comment/';
export const API_CDN_URL = (entity) => {
    if (typeof entity.cdnPath !== 'undefined' && entity.cdnPath !== '') {
        return entity.cdnPath + '/';
    } else {
        let cdnPath = entity.protocol + '://cdn.' + entity.host;
        if (entity.path !== '') {
            cdnPath += '/' + entity.path + '/';
        }
        return cdnPath;
    }
}
export const API_CDN_UPLOAD_URL = (entity) => {
    if (typeof entity.cdnUploadPath !== 'undefined' && entity.cdnUploadPath !== '') {
        return entity.cdnUploadPath + '/';
    } else {
        let cdnUploadPath = entity.protocol + '://cdn-upload.' + entity.host;
        if (entity.path !== '') {
            cdnUploadPath += '/' + entity.path + '/';
        }
        return cdnUploadPath;
    }
}