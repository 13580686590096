import authHeader from './auth-header';
import axios from "axios";
import { API_ENTITY_ARTICLE_TAG_URL } from "./config";

// API to get active article tags translations
const readAllActiveArticleTagsTranslations = (entityId) => {
    return axios.get(API_ENTITY_ARTICLE_TAG_URL(entityId) + 'translations', {
        headers: authHeader()
    }).then(res => res.data);
}

// API to get entity article tag by id
const readArticleTag = (entityId, id) => {
    return axios.get(API_ENTITY_ARTICLE_TAG_URL(entityId) + id, {
        headers: authHeader()
    }).then(res => res.data);
}

// API to get all entity article tags
const readArticleTags = (entityId) => {
    return axios.get(API_ENTITY_ARTICLE_TAG_URL(entityId), {
        headers: authHeader()
    }).then(res => res.data);
}


// API to create entity article tag
const createArticleTag = (entityId, entityArticleTag) => {
    return axios
        .post(API_ENTITY_ARTICLE_TAG_URL(entityId), entityArticleTag, {
            headers: authHeader()
        }).then((response) => {
            return response.data;
        });
};

// API to update entity article tag
const updateArticleTag = (entityId, id, entityArticleTag) => {
    return axios
        .put(API_ENTITY_ARTICLE_TAG_URL(entityId) + id, entityArticleTag, {
            headers: authHeader()
        }).then((response) => {
            return response.data;
        });
};

// API to delete entity article tag
const deleteArticleTag = (entityId, id) => {
    return axios
        .delete(API_ENTITY_ARTICLE_TAG_URL(entityId) + id, {
            headers: authHeader()
        }).then((response) => {
            return response.data;
        });
};

const entityArticleTags = {
    createArticleTag,
    readArticleTag,
    readArticleTags,
    updateArticleTag,
    deleteArticleTag,
    readAllActiveArticleTagsTranslations
};

export default entityArticleTags;