import React, { useCallback, useEffect, useState } from 'react';
import { Layout, Popconfirm, message, Button, Table, Space, Form, Input, Descriptions, Modal, Row, Col, Select } from 'antd';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import MemberSidebar from './components/member.sidebar.component';
import MemberFooter from './components/member.footer.component';
import { useDispatch } from 'react-redux';
import { readOrders, completeOrder, sendOrderConfirmationEmail, sendTripConfirmationEmail, countOrders } from '../actions/entity.order';
import { PageHeader } from '@ant-design/pro-components';
import { useTranslation } from 'react-i18next';
import { green, red } from '@ant-design/colors';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { defaultPageSize, withFilterForm } from '../components/withFilterForm';

const layout = {
    labelCol: {
        span: 24,
    },
    wrapperCol: {
        span: 24,
    },
};

function Orders(props) {
    const { form, searchParams, setPage, setPageSize } = props;
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const params = useParams();
    const [sendOrderConfirmationEmailForm] = Form.useForm();
    const { Content } = Layout;
    const [dataSource, setDataSource] = useState([]);
    const [total, setTotal] = useState(0);
    const [emailOrder, setEmailOrder] = useState(null);
    const [orderConfirmationEmailModalVisible, setOrderConfirmationEmailModalVisible] = useState(false);
    const [tripConfirmationEmailModalVisible, setTripConfirmationEmailModalVisible] = useState(false);
    const [sendTripConfirmationEmailForm] = Form.useForm();


    const confirmSendTripConfirmationEmail = () => {
        const values = sendTripConfirmationEmailForm.getFieldsValue();
        dispatch(sendTripConfirmationEmail(emailOrder.entity, emailOrder.id, values.email)).then((response) => {
            if (typeof response.result !== 'undefined') {
                setDataSource(dataSource.map((item) => {
                    if (item.id === emailOrder.id) {
                        item.orderTripConfirmedEmailSentAt = response.result.orderTripConfirmedEmailSentAt;
                    }
                    return item;
                }
                ));
                message.success(t('entityOrders.sendEmail.success'));
            }
            setEmailOrder(null);
            setTripConfirmationEmailModalVisible(false);
            sendTripConfirmationEmailForm.resetFields();
        }).catch((error) => {
            try {
                let responseMessage = error.response.data.message;
                message.error(responseMessage);
            } catch (e) {
                console.log('Failed:', e);
                message.error(JSON.stringify(e));
            }
        });
    }

    const cancelSendTripConfirmationEmail = () => {
        setEmailOrder(null);
        setTripConfirmationEmailModalVisible(false);
        sendTripConfirmationEmailForm.resetFields();
    }

    const completeOrderAction = (record) => {
        dispatch(completeOrder(record.entity, record.id)).then((response) => {
            if (typeof response.result !== 'undefined') {
                setDataSource(dataSource.map((item) => {
                    if (item.id === record.id) {
                        item.status = 'completed';
                    }
                    return item;
                }));;
            }
        }).catch((error) => {
            try {
                let responseMessage = error.response.data.message;
                message.error(responseMessage);
            } catch (e) {
                console.log('Failed:', e);
                message.error(JSON.stringify(e));
            }
        });
    };

    const expandedRowRender = (record) => {
        return <Descriptions title={t('entityOrders.columns.details')} layout="horizontal" column={1} size="small" bordered={true}>
            <Descriptions.Item label={t('entityOrders.columns.id')}>{record.id}</Descriptions.Item>
            <Descriptions.Item label={t('entityOrders.columns.orderCompletedEmailSentAt')}>
                {Array.isArray(record.orderCompletedEmailSentAt) && <ul>{record.orderCompletedEmailSentAt.map((item, index) => {
                    return <li key={index}>{item.date} to {item.to}</li>
                })}</ul>}
            </Descriptions.Item>
            <Descriptions.Item label={t('entityOrders.columns.orderTripConfirmedEmailSentAt')}>
                {Array.isArray(record.orderTripConfirmedEmailSentAt) && <ul>{record.orderTripConfirmedEmailSentAt.map((item, index) => {
                    return <li key={index}>{item.date} to {item.to}</li>
                })}</ul>}
            </Descriptions.Item>
        </Descriptions>

    }

    const resendTripConfirmationEmailAction = (record) => {
        setEmailOrder(record);
        setTripConfirmationEmailModalVisible(true);
        console.log(record);
        sendTripConfirmationEmailForm.setFieldValue('email', record.user.email);
    }

    const resendOrderConfirmationEmailAction = (record) => {
        setEmailOrder(record);
        setOrderConfirmationEmailModalVisible(true);
        console.log(record);
        sendOrderConfirmationEmailForm.setFieldValue('email', record.user.email);
    }
    const confirmSendOrderConfirmationEmail = () => {
        const values = sendOrderConfirmationEmailForm.getFieldsValue();
        dispatch(sendOrderConfirmationEmail(emailOrder.entity, emailOrder.id, values.email)).then((response) => {
            if (typeof response.result !== 'undefined') {
                setDataSource(dataSource.map((item) => {
                    if (item.id === emailOrder.id) {
                        item.orderCompletedEmailSentAt = response.result.orderCompletedEmailSentAt;
                    }
                    return item;
                }));
                message.success(t('entityOrders.sendEmail.success'));
            }
            setEmailOrder(null);
            setOrderConfirmationEmailModalVisible(false);
            sendOrderConfirmationEmailForm.resetFields();
        }).catch((error) => {
            try {
                let responseMessage = error.response.data.message;
                message.error(responseMessage);
            } catch (e) {
                console.log('Failed:', e);
                message.error(JSON.stringify(e));
            }
        });
    }
    const cancelSendOrderConfirmationEmail = () => {
        setEmailOrder(null);
        setOrderConfirmationEmailModalVisible(false);
        sendOrderConfirmationEmailForm.resetFields();
    }

    const columns = [
        {
            title: t('entityOrders.columns.trip'),
            dataIndex: 'trip',
            key: 'trip',
            render: (text, record) => {
                if (typeof record.tripTranslation !== 'undefined') {
                    return <Link to={`/entity/${params.entityId}/trip/${record.tripTranslation.parent}`}><span>{record.tripTranslation.name}</span> {record.trip.confirmed ? <CheckCircleOutlined color={green[5]} /> : <CloseCircleOutlined color={red[5]} />}</Link>;
                } else {
                    return '';
                }
            }
        },
        {
            title: t('entityOrders.columns.user'),
            dataIndex: 'user',
            key: 'user',
            render: (text, record) => {
                if (typeof record.user !== 'undefined' && record.user !== null) {
                    return <Link to={`/entity/${params.entityId}/user/${record.user._id}`}>{record.user.lastName}, {record.user.firstName}</Link>;
                } else {
                    return '-';
                }
            }
        },
        {
            title: t('entityOrders.columns.amount'),
            dataIndex: 'amount',
            key: 'amount',
        },
        {
            title: t('entityOrders.columns.paid'),
            dataIndex: 'paid',
            key: 'paid',
        },
        {
            title: t('entityOrders.columns.subtotal'),
            dataIndex: 'subtotal',
            key: 'subtotal',
        },
        {
            title: t('entityOrders.columns.total'),
            dataIndex: 'total',
            key: 'total',
            render: (text, record) => {
                if (typeof record.total !== 'undefined' && record.total !== null) {
                    return record.total;
                } else {
                    return 'N/A';
                }
            }
        },
        {
            title: t('entityOrders.columns.status'),
            dataIndex: 'status',
            key: 'status'
        },
        {
            title: t('entityOrders.columns.created'),
            dataIndex: 'createdAt',
            key: 'createdAt'
        },
        {
            title: t('entityOrders.columns.orderCompletedEmailSentAt'),
            dataIndex: 'orderCompletedEmailSentAt',
            key: 'orderCompletedEmailSentAt',
            render: (text, record) => {
                if (typeof record.orderCompletedEmailSentAt !== 'undefined' && Array.isArray(record.orderCompletedEmailSentAt) && record.orderCompletedEmailSentAt.length > 0) {
                    return <CheckCircleOutlined style={{ color: green[5] }} />;
                } else {
                    return <CloseCircleOutlined style={{ color: red[5] }} />;
                }
            }
        },
        {
            title: t('entityOrders.columns.orderTripConfirmedEmailSentAt'),
            dataIndex: 'orderTripConfirmedEmailSentAt',
            key: 'orderTripConfirmedEmailSentAt',
            render: (text, record) => {
                if (typeof record.orderTripConfirmedEmailSentAt !== 'undefined' && Array.isArray(record.orderTripConfirmedEmailSentAt) && record.orderTripConfirmedEmailSentAt.length > 0) {
                    return <CheckCircleOutlined style={{ color: green[5] }} />;
                } else {
                    return <CloseCircleOutlined style={{ color: red[5] }} />;
                }
            }
        },
        {
            title: t('entityOrders.columns.actions'),
            dataIndex: 'actions',
            key: 'actions',
            render: (text, record) => <Space direction='vertical'>
                <Popconfirm
                    title="Are you sure to complete this order?"
                    onConfirm={() => completeOrderAction(record)}
                    okText="Complete"
                    cancelText="Cancel"
                >
                    {record.status === 'paid' && <Button size="small" style={{ backgroundColor: green[5] }} type="primary">{t('entityOrders.actions.complete')}</Button>}
                </Popconfirm>
                {!['pending', 'refunded', 'cancelled', 'failed'].includes(record.status) && <Button size="small" type="primary" onClick={() => resendOrderConfirmationEmailAction(record)}>{t('entityOrders.actions.sendConfirmationEmail')}</Button>}
                {record.trip.confirmed && !['pending', 'refunded', 'cancelled', 'failed'].includes(record.status) && <Button size="small" type="primary" onClick={() => resendTripConfirmationEmailAction(record)}>{t('entityOrders.actions.sendTripConfirmationEmail')}</Button>}
                <Button size="small" type="primary" href={`/entity/${params.entityId}/order/${record.id}`}>{t('entityOrders.actions.edit')}</Button>
            </Space>

        }
    ];

    const loadOrders = useCallback(() => {
        const filters = {};
        if (typeof searchParams.get('trip') !== 'undefined' && searchParams.get('trip') && searchParams.get('trip') !== '') {
            filters['trip'] = searchParams.get('trip');
        }
        if (typeof searchParams.get('user') !== 'undefined' && searchParams.get('user') && searchParams.get('user') !== '') {
            filters['user'] = searchParams.get('user');
        }
        if (typeof searchParams.get('status') !== 'undefined' && searchParams.get('status') && searchParams.get('status') !== '') {
            filters['status'] = searchParams.get('status');
        }
        const filtersWithPagination = { ...filters, page: searchParams.get('page'), pageSize: searchParams.get('pageSize') };
        dispatch(countOrders(params.entityId, filters)).then((data) => {
            setTotal(data.result);
        }).catch(error => {
            try {
                let responseMessage = error.response.data.message;
                console.log(responseMessage);
                message.error(responseMessage);
            } catch (e) {
                console.log('Failed:', e);
                message.error(JSON.stringify(e));
            }
        });
        dispatch(readOrders(params.entityId, filtersWithPagination)).then((data) => {
            setDataSource(data.result);
        }).catch((error) => {
            try {
                setDataSource([]);
                let responseMessage = error.response.data.message;
                console.log(responseMessage);
                message.error(responseMessage);
            } catch (e) {
                console.log('Failed:', e);
                message.error(JSON.stringify(e));
            }
        });
    }, [dispatch, searchParams]);

    useEffect(() => {
        loadOrders();
    }, [loadOrders]);

    return <>
        <MemberSidebar />
        <Layout className="site-layout">
            <Content style={{ margin: '0 16px' }}>
                <div className="site-layout-background">
                    <PageHeader
                        className="site-page-header"
                        onBack={() => navigate(`/entity/${params.entityId}/dashboard`)}
                        title={t('entityOrders.title')}
                        subTitle={t('entityOrders.subtitle')}
                    />
                    {form}
                    <Table
                        size='small'
                        rowKey={record => record.id}
                        dataSource={dataSource}
                        columns={columns}
                        expandable={{
                            expandedRowRender: expandedRowRender,
                            rowExpandable: () => true,
                        }}
                        pagination={{
                            total: total,
                            onChange(page, pageSize) {
                                setPage(page);
                                setPageSize(pageSize);
                            },
                            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                            defaultCurrent: searchParams.get('page') ? parseInt(searchParams.get('page')) : 1,
                            defaultPageSize: searchParams.get('pageSize') ? parseInt(searchParams.get('pageSize')) : defaultPageSize,
                            showSizeChanger: true,
                            pageSizeOptions: [defaultPageSize.toString(), '250', '500', '1000'],
                            position: ['topRight', 'bottomRight']
                        }}
                    />
                    <Modal title={t('entityORders.sendEmail.title')} open={orderConfirmationEmailModalVisible} onOk={confirmSendOrderConfirmationEmail} onCancel={cancelSendOrderConfirmationEmail}>
                        <Form form={sendOrderConfirmationEmailForm}>
                            <Form.Item
                                name="email"
                                label={t('entityOrders.field.email.label')}
                            >
                                <Input />
                            </Form.Item>
                        </Form>
                    </Modal>
                    <Modal title={t('entityORders.sendTripConfirmed.title')} open={tripConfirmationEmailModalVisible} onOk={confirmSendTripConfirmationEmail} onCancel={cancelSendTripConfirmationEmail}>
                        <Form form={sendTripConfirmationEmailForm}>
                            <Form.Item
                                name="email"
                                label={t('entityOrders.field.email.label')}
                            >
                                <Input />
                            </Form.Item>
                        </Form>
                    </Modal>
                </div>
            </Content>
            <MemberFooter />
        </Layout>
    </>;
}

export default withFilterForm(Orders, [
    { name: 'trip', label: 'Trip ID', formItem: <Input placeholder='Trip ID' /> },
    { name: 'user', label: 'User ID', formItem: <Input placeholder='User ID' /> },
    {
        name: 'status', label: 'Status', formItem: <Select allowClear={true} placeholder='Status' options={
            [
                { label: 'Pending', value: 'pending' },
                { label: 'Partial Paid', value: 'partial_paid' },
                { label: 'Paid', value: 'paid' },
                { label: 'Cancelled', value: 'cancelled' },
                { label: 'Completed', value: 'completed' },
                { label: 'Refunded', value: 'refunded' },
                { label: 'Failed', value: 'failed' }
            ]
        } />
    },
], null, null, null, defaultPageSize, 1, ['trip', 'user', 'status']);