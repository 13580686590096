import ArticleCategoryService from "../services/entity.article.category.service";

// Action to get all root entity article categories
export const readRootArticleCategories = (entityId) => (dispatch) => {
    return ArticleCategoryService.readRootArticleCategories(entityId).then((data) => {
        console.log(data);
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}


// Action to get all active entity article category translations by entity id
export const readAllActiveArticleCategoriesTranslations = (entityId) => (dispatch) => {
    return ArticleCategoryService.readAllActiveArticleCategoriesTranslations(entityId).then((data) => {
        console.log(data);
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to get entity by id
export const readArticleCategory = (entityId, id) => (dispatch) => {
    return ArticleCategoryService.readArticleCategory(entityId, id).then((data) => {
        console.log(data);
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to get all entity article categories
export const readArticleCategories = (entityId) => (dispatch) => {
    return ArticleCategoryService.readArticleCategories(entityId).then((data) => {
        // if (data.result) {
        //     dispatch({
        //         type: READ_ENTITY_REGIONS_SUCCESS,
        //         payload: data.result
        //     });
        // }
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to create the entity article category
export const createArticleCategory = (entityId, articleCategory) => (dispatch) => {
    return ArticleCategoryService.createArticleCategory(entityId, articleCategory).then((data) => {
        console.log(data);
        return Promise.resolve(data);
    }, (error) => {
        console.log(error.message);
        return Promise.reject(error);
    });
}

// Action to update the entity article category
export const updateArticleCategory = (entityId, id, articleCategory) => (dispatch) => {
    return ArticleCategoryService.updateArticleCategory(entityId, id, articleCategory).then((data) => {
        console.log(data);
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to delete the entity article category
export const deleteArticleCategory = (entityId, id) => (dispatch) => {
    return ArticleCategoryService.deleteArticleCategory(entityId, id).then((data) => {
        // dispatch({
        //     type: DELETE_ENTITY_REGION_SUCCESS,
        //     payload: id
        // });
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}