import TripCountryService from "../services/entity.trip.country.service";

// Action to get all active trip country translations by entity id
export const readAllActiveTripCountriesTranslations = (entityId) => (dispatch) => {
    return TripCountryService.readAllActiveTripCountriesTranslations(entityId).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to get entity by id
export const readTripCountry = (entityId, id) => (dispatch) => {
    return TripCountryService.readTripCountry(entityId, id).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to get all trip countries
export const readTripCountries = (entityId) => (dispatch) => {
    return TripCountryService.readTripCountries(entityId).then((data) => {
        // if (data.result) {
        //     dispatch({
        //         type: READ_ENTITY_REGIONS_SUCCESS,
        //         payload: data.result
        //     });
        // }
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to create the trip country
export const createTripCountry = (entityId, tripCountry) => (dispatch) => {
    return TripCountryService.createTripCountry(entityId, tripCountry).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        console.log(error.message);
        return Promise.reject(error);
    });
}

// Action to update the trip country
export const updateTripCountry = (entityId, id, tripCountry) => (dispatch) => {
    return TripCountryService.updateTripCountry(entityId, id, tripCountry).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to delete the trip country
export const deleteTripCountry = (entityId, id) => (dispatch) => {
    return TripCountryService.deleteTripCountry(entityId, id).then((data) => {
        // dispatch({
        //     type: DELETE_ENTITY_REGION_SUCCESS,
        //     payload: id
        // });
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}