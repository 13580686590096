import DiscussionService from "../services/entity.discussion.service";

// API Search discussion by name
export const searchDiscussion = (entityId, name) => (dispatch) => {
    return DiscussionService.searchDiscussion(entityId, name).then((data) => {
        console.log(data);
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to get entity by id
export const readDiscussion = (entityId, id, params) => (dispatch) => {
    return DiscussionService.readDiscussion(entityId, id, params).then((data) => {
        console.log(data);
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to count all comments in the discussion
export const countComments = (entityId, id) => (dispatch) => {
    return DiscussionService.countComments(entityId, id).then((data
    ) => {
        console.log(data);
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    }
    );
}

// Action to get all discussions
export const readDiscussions = (entityId) => (dispatch) => {
    return DiscussionService.readDiscussions(entityId).then((data) => {
        // if (data.result) {
        //     dispatch({
        //         type: READ_ENTITY_REGIONS_SUCCESS,
        //         payload: data.result
        //     });
        // }
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to create the discussion
export const createDiscussion = (entityId, discussion) => (dispatch) => {
    return DiscussionService.createDiscussion(entityId, discussion).then((data) => {
        console.log(data);
        return Promise.resolve(data);
    }, (error) => {
        console.log(error.message);
        return Promise.reject(error);
    });
}

// Action to update the discussion
export const updateDiscussion = (entityId, id, discussion) => (dispatch) => {
    return DiscussionService.updateDiscussion(entityId, id, discussion).then((data) => {
        console.log(data);
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to delete the discussion
export const deleteDiscussion = (entityId, id) => (dispatch) => {
    return DiscussionService.deleteDiscussion(entityId, id).then((data) => {
        // dispatch({
        //     type: DELETE_ENTITY_REGION_SUCCESS,
        //     payload: id
        // });
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}