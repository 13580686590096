import React from 'react';
import { Layout, Card, Row, Col } from 'antd';
import MemberSidebar from './components/member.sidebar.component';
import MemberFooter from './components/member.footer.component';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function Dashboard() {
    const { Content } = Layout;
    const entities = useSelector(state => state.entity.entities);
    const { t } = useTranslation();

    return <>
        <MemberSidebar />
        <Layout className="site-layout">
            <Content style={{ margin: '0 16px' }}>
                <div className="site-layout-background">
                    <h1 style={{ marginBottom: 20 }}>{t('dashboard.title')}</h1>
                    <Row justify="start">
                        {entities && entities.map(entity => {
                            return <Col span={6}>
                                <Link to={`/entity/${entity.id}/dashboard`}>
                                    <Card style={{ margin: '0 10px' }}
                                        hoverable
                                    ><div>{entity.name}</div>
                                    </Card>
                                </Link>
                            </Col>
                        })}
                    </Row>

                </div>
            </Content>
            <MemberFooter />
        </Layout>
    </>;
}

export default Dashboard;