import authHeader from './auth-header';
import axios from "axios";
import { API_ENTITY_TRIP_HOLIDAY_URL } from "./config";

// API to get active trip holiday translations
const readAllActiveTripHolidaysTranslations = (entityId) => {
    return axios.get(API_ENTITY_TRIP_HOLIDAY_URL(entityId) + 'translations', {
        headers: authHeader()
    }).then(res => res.data);
}

// API to get trip holiday by id
const readTripHoliday = (entityId, id) => {
    return axios.get(API_ENTITY_TRIP_HOLIDAY_URL(entityId) + id, {
        headers: authHeader()
    }).then(res => res.data);
}

// API to get all trip holidays
const readTripHolidays = (entityId) => {
    return axios.get(API_ENTITY_TRIP_HOLIDAY_URL(entityId), {
        headers: authHeader()
    }).then(res => res.data);
}


// API to create trip holiday
const createTripHoliday = (entityId, entityHoliday) => {
    return axios
        .post(API_ENTITY_TRIP_HOLIDAY_URL(entityId), entityHoliday, {
            headers: authHeader()
        }).then((response) => {
            return response.data;
        });
};

// API to update trip holiday
const updateTripHoliday = (entityId, id, entityHoliday) => {
    return axios
        .put(API_ENTITY_TRIP_HOLIDAY_URL(entityId) + id, entityHoliday, {
            headers: authHeader()
        }).then((response) => {
            return response.data;
        });
};

// API to delete trip holiday
const deleteTripHoliday = (entityId, id) => {
    return axios
        .delete(API_ENTITY_TRIP_HOLIDAY_URL(entityId) + id, {
            headers: authHeader()
        }).then((response) => {
            return response.data;
        });
};

const entityHolidays = {
    readAllActiveTripHolidaysTranslations,
    createTripHoliday,
    readTripHoliday,
    readTripHolidays,
    updateTripHoliday,
    deleteTripHoliday
};

export default entityHolidays;