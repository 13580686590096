import { Form, Input, Row, Col, Select, Button } from "antd";
import MediaSelect from "./mediaSelect.field";
import { MinusCircleOutlined, PlusOutlined, UpCircleOutlined, DownCircleOutlined } from '@ant-design/icons';

export default function LandingPageComponents({ language, form, showMediaDrawer, t, tripOptions, tripCountryOptions }) {
    return <>
        <div style={{ padding: 16, backgroundColor: '#eeeeee', marginBottom: 16 }}>
            <h2>{t('entityPage.field.landing.bannerSlider.sectionTitle')}</h2>
            <Form.List name={[language.value, 'data', 'landing', 'bannerSlider']} style={{ width: '100%' }}>
                {(fields, { add, remove, move }) => (
                    <>
                        <h1>{t('entityPage.field.landing.bannerSlider.label')}</h1>
                        {fields.map(({ key, name, ...restField }, fieldPosition) => {
                            return <div key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                <Row gutter={[16, 0]} style={{ width: 'calc(100% - 100px)' }}>
                                    <Col span={24}>
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'title']}
                                            required={true}
                                            label={t('entityPage.field.landing.bannerSliderTitle.label')}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: t('entityPage.field.landing.bannerSliderTitle.required')
                                                }
                                            ]}
                                        >
                                            <Input placeholder={t('entityPage.field.landing.bannerSliderTitle.placeholder')} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <MediaSelect
                                            fieldName={[language.value, 'data', 'landing', 'bannerSlider'].concat(name, 'image')}
                                            showMediaDrawer={showMediaDrawer} form={form} wrapper={{
                                                wrapperCol: 16,
                                                label: t('entityPage.field.landing.bannerSliderImage.label'),
                                                required: true,
                                            }} inner={{
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: t('entityPage.field.landing.bannerSliderImage.required')
                                                    }
                                                ],
                                                name: [name, 'image'],
                                            }} />
                                    </Col>
                                </Row>
                                <div style={{ width: 100 }}>
                                    {(fields.length > 1 && fieldPosition !== 0) && <UpCircleOutlined onClick={() => move(fieldPosition, fieldPosition - 1)} style={{ marginLeft: 8 }} />}
                                    {(fields.length > 1 && fieldPosition !== (fields.length - 1)) && <DownCircleOutlined onClick={() => move(fieldPosition, fieldPosition + 1)} style={{ marginLeft: 8 }} />}
                                    <MinusCircleOutlined onClick={() => remove(name)} style={{ marginLeft: 8 }} />
                                </div>
                            </div>
                        })}
                        <Form.Item wrapperCol={{ span: 24 }}>
                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                {t('entityPage.field.landing.bannerSlider.add')}
                            </Button>
                        </Form.Item>
                    </>
                )}
            </Form.List>
        </div>
        <div style={{ padding: 16, backgroundColor: '#eeeeee', marginBottom: 16 }}>
            <h2>{t('entityPage.field.landing.featuredProducts.sectionTitle')}</h2>
            <Form.Item
                label={t('entityPage.field.landing.featuredProductsSubtitle.label')}
                name={[language.value, 'data', 'landing', 'featuredProductsSubtitle']}
                required={true}
                rules={
                    [
                        {
                            required: true,
                            message: t('entityPage.field.landing.featuredProductsSubtitle.required')

                        }
                    ]}
            >
                <Input placeholder={t('entityPage.field.landing.featuredProductsSubtitle.placeholder')} />
            </Form.Item>
            <Form.Item
                label={t('entityPage.field.landing.featuredProductsTitle.label')}
                name={[language.value, 'data', 'landing', 'featuredProductsTitle']}
                required={true}
                rules={
                    [
                        {
                            required: true,
                            message: t('entityPage.field.landing.featuredProductsTitle.required')

                        }
                    ]}
            >
                <Input placeholder={t('entityPage.field.landing.featuredProductsTitle.placeholder')} />
            </Form.Item>
            <Form.List name={[language.value, 'data', 'landing', 'featuredProductsList']} style={{ width: '100%' }}>
                {(fields, { add, remove, move }) => (
                    <>
                        <h1>{t('entityPage.field.landing.featuredProductsList.label')}</h1>
                        {fields.map(({ key, name, ...restField }, fieldPosition) => {
                            return <div key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                <Row gutter={[16, 0]} style={{ width: 'calc(100% - 100px)' }}>
                                    <Col span={24}>
                                        <Form.Item
                                            {...restField}
                                            name={[name]}
                                            required={true}
                                            wrapperCol={{ span: 24 }}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: t('entityPage.field.landing.featuredProductsList.required')
                                                }
                                            ]}
                                        >
                                            <Select allowClear showSearch filterOption={(input, option) => {
                                                return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                            }}>
                                                {tripOptions.filter(trip => {
                                                    return trip.language === language.value;
                                                }).map(trip => {
                                                    return <Select.Option key={trip.value} value={trip.value}>{trip.label}</Select.Option>
                                                })}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <div style={{ width: 100 }}>
                                    {(fields.length > 1 && fieldPosition !== 0) && <UpCircleOutlined onClick={() => move(fieldPosition, fieldPosition - 1)} style={{ marginLeft: 8 }} />}
                                    {(fields.length > 1 && fieldPosition !== (fields.length - 1)) && <DownCircleOutlined onClick={() => move(fieldPosition, fieldPosition + 1)} style={{ marginLeft: 8 }} />}
                                    <MinusCircleOutlined onClick={() => remove(name)} style={{ marginLeft: 8 }} />
                                </div>
                            </div>
                        })}
                        <Form.Item wrapperCol={{ span: 24 }}>
                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                {t('entityPage.field.landing.featuredProductsList.add')}
                            </Button>
                        </Form.Item>
                    </>
                )}
            </Form.List>
        </div>
        <div style={{ padding: 16, backgroundColor: '#eeeeee', marginBottom: 16 }}>
            <h2>{t('entityPage.field.landing.featuredCountries.sectionTitle')}</h2>
            <Form.Item
                label={t('entityPage.field.landing.featuredCountriesSubtitle.label')}
                name={[language.value, 'data', 'landing', 'featuredCountriesSubtitle']}
                required={true}
                rules={
                    [
                        {
                            required: true,
                            message: t('entityPage.field.landing.featuredCountriesSubtitle.required')

                        }
                    ]}
            >
                <Input placeholder={t('entityPage.field.landing.featuredCountriesSubtitle.placeholder')} />
            </Form.Item>
            <Form.Item
                label={t('entityPage.field.landing.featuredCountriesTitle.label')}
                name={[language.value, 'data', 'landing', 'featuredCountriesTitle']}
                required={true}
                rules={
                    [
                        {
                            required: true,
                            message: t('entityPage.field.landing.featuredCountriesTitle.required')

                        }
                    ]}
            >
                <Input placeholder={t('entityPage.field.landing.featuredCountriesTitle.placeholder')} />
            </Form.Item>
            <Form.List name={[language.value, 'data', 'landing', 'featuredCountriesList']} style={{ width: '100%' }}>
                {(fields, { add, remove, move }) => (
                    <>
                        <h1>{t('entityPage.field.landing.featuredCountriesList.label')}</h1>
                        {fields.map(({ key, name, ...restField }, fieldPosition) => {
                            return <div key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                <Row gutter={[16, 0]} style={{ width: 'calc(100% - 100px)' }}>
                                    <Col span={24}>
                                        <Form.Item
                                            {...restField}
                                            name={[name]}
                                            required={true}
                                            wrapperCol={{ span: 24 }}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: t('entityPage.field.landing.featuredCountriesList.required')
                                                }
                                            ]}
                                        >
                                            <Select allowClear>
                                                {tripCountryOptions.filter(country => {
                                                    return country.language === language.value;
                                                }).map(country => {
                                                    return <Select.Option key={country.value} value={country.value}>{country.label}</Select.Option>
                                                })}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <div style={{ width: 100 }}>
                                    {(fields.length > 1 && fieldPosition !== 0) && <UpCircleOutlined onClick={() => move(fieldPosition, fieldPosition - 1)} style={{ marginLeft: 8 }} />}
                                    {(fields.length > 1 && fieldPosition !== (fields.length - 1)) && <DownCircleOutlined onClick={() => move(fieldPosition, fieldPosition + 1)} style={{ marginLeft: 8 }} />}
                                    <MinusCircleOutlined onClick={() => remove(name)} style={{ marginLeft: 8 }} />
                                </div>
                            </div>
                        })}
                        <Form.Item wrapperCol={{ span: 24 }}>
                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                {t('entityPage.field.landing.featuredCountriesList.add')}
                            </Button>
                        </Form.Item>
                    </>
                )}
            </Form.List>
        </div>
        <div style={{ padding: 16, backgroundColor: '#eeeeee', marginBottom: 16 }}>
            <h2>{t('entityPage.field.landing.features.sectionTitle')}</h2>
            <Form.Item
                label={t('entityPage.field.landing.featuresTitle.label')}
                name={[language.value, 'data', 'landing', 'featuresTitle']}
                required={true}
                rules={
                    [
                        {
                            required: true,
                            message: t('entityPage.field.landing.featuresTitle.required')

                        }
                    ]}
            >
                <Input placeholder={t('entityPage.field.landing.featuresTitle.placeholder')} />
            </Form.Item>
            <Form.List name={[language.value, 'data', 'landing', 'featuresList']} style={{ width: '100%' }}>
                {(fields, { add, remove, move }) => (
                    <>
                        <h1>{t('entityPage.field.landing.featuresList.label')}</h1>
                        {fields.map(({ key, name, ...restField }, fieldPosition) => {
                            return <div key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                <Row gutter={[16, 0]} style={{ width: 'calc(100% - 100px)' }}>
                                    <Col span={24}>
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'title']}
                                            required={true}
                                            label={t('entityPage.field.landing.featuresListTitle.label')}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: t('entityPage.field.landing.featuresListTitle.required')
                                                }
                                            ]}
                                        >
                                            <Input placeholder={t('entityPage.field.landing.featuresListTitle.placeholder')} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'description']}
                                            required={true}
                                            label={t('entityPage.field.landing.featuresListDescriptioin.label')}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: t('entityPage.field.landing.featuresListDescriptioin.required')
                                                }
                                            ]}
                                        >
                                            <Input placeholder={t('entityPage.field.landing.featuresListDescriptioin.placeholder')} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <MediaSelect
                                            fieldName={[language.value, 'data', 'landing', 'featuresList'].concat(name, 'image')}
                                            showMediaDrawer={showMediaDrawer} form={form} wrapper={{
                                                wrapperCol: 16,
                                                label: t('entityPage.field.landing.featuresListImage.label'),
                                                required: true,
                                            }} inner={{
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: t('entityPage.field.landing.featuresListImage.required')
                                                    }
                                                ],
                                                name: [name, 'image'],
                                            }} />
                                    </Col>
                                </Row>
                                <div style={{ width: 100 }}>
                                    {(fields.length > 1 && fieldPosition !== 0) && <UpCircleOutlined onClick={() => move(fieldPosition, fieldPosition - 1)} style={{ marginLeft: 8 }} />}
                                    {(fields.length > 1 && fieldPosition !== (fields.length - 1)) && <DownCircleOutlined onClick={() => move(fieldPosition, fieldPosition + 1)} style={{ marginLeft: 8 }} />}
                                    <MinusCircleOutlined onClick={() => remove(name)} style={{ marginLeft: 8 }} />
                                </div>
                            </div>
                        })}
                        <Form.Item wrapperCol={{ span: 24 }}>
                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                {t('entityPage.field.landing.featuresList.add')}
                            </Button>
                        </Form.Item>
                    </>
                )}
            </Form.List>
        </div>
        <div style={{ padding: 16, backgroundColor: '#eeeeee', marginBottom: 16 }}>
            <h2>{t('entityPage.field.landing.media.sectionTitle')}</h2>
            <Form.Item
                label={t('entityPage.field.landing.mediaTitle.label')}
                name={[language.value, 'data', 'landing', 'mediaTitle']}
                required={true}
                rules={
                    [
                        {
                            required: true,
                            message: t('entityPage.field.landing.mediaTitle.required')

                        }
                    ]}
            >
                <Input placeholder={t('entityPage.field.landing.mediaTitle.placeholder')} />
            </Form.Item>
            <Form.Item
                label={t('entityPage.field.landing.mediaDescription.label')}
                name={[language.value, 'data', 'landing', 'mediaDescription']}
                required={true}
                rules={
                    [
                        {
                            required: true,
                            message: t('entityPage.field.landing.mediaDescription.required')

                        }
                    ]}
            >
                <Input.TextArea placeholder={t('entityPage.field.landing.mediaDescription.placeholder')} />
            </Form.Item>
            <MediaSelect showMediaDrawer={showMediaDrawer} form={form} wrapper={{
                wrapperCol: 16,
                label: t('entityPage.field.landing.mediaBanner.label')
            }} inner={{
                name: [language.value, 'data', 'landing', 'mediaBanner']
            }} />
        </div>
        <div style={{ padding: 16, backgroundColor: '#eeeeee', marginBottom: 16 }}>
            <h2>{t('entityPage.field.landing.partner.sectionTitle')}</h2>
            <Form.Item
                label={t('entityPage.field.landing.partnerTitle.label')}
                name={[language.value, 'data', 'landing', 'partnerTitle']}
                required={true}
                rules={
                    [
                        {
                            required: true,
                            message: t('entityPage.field.landing.partnerTitle.required')

                        }
                    ]}
            >
                <Input placeholder={t('entityPage.field.landing.partnerTitle.placeholder')} />
            </Form.Item>
            <Form.Item
                label={t('entityPage.field.landing.partnerDescription.label')}
                name={[language.value, 'data', 'landing', 'partnerDescription']} p
            >
                <Input.TextArea placeholder={t('entityPage.field.landing.partnerDescription.placeholder')} />
            </Form.Item>
            <MediaSelect showMediaDrawer={showMediaDrawer} form={form} wrapper={{
                wrapperCol: 16,
                label: t('entityPage.field.landing.partnerBanner.label')
            }} inner={{
                name: [language.value, 'data', 'landing', 'partnerBanner']
            }} />
        </div>
        <div style={{ padding: 16, backgroundColor: '#eeeeee', marginBottom: 16 }}>
            <h2>{t('entityPage.field.landing.content.sectionTitle')}</h2>
            <Form.Item
                label={t('entityPage.field.landing.contentTitle.label')}
                name={[language.value, 'data', 'landing', 'contentTitle']}
                required={true}
                rules={
                    [
                        {
                            required: true,
                            message: t('entityPage.field.landing.contentTitle.required')

                        }
                    ]}
            >
                <Input placeholder={t('entityPage.field.landing.contentTitle.placeholder')} />
            </Form.Item>
            <Form.Item
                label={t('entityPage.field.landing.contentDescription.label')}
                name={[language.value, 'data', 'landing', 'contentDescription']}
                required={true}
                rules={
                    [
                        {
                            required: true,
                            message: t('entityPage.field.landing.contentDescription.required')

                        }
                    ]}
            >
                <Input.TextArea placeholder={t('entityPage.field.landing.contentDescription.placeholder')} />
            </Form.Item>
            <Form.Item
                label={t('entityPage.field.landing.contentLink.label')}
                name={[language.value, 'data', 'landing', 'contentLink']}
                required={true}
                rules={
                    [
                        {
                            required: true,
                            message: t('entityPage.field.landing.contentLink.required')

                        }
                    ]}
            >
                <Input placeholder={t('entityPage.field.landing.contentLink.placeholder')} />
            </Form.Item>
            <Form.List name={[language.value, 'data', 'landing', 'contentList']} style={{ width: '100%' }}>
                {(fields, { add, remove, move }) => (
                    <>
                        <h1>{t('entityPage.field.landing.contentList.label')}</h1>
                        {fields.map(({ key, name, ...restField }, fieldPosition) => {
                            return <div key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                <Row gutter={[16, 0]} style={{ width: 'calc(100% - 100px)' }}>
                                    <Col span={24}>
                                        <MediaSelect
                                            fieldName={[language.value, 'data', 'landing', 'contentList'].concat(name, 'image')}
                                            showMediaDrawer={showMediaDrawer} form={form} wrapper={{
                                                wrapperCol: 16,
                                                label: t('entityPage.field.landing.contentListImage.label'),
                                                required: true,
                                            }} inner={{
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: t('entityPage.field.landing.contentListImage.required')
                                                    }
                                                ],
                                                name: [name, 'image'],
                                            }} />
                                    </Col>
                                </Row>
                                <div style={{ width: 100 }}>
                                    {(fields.length > 1 && fieldPosition !== 0) && <UpCircleOutlined onClick={() => move(fieldPosition, fieldPosition - 1)} style={{ marginLeft: 8 }} />}
                                    {(fields.length > 1 && fieldPosition !== (fields.length - 1)) && <DownCircleOutlined onClick={() => move(fieldPosition, fieldPosition + 1)} style={{ marginLeft: 8 }} />}
                                    <MinusCircleOutlined onClick={() => remove(name)} style={{ marginLeft: 8 }} />
                                </div>
                            </div>
                        })}
                        <Form.Item wrapperCol={{ span: 24 }}>
                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                {t('entityPage.field.landing.contentList.add')}
                            </Button>
                        </Form.Item>
                    </>
                )}
            </Form.List>
        </div>
        <div style={{ padding: 16, backgroundColor: '#eeeeee', marginBottom: 16 }}>
            <h2>{t('entityPage.field.landing.blog.sectionTitle')}</h2>
            <Form.Item
                label={t('entityPage.field.landing.blogTitle.label')}
                name={[language.value, 'data', 'landing', 'blogTitle']}
                required={true}
                rules={
                    [
                        {
                            required: true,
                            message: t('entityPage.field.landing.blogTitle.required')

                        }
                    ]}
            >
                <Input placeholder={t('entityPage.field.landing.blogTitle.placeholder')} />
            </Form.Item>
            <Form.Item
                label={t('entityPage.field.landing.blogDescription.label')}
                name={[language.value, 'data', 'landing', 'blogDescription']}
                required={true}
                rules={
                    [
                        {
                            required: true,
                            message: t('entityPage.field.landing.blogDescription.required')

                        }
                    ]}
            >
                <Input.TextArea placeholder={t('entityPage.field.landing.blogDescription.placeholder')} />
            </Form.Item>
        </div>
        <div style={{ padding: 16, backgroundColor: '#eeeeee', marginBottom: 16 }}>
            <h2>{t('entityPage.field.landing.tagline.sectionTitle')}</h2>
            <Form.Item
                label={t('entityPage.field.landing.taglineTitle.label')}
                name={[language.value, 'data', 'landing', 'taglineTitle']}
            >
                <Input.TextArea placeholder={t('entityPage.field.landing.taglineTitle.placeholder')} />
            </Form.Item>
            <Form.Item
                label={t('entityPage.field.landing.taglineCta.label')}
                name={[language.value, 'data', 'landing', 'taglineCta']}
            >
                <Input placeholder={t('entityPage.field.landing.taglineCta.placeholder')} />
            </Form.Item>
            <Form.Item
                label={t('entityPage.field.landing.taglineUrl.label')}
                name={[language.value, 'data', 'landing', 'taglineUrl']}
            >
                <Input placeholder={t('entityPage.field.landing.taglineUrl.placeholder')} />
            </Form.Item>
        </div>
        <div style={{ padding: 16, backgroundColor: '#eeeeee', marginBottom: 16 }}>
            <h2>{t('entityPage.field.landing.about.sectionTitle')}</h2>
            <Form.Item
                label={t('entityPage.field.landing.aboutTitle.label')}
                name={[language.value, 'data', 'landing', 'aboutTitle']}
                required={true}
                rules={
                    [
                        {
                            required: true,
                            messageg: t('entityPage.field.landing.aboutTitle.required')

                        }
                    ]}
            >
                <Input placeholder={t('entityPage.field.landing.aboutTitle.placeholder')} />
            </Form.Item>
            <Form.Item
                label={t('entityPage.field.landing.aboutCta.label')}
                name={[language.value, 'data', 'landing', 'aboutCta']}
                required={true}
                rules={
                    [
                        {
                            required: true,
                            message: t('entityPage.field.landing.aboutCta.required')

                        }
                    ]}
            >
                <Input placeholder={t('entityPage.field.landing.aboutCta.placeholder')} />
            </Form.Item>
            <Form.Item
                label={t('entityPage.field.landing.aboutDescription.label')}
                name={[language.value, 'data', 'landing', 'aboutDescription']}
                required={true}
                rules={
                    [
                        {
                            required: true,
                            message: t('entityPage.field.landing.aboutDescription.required')

                        }
                    ]}
            >
                <Input.TextArea placeholder={t('entityPage.field.landing.aboutDescription.placeholder')} />
            </Form.Item>
            <MediaSelect showMediaDrawer={showMediaDrawer} form={form} wrapper={{
                wrapperCol: 16,
                label: t('entityPage.field.landing.aboutImageTitle.label')
            }} inner={{
                name: [language.value, 'data', 'landing', 'aboutImageTitle']
            }} />
            <MediaSelect showMediaDrawer={showMediaDrawer} form={form} wrapper={{
                wrapperCol: 16,
                label: t('entityPage.field.landing.aboutBanner.label')
            }} inner={{
                name: [language.value, 'data', 'landing', 'aboutBanner']
            }} />
        </div>
    </>
}