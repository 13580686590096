import authHeader from './auth-header';
import axios from "axios";
import { API_ENTITY_ORDER_URL } from "./config";
import qs from 'qs';

// API to get entity order by id
const readOrder = (entityId, id) => {
    return axios.get(API_ENTITY_ORDER_URL(entityId) + id, {
        headers: authHeader()
    }).then(res => res.data);
}

const completeOrder = (entityId, id) => {
    return axios.post(API_ENTITY_ORDER_URL(entityId) + id + "/complete", {}, {
        headers: authHeader()
    }).then(res => res.data);
}

// API to get all entity orders
const readOrders = (entityId, params) => {
    return axios.get(API_ENTITY_ORDER_URL(entityId) + '?' + qs.stringify(params), {
        headers: authHeader()
    }).then(res => res.data);
}

// API to count all entity orders
const countOrders = (entityId, params) => {
    return axios.get(API_ENTITY_ORDER_URL(entityId) + 'count?' + qs.stringify(params), {
        headers: authHeader()
    }).then(res => res.data);
}


// API to create entity order
const createOrder = (entityId, entityOrder) => {
    return axios
        .post(API_ENTITY_ORDER_URL(entityId), entityOrder, {
            headers: authHeader()
        }).then((response) => {
            return response.data;
        });
};

// API to update entity order
const updateOrder = (entityId, id, entityOrder) => {
    return axios
        .put(API_ENTITY_ORDER_URL(entityId) + id, entityOrder, {
            headers: authHeader()
        }).then((response) => {
            return response.data;
        });
};

// API to add custom addon on entity order
const addCustomAddonOnOrder = (entityId, orderId, customAddon) => {
    return axios
        .post(API_ENTITY_ORDER_URL(entityId) + orderId + "/custom-addons", customAddon, {
            headers: authHeader()
        }).then((response) => {
            return response.data;
        });
};

// API to update entity order traveler
const updateOrderTraveler = (entityId, orderId, travelerId, traveler) => {
    return axios
        .put(API_ENTITY_ORDER_URL(entityId) + orderId + "/traveler/" + travelerId, traveler, {
            headers: authHeader()
        }).then((response) => {
            return response.data;
        });
};

// API to send trip confirmation email
const sendTripConfirmationEmail = (entityId, orderId, email) => {
    return axios
        .post(API_ENTITY_ORDER_URL(entityId) + orderId + "/send-trip-confirmation-email", { email }, {
            headers: authHeader()
        }).then((response) => {
            return response.data;
        });
};

// API to send order confirmation email
const sendOrderConfirmationEmail = (entityId, orderId, email) => {
    return axios
        .post(API_ENTITY_ORDER_URL(entityId) + orderId + "/send-confirmation-email", { email }, {
            headers: authHeader()
        }).then((response) => {
            return response.data;
        });
};


const entityOrders = {
    createOrder,
    readOrder,
    completeOrder,
    readOrders,
    countOrders,
    updateOrder,
    addCustomAddonOnOrder,
    updateOrderTraveler,
    sendOrderConfirmationEmail,
    sendTripConfirmationEmail
};

export default entityOrders;