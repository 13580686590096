import authHeader from './auth-header';
import axios from "axios";
import { API_ENTITY_TRIP_TYPE_URL } from "./config";

// API to get active trip type translations
const readAllActiveTripTypesTranslations = (entityId) => {
    return axios.get(API_ENTITY_TRIP_TYPE_URL(entityId) + 'translations', {
        headers: authHeader()
    }).then(res => res.data);
}

// API to get entity type by id
const readTripType = (entityId, id) => {
    return axios.get(API_ENTITY_TRIP_TYPE_URL(entityId) + id, {
        headers: authHeader()
    }).then(res => res.data);
}

// API to get all entity types
const readTripTypes = (entityId) => {
    return axios.get(API_ENTITY_TRIP_TYPE_URL(entityId), {
        headers: authHeader()
    }).then(res => res.data);
}


// API to create entity type
const createTripType = (entityId, tripType) => {
    return axios
        .post(API_ENTITY_TRIP_TYPE_URL(entityId), tripType, {
            headers: authHeader()
        }).then((response) => {
            return response.data;
        });
};

// API to update entity type
const updateTripType = (entityId, id, tripType) => {
    return axios
        .put(API_ENTITY_TRIP_TYPE_URL(entityId) + id, tripType, {
            headers: authHeader()
        }).then((response) => {
            return response.data;
        });
};

// API to delete entity type
const deleteTripType = (entityId, id) => {
    return axios
        .delete(API_ENTITY_TRIP_TYPE_URL(entityId) + id, {
            headers: authHeader()
        }).then((response) => {
            return response.data;
        });
};

const tripTypes = {
    createTripType,
    readTripType,
    readTripTypes,
    updateTripType,
    deleteTripType,
    readAllActiveTripTypesTranslations
};

export default tripTypes;