import authHeader from './auth-header';
import axios from "axios";
import { API_ENTITY_PAGE_URL } from "./config";

// API to get entity page by id
const readEntityPage = (entityId, id) => {
    return axios.get(API_ENTITY_PAGE_URL(entityId) + id, {
        headers: authHeader()
    }).then(res => res.data);
}

// API to get all entity pages
const readEntityPages = (entityId) => {
    return axios.get(API_ENTITY_PAGE_URL(entityId), {
        headers: authHeader()
    }).then(res => res.data);
}


// API to create entity page
const createEntityPage = (entityId, entityPage) => {
    return axios
        .post(API_ENTITY_PAGE_URL(entityId), entityPage, {
            headers: authHeader()
        }).then((response) => {
            return response.data;
        });
};

// API to update entity page
const updateEntityPage = (entityId, id, entityPage) => {
    return axios
        .put(API_ENTITY_PAGE_URL(entityId) + id, entityPage, {
            headers: authHeader()
        }).then((response) => {
            return response.data;
        });
};

// API to delete entity page
const deleteEntityPage = (entityId, id) => {
    return axios
        .delete(API_ENTITY_PAGE_URL(entityId) + id, {
            headers: authHeader()
        }).then((response) => {
            return response.data;
        });
};

const entityPages = {
    createEntityPage,
    readEntityPage,
    readEntityPages,
    updateEntityPage,
    deleteEntityPage
};

export default entityPages;