function isRoot(user) {
    try {
        if (user) {
            if (typeof user.isRoot !== 'undefined' && user.isRoot === true) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    } catch (e) {
        console.log(e);
        return false;
    }
}

function isTopAdmin(user) {
    try {
        if (user) {
            if (typeof user.isTopAdmin !== 'undefined' && user.isTopAdmin === true) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    } catch (e) {
        console.log(e);
        return false;
    }
}

function isRootOrTopAdmin(user) {
    return isTopAdmin(user) || isRoot(user);
}

function isRegionalAdminOrHigher(user, entity) {
    if (isRootOrTopAdmin(user)) {
        return true;
    } else {
        if (typeof user.roles !== 'undefined' && Array.isArray(user.roles) && user.roles.length > 0) {
            for (let i in user.roles) {
                let role = user.roles[i];
                if (role.isRegionalAdmin === true) {
                    return true;
                } else {
                    return false;
                }
            }
        } else {
            return false;
        }
    }
}

// Checking user whether the current user is excluded the submission data in the entity
function userIsExcludedSubmissionDataInEntity(user, entity) {
    if (isRootOrTopAdmin(user)) {
        return false;
    } else {
        if (entity) {
            if (typeof user.roles !== 'undefined' && Array.isArray(user.roles) && user.roles.length > 0) {
                for (let i in user.roles) {
                    let role = user.roles[i];
                    if (role.excludeSubmissionData === true && role.entity === entity.id) {
                        return true;
                    }
                }
                // No roles found that match the entity and excludeSubmissionData is true
                return false;
            } else {
                // Return true because roles are not found in this user
                return true;
            }
        } else {
            return true;
        }
    }
}

function userIsReadOnlyInEntity(user, entity) {
    if (isRootOrTopAdmin(user)) {
        return false;
    } else {
        if (entity) {
            if (typeof user.roles !== 'undefined' && Array.isArray(user.roles) && user.roles.length > 0) {
                for (let i in user.roles) {
                    let role = user.roles[i];
                    if (role.isReadOnly === true && role.entity === entity.id) {
                        return true;
                    }
                }
                // No roles found that match the entity and isReadOnly is true
                return false;
            } else {
                // Return true because roles are not found in this user
                return true;
            }
        } else {
            return true;
        }
    }
}

// TODO: need to forEach the roles and inspect the permissions
function userHasPermissionInEntity(user, permission, entity) {
    return false;
}

export {
    isRoot,
    isTopAdmin,
    isRootOrTopAdmin,
    isRegionalAdminOrHigher,
    userHasPermissionInEntity,
    userIsReadOnlyInEntity,
    userIsExcludedSubmissionDataInEntity
}