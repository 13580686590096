export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";

export const FIRST_UPDATE_PASSWORD_SUCCESS = "FIRST_UPDATE_PASSWORD_SUCCESS";
export const FIRST_UPDATE_PASSWORD_FAIL = "FIRST_UPDATE_PASSWORD_FAIL";

export const PROFILE_UPDATE_NAME_SUCCESS = "PROFILE_UPDATE_NAME_SUCCESS";
export const PROFILE_UPDATE_NAME_FAIL = "PROFILE_UPDATE_NAME_FAIL";

export const CURRENT_USER_SUCCESS = "CURRENT_USER_SUCCESS";
export const CURRENT_USER_FAIL = "CURRENT_USER_FAIL";

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

export const READ_ENTITIES_SUCCESS = "READ_ENTITIES_SUCCESS";
export const READ_ENTITIES_FAIL = "READ_ENTITIES_FAIL";
export const DELETE_ENTITY_SUCCESS = "DELETE_ENTITY_SUCCESS";

export const READ_ADMINS_SUCCESS = "READ_ADMINS_SUCCESS";
export const READ_ADMINS_FAIL = "READ_ADMINS_FAIL";
export const CREATE_ADMIN_SUCCESS = "CREATE_ADMIN_SUCCESS";
export const DELETE_ADMIN_SUCCESS = "DELETE_ADMIN_SUCCESS";
export const UPDATE_ADMIN_SUCCESS = "UPDATE_ADMIN_SUCCESS";

// export const READ_ROLES_SUCCESS = "READ_ROLES_SUCCESS";
// export const READ_ROLES_FAIL = "READ_ROLES_FAIL";
// export const DELETE_ROLE_SUCCESS = "DELETE_ROLE_SUCCESS";

// export const READ_ADMINS_SUCCESS = "READ_ADMINS_SUCCESS";
// export const READ_ADMINS_FAIL = "READ_ADMINS_FAIL";
// export const CREATE_ADMIN_SUCCESS = "CREATE_ADMIN_SUCCESS";
// export const DELETE_ADMIN_SUCCESS = "DELETE_ADMIN_SUCCESS";
// export const UPDATE_ADMIN_SUCCESS = "UPDATE_ADMIN_SUCCESS";

// export const READ_USER_ATTRS_FOR_ADMIN_LIST_SUCCESS = "READ_USER_ATTRS_FOR_ADMIN_LIST_SUCCESS";
// export const READ_USER_ATTRS_FOR_ADMIN_LIST_FAIL = "READ_USER_ATTRS_FOR_ADMIN_LIST_FAIL";
// export const READ_USER_ATTRS_SUCCESS = "READ_USER_ATTRS_SUCCESS";

// export const READ_USER_ATTRS_FOR_ADMIN_USER_EDIT_SUCCESS = "READ_USER_ATTRS_FOR_ADMIN_USER_EDIT_SUCCESS";
// export const READ_USER_ATTRS_FOR_ADMIN_USER_EDIT_FAIL = "READ_USER_ATTRS_FOR_ADMIN_USER_EDIT_FAIL";