import authHeader from './auth-header';
import axios from "axios";
import { API_ENTITY_USER_URL } from "./config";
import qs from 'qs';

// API to get user by id
const readUser = (entityId, id) => {
    return axios.get(API_ENTITY_USER_URL(entityId) + id, {
        headers: authHeader()
    }).then(res => res.data);
}

// API to count all users
const countUsers = (entityId, params) => {
    console.log('countUsers', params);
    let filters = params;
    if (typeof filters.firstname === 'undefined' || filters.firstname === null) {
        delete filters.firstname;
    }
    if (typeof filters.lastname === 'undefined' || filters.lastname === null) {
        delete filters.lastname;
    }
    if (typeof filters.email === 'undefined' || filters.email === null) {
        delete filters.email;
    }
    return axios.get(API_ENTITY_USER_URL(entityId) + 'count?' + qs.stringify(filters), {
        headers: authHeader()
    }).then(res => res.data);
}

// API to get all users
const readUsers = (entityId, params) => {
    console.log('readUsers', params);
    let filters = params;
    if (typeof filters.firstname === 'undefined' || filters.firstname === null) {
        delete filters.firstname;
    }
    if (typeof filters.lastname === 'undefined' || filters.lastname === null) {
        delete filters.lastname;
    }
    if (typeof filters.email === 'undefined' || filters.email === null) {
        delete filters.email;
    }
    return axios.get(API_ENTITY_USER_URL(entityId) + '?' + qs.stringify(filters), {
        headers: authHeader()
    }).then(res => res.data);
}


// API to create user
const createUser = (entityId, user) => {
    return axios
        .post(API_ENTITY_USER_URL(entityId), user, {
            headers: authHeader()
        }).then((response) => {
            return response.data;
        });
};

// API to update user
const updateUser = (entityId, id, user) => {
    return axios
        .put(API_ENTITY_USER_URL(entityId) + id, user, {
            headers: authHeader()
        }).then((response) => {
            return response.data;
        });
};

// API to delete user
const deleteUser = (entityId, id) => {
    return axios
        .delete(API_ENTITY_USER_URL(entityId) + id, {
            headers: authHeader()
        }).then((response) => {
            return response.data;
        });
};

// API to enable user
const enableUser = (entityId, id) => {
    return axios
        .post(API_ENTITY_USER_URL(entityId) + id + "/enable", {}, {
            headers: authHeader()
        }).then((response) => {
            return response.data;
        });
};

// API to disable user
const disableUser = (entityId, id) => {
    return axios
        .post(API_ENTITY_USER_URL(entityId) + id + "/disable", {}, {
            headers: authHeader()
        }).then((response) => {
            return response.data;
        });
};


const users = {
    createUser,
    readUser,
    readUsers,
    countUsers,
    updateUser,
    deleteUser,
    enableUser,
    disableUser
};

export default users;