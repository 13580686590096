import authHeader from './auth-header';
import axios from "axios";
import { API_ENTITY_TRIP_COLLECTION_URL } from "./config";

// API to get entity collection by id
const readTripCollection = (entityId, id) => {
    return axios.get(API_ENTITY_TRIP_COLLECTION_URL(entityId) + id, {
        headers: authHeader()
    }).then(res => res.data);
}

// API to get all entity collections
const readTripCollections = (entityId) => {
    return axios.get(API_ENTITY_TRIP_COLLECTION_URL(entityId), {
        headers: authHeader()
    }).then(res => res.data);
}


// API to create entity collection
const createTripCollection = (entityId, tripCollection) => {
    return axios
        .post(API_ENTITY_TRIP_COLLECTION_URL(entityId), tripCollection, {
            headers: authHeader()
        }).then((response) => {
            return response.data;
        });
};

// API to update entity collection
const updateTripCollection = (entityId, id, tripCollection) => {
    return axios
        .put(API_ENTITY_TRIP_COLLECTION_URL(entityId) + id, tripCollection, {
            headers: authHeader()
        }).then((response) => {
            return response.data;
        });
};

// API to delete entity collection
const deleteTripCollection = (entityId, id) => {
    return axios
        .delete(API_ENTITY_TRIP_COLLECTION_URL(entityId) + id, {
            headers: authHeader()
        }).then((response) => {
            return response.data;
        });
};

const tripCollections = {
    createTripCollection,
    readTripCollection,
    readTripCollections,
    updateTripCollection,
    deleteTripCollection
};

export default tripCollections;