import React, { useEffect, useState } from 'react';
import { Layout, Popconfirm, message, Button, Table, Space, Input, Form, Statistic } from 'antd';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import MemberSidebar from './components/member.sidebar.component';
import MemberFooter from './components/member.footer.component';
import { useDispatch } from 'react-redux';
import { readCompanions, countCompanions, deleteCompanion } from '../actions/entity.companion';
import { PageHeader } from '@ant-design/pro-components';
import { useTranslation } from 'react-i18next';
import { green } from '@ant-design/colors';
import CompanionDetail from './components/companion.component';
import { getShortToken } from '../actions/auth';

const layout = {
    labelCol: {
        span: 24,
    },
    wrapperCol: {
        span: 24,
    },
};

function Companions(props) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const params = useParams();
    const { Content } = Layout;
    const [dataSource, setDataSource] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const defaultUserIdState = searchParams.get('user') ? searchParams.get('user') : null;
    const [user, setUser] = useState(defaultUserIdState);
    const [shortToken, setShortToken] = useState(null);
    const [dataSourceCount, setDataSourceCount] = useState(0);
    const defaultPerPageState = searchParams.get('perPage') ? parseInt(searchParams.get('perPage')) : 40;
    const [perPage, setPerPage] = useState(defaultPerPageState);
    const defaultPageState = searchParams.get('page') ? parseInt(searchParams.get('page')) : 1;
    const [page, setPage] = useState(defaultPageState);

    const deleteCompanionAction = (id) => {
        dispatch(deleteCompanion(params.entityId, id)).then((response) => {
            message.success(t('companions.message.delete-success'));
            setDataSource(dataSource.filter(item => item.id !== id));
        }).catch(e => {
            console.log(e);
            message.error(t('companions.message.delete-failed'));
        });
    }

    const columns = [
        {
            title: t('companions.columns.id'),
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: t('companions.columns.primary'),
            dataIndex: 'primary',
            key: 'primary',
            render: (text, record) => {
                if (typeof record.primary !== 'undefined' && record.primary === true) {
                    return t('companions.columns.primary.true');
                } else {
                    return t('companions.columns.primary.false');
                }
            }
        },
        {
            title: t('companions.columns.isMember'),
            dataIndex: 'isMember',
            key: 'isMember',
            render: (text, record) => {
                if (typeof record.memberId !== 'undefined' && record.memberId && record.memberId !== '' && typeof record.memberEmail !== 'undefined' && record.memberEmail && record.memberEmail !== '') {
                    return t('companions.columns.isMember.true');
                } else {
                    return t('companions.columns.isMember.false');
                }
            }
        },
        {
            title: t('companions.columns.name'),
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => {
                if (typeof record.basic !== 'undefined' && record.basic) {
                    return `${record.basic.lastName}, ${record.basic.firstName}`;
                } else {
                    return 'N/A';
                }
            }
        },
        {
            title: t('companions.columns.email'),
            dataIndex: 'email',
            key: 'email',
            render: (text, record) => {
                if (typeof record.basic !== 'undefined' && record.basic && typeof record.basic.email !== 'undefined' && record.basic.email) {
                    return record.basic.email;
                } else if (typeof record.memberEmail !== 'undefined' && record.memberEmail) {
                    return record.memberEmail;
                } else {
                    return 'N/A';
                }
            }
        },
        {
            title: t('companions.columns.user'),
            dataIndex: 'user',
            key: 'user',
            render: (text, record) => {
                if (record.user && record.user._id) {
                    return <Link to={`/entity/${params.entityId}/user/${record.user._id}`}>{record.user.lastName}, {record.user.firstName}</Link>
                } else {
                    return text;
                }
            }
        },
        {
            title: t('companions.columns.created'),
            dataIndex: 'createdAt',
            key: 'createdAt'
        },
        {
            title: t('companions.columns.actions'),
            dataIndex: 'actions',
            key: 'actions',
            render: (text, record) => <Space>
                <Button size="small" type="primary" href={`/entity/${params.entityId}/companion/${record.id}`}>{t('companions.actions.edit')}</Button>
                <Popconfirm
                    title={t('companions.actions.delete-confirm')}
                    onConfirm={() => deleteCompanionAction(record.id)}
                    okText={t('companions.actions.delete-ok')}
                    cancelText={t('companions.actions.delete-cancel')}
                >
                    <Button size="small" type="primary" danger>{t('companions.actions.delete')}</Button>
                </Popconfirm>
            </Space >

        }
    ];

    useEffect(() => {
        dispatch(getShortToken()).then((data) => {
            if (data && data.shortToken) {
                setShortToken(data.shortToken);
            }
        }).catch(e => {
            console.log(e.message);
        });
        dispatch(countCompanions(params.entityId, { user })).then((response) => {
            if (typeof response.result !== 'undefined') {
                setDataSourceCount(response.result);
            } else {
                setDataSourceCount(0);
            }
        }).catch((error) => {
            try {
                let responseMessage = error.response.data.message;
                console.log(responseMessage);
                message.error(responseMessage);
            } catch (e) {
                console.log('Failed:', e);
                message.error(JSON.stringify(e));
            }
        });
        dispatch(readCompanions(params.entityId, { perPage, page, user })).then((response) => {
            if (typeof response.result !== 'undefined') {
                setDataSource(response.result);
            } else {
                setDataSource([]);
            }
        }).catch((error) => {
            try {
                setDataSource([]);
                let responseMessage = error.response.data.message;
                console.log(responseMessage);
                message.error(responseMessage);
            } catch (e) {
                console.log('Failed:', e);
                message.error(JSON.stringify(e));
            }
        });
    }, [dispatch, params.entityId, setSearchParams, user, page, perPage]);

    useEffect(() => {
        if (typeof user === 'undefined' || user === null) {
            setSearchParams({ page, perPage });
        } else {
            setSearchParams({ page, perPage, user });
        }
    }, [perPage, page, user, setSearchParams]);

    useEffect(() => {
        form.setFieldValue('user', user);
    }, [form, user]);

    const clearUserAction = () => {
        setUser(null);
    }
    const onFinish = (values) => {
        if (values.user === '') {
            values.user = null;
            setUser(values.user);
            setSearchParams({});
        } else {
            setUser(values.user);
            setSearchParams({ user: values.user });
        }
    }
    const onFinishFailed = () => {

    }

    return <>
        <MemberSidebar />
        <Layout className="site-layout">
            <Content style={{ margin: '0 16px' }}>
                <div className="site-layout-background">
                    <PageHeader
                        className="site-page-header"
                        onBack={() => navigate(`/entity/${params.entityId}/dashboard`)}
                        title={t('companions.title')}
                        subTitle={t('companions.subtitle')}
                        extra={[
                            <Button key="create" type="primary" href={`/entity/${params.entityId}/companion`}>{t('companions.actions.new')}</Button>
                        ]}
                    />
                    <Form style={{ backgroundColor: '#eeeeee', padding: 20, marginBottom: 20 }} {...layout} form={form} name="companions" onFinish={onFinish} onFinishFailed={onFinishFailed}>
                        <h2>{t('entityCompanions.searchForm')}</h2>
                        <Form.Item
                            label={t('entityCompanions.field.user.label')}
                            name="user"
                        >
                            <Input placeholder={t('entityCompanions.field.user.placeholder')} />
                        </Form.Item>
                        <Space>
                            <Button type="primary" htmlType="submit">{t('entityCompanions.actions.searchUser')}</Button>
                            <Button type="primary" danger onClick={clearUserAction}>{t('entityCompanions.actions.clearUser')}</Button>
                        </Space>
                    </Form>
                    <Statistic style={{ marginBottom: 20 }} title={t('entityCompanions.statistic.title')} value={dataSource.length} />
                    <Table
                        size="small"
                        rowKey={record => record.id}
                        dataSource={dataSource}
                        columns={columns}
                        expandable={{
                            expandedRowRender: (record) => <CompanionDetail shortToken={shortToken} {...record} />,
                            rowExpandable: () => true,
                        }}
                        pagination={{
                            position: ['topRight', 'bottomRight'],
                            total: dataSourceCount,
                            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                            showSizeChanger: true,
                            pageSize: perPage,
                            current: page,
                            onChange: (page, pageSize) => {
                                setPage(page);
                                setPerPage(pageSize);
                            }
                        }}
                    />
                </div>
            </Content>
            <MemberFooter />
        </Layout>
    </>;
}

export default Companions;