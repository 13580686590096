import React, { useEffect, useState } from 'react';
import { Layout, message, Button, Row, Form, Input, Switch, Select, Tabs, Drawer, Space, Col, Descriptions, Table, Modal, InputNumber } from 'antd';
import { PageHeader } from '@ant-design/pro-components';
import { Link, useNavigate, useParams } from 'react-router-dom';
import MemberSidebar from './components/member.sidebar.component';
import MemberFooter from './components/member.footer.component';
import { useDispatch, useSelector } from 'react-redux';
import {
    readOrder,
    updateOrderTraveler,
    addCustomAddonOnOrder,
} from '../actions/entity.order';
import { useTranslation } from 'react-i18next';
import { dot, set } from 'dot-object';
import CompanionDetail from './components/companion.component';
import { getShortToken } from '../actions/auth';
import CompanionForm from './components/companionForm.component';
import { API_USER_MEDIA_URL } from '../services/config';
import { changeToDateUTC, changeToDayJsObject } from '../helpers/date';

function convertDataFromDataToForm(data) {
    if (typeof data.passport !== 'undefined' && data.passport !== null) {
        if (typeof data.passport.mediaDocuments !== 'undefined' && data.passport.mediaDocuments) {
            data.passport.mediaDocuments = data.passport.mediaDocuments.map(mediaDocument => {
                if (typeof mediaDocument._id !== 'undefined' && mediaDocument._id !== null) {
                    return mediaDocument._id;
                } else {
                    return mediaDocument;
                }
            });
        }
    }
    return data;
}

function convertDatesFromFormToData(data) {
    if (typeof data.basic !== 'undefined' && data.basic) {
        if (typeof data.basic.dob !== 'undefined' && data.basic.dob !== null) {
            data.basic.dob = changeToDateUTC(data.basic.dob);
        } else {
            data.basic.dob = null;
        }
    }
    if (typeof data.passport !== 'undefined' && data.passport) {
        let passportDateFieldKeys = ['passportIssuedDate', 'passportExpiredDate', 'chinaRelatedIssuedDate', 'chinaRelatedExpiredDate'];
        for (let i in passportDateFieldKeys) {
            let key = passportDateFieldKeys[i];
            if (typeof data.passport[key] !== 'undefined' && data.passport[key] !== null) {
                data.passport[key] = changeToDateUTC(data.passport[key]);
            } else {
                data.passport[key] = null;
            }
        }
    }
    return data;
}

function convertDatesFromDataToForm(data) {
    if (typeof data.basic !== 'undefined' && data.basic) {
        if (typeof data.basic.dob !== 'undefined' && data.basic.dob !== null) {
            data.basic.dob = changeToDayJsObject(data.basic.dob);
        } else {
            data.basic.dob = null;
        }
    }
    if (typeof data.passport !== 'undefined' && data.passport) {
        let passportDateFieldKeys = ['passportIssuedDate', 'passportExpiredDate', 'chinaRelatedIssuedDate', 'chinaRelatedExpiredDate'];
        for (let i in passportDateFieldKeys) {
            let key = passportDateFieldKeys[i];
            if (typeof data.passport[key] !== 'undefined' && data.passport[key] !== null) {
                data.passport[key] = changeToDayJsObject(data.passport[key]);
            } else {
                data.passport[key] = null;
            }
        }
    }
    return data;
}

const travelerAddonColumns = [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: 'Code',
        dataIndex: 'code',
        key: 'code',
    },
    {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
    },
    {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
    },
    {
        title: 'Price',
        dataIndex: 'price',
        key: 'price',
        render: (text, record) => {
            if (record.type === 'array') {
                if (typeof record.selectedOption !== 'undefined' && record.selectedOption.price) {
                    return record.selectedOption.price;
                } else {
                    return 'N/A';
                }
            } else {
                return record.price;
            }
        }
    }
]

const TravelerDetail = (props) => {
    const { traveler, shortToken } = props;
    console.log(props);
    return <>
        <CompanionDetail {...traveler.data} shortToken={shortToken} />
        {Array.isArray(traveler.addons) && <Table dataSource={traveler.addons} columns={travelerAddonColumns} />}
    </>
}

const paymentColumns = [
    {
        title: 'Payment ID',
        dataIndex: 'paymentId',
        key: 'paymentId',
        render: (text, record) => {
            return `${record.id}${record.isDeposit ? ' <Deposit>' : ''}`;
        }
    },
    {
        title: 'Payment Method',
        dataIndex: 'method',
        key: 'method',
    },
    {
        title: 'Amount',
        dataIndex: 'amount',
        key: 'amount'
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
    },
    {
        title: 'Created At',
        dataIndex: 'createdAt',
        key: 'createdAt',
    }
]

const pricingColumns = [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        render: (text, record) => {
            if (record.nameBold) {
                return <strong>{record.name}</strong>
            } else {
                return record.name;
            }
        },
        onCell: (_, index) => {
            if (_ && _.last) {
                return {
                    colSpan: 2
                };
            } else {
                return {
                    colSpan: 1
                };
            }
        },
    },
    {
        title: 'Count',
        dataIndex: 'count',
        key: 'count',
        onCell: (_, index) => {
            if (_ && _.last) {
                return {
                    colSpan: 0
                };
            } else {
                return {
                    colSpan: 1
                };
            }
        },
    },
    {
        title: 'Price',
        dataIndex: 'price',
        key: 'price',
        render: (text, record) => {
            if (record.priceBold) {
                return <strong>{record.price}</strong>
            } else {
                return record.price;
            }
        },
    }
]

const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 10,
    },
};
const basicNeededKeys = [{
    key: 'tripTranslation',
    label: 'Trip'
}, {
    key: 'user',
    label: 'User'
}, {
    key: 'amount',
    label: 'Amount'
}, {
    key: 'status',
    label: 'Status'
}, {
    key: 'createdAt',
    label: 'Created At'
}, {
    key: 'updatedAt',
    label: 'Updated At'
}, {
    key: 'completedInformation',
    label: 'Completed Trip Information'
}];

const basicPricingKeys = [
    {
        key: 'first_payment_date_valid',
        label: 'First Payment Date Valid'
    },
    {
        key: 'needDeposit',
        label: 'Deposit Needed'
    },
    {
        key: 'early_bird',
        label: 'Early Bird'
    },
    {
        key: 'unitDeposit',
        label: 'Unit Deposit'
    },
    {
        key: 'amount',
        label: 'Amount'
    },
    {
        key: 'unitPrice',
        label: 'Unit Price'
    },
    {
        key: 'originalUnitPrice',
        label: 'Original Unit Price'
    },
    {
        key: 'paid',
        label: 'Paid'
    },
    {
        key: 'subtotal',
        label: 'Subtotal'
    },
    {
        key: 'total',
        label: 'Total'
    }
];

function calculatePricingDataSource(order) {
    let tempItems = [];
    tempItems.push({
        name: 'Unit Price',
        count: `x ${order.amount}`,
        price: (order.unitPrice * order.amount)
    });
    // Traveler addons
    if (typeof order.travelers !== 'undefined' && Array.isArray(order.travelers)) {
        order.travelers.forEach((traveler, travelerIndex) => {
            if (typeof traveler.addons !== 'undefined' && Array.isArray(traveler.addons)) {
                traveler.addons.forEach(addon => {
                    if (addon.type === 'array') {
                        tempItems.push({
                            name: `Traveler #${travelerIndex + 1} - ${addon.name} - ${addon.selectedOption.name}`,
                            count: `x 1`,
                            price: addon.selectedOption.price
                        });
                    } else if (addon.type === 'number' || addon.type === 'text') {
                        tempItems.push({
                            name: `Traveler #${travelerIndex + 1} - ${addon.name} <${addon.extra?addon.extra:'N/A'}>`,
                            count: `x 1`,
                            price: addon.price
                        });
                    } else {
                        tempItems.push({
                            name: `Traveler #${travelerIndex + 1} - ${addon.name}`,
                            count: `x 1`,
                            price: addon.price
                        });
                    }
                });
            }
        });
    }
    // Trip level addons
    if (typeof order.tripAddons !== 'undefined' && Array.isArray(order.tripAddons)) {
        order.tripAddons.forEach(addon => {
            tempItems.push({
                name: `${addon.name} [price: ${addon.price}] [unitPrice: ${addon.unitPrice}]`,
                count: `x 1`,
                price: (addon.price + addon.unitPrice * order.amount)
            });
        });
    }
    // Trip custom addons
    if (typeof order.custom_addons !== 'undefined' && Array.isArray(order.custom_addons)) {
        order.custom_addons.forEach(customAddon => {
            tempItems.push({
                name: `${customAddon.name} (${customAddon.code})`,
                count: `x 1`,
                price: customAddon.price
            });
        });
    }
    tempItems.push({
        nameBold: true,
        name: 'Subtotal',
        count: '',
        price: order.subtotal,
        priceBold: true,
        last: true
    });
    if (order.total) {
        tempItems.push({
            nameBold: true,
            name: 'Total',
            count: '',
            price: order.total,
            last: true
        });
    }
    return tempItems;
}

function Order() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const params = useParams();
    const { Content } = Layout;
    const [customAddonModalForm] = Form.useForm();
    const [travelerModalForm] = Form.useForm();
    const [orderTitle, setOrderTitle] = useState('');
    const [orderData, setOrderData] = useState(null);
    const [basicOrderItems, setBasicOrderItems] = useState(null);
    const [priceOrderItems, setPriceOrderItems] = useState(null);
    const [pricingDataSource, setPricingDataSource] = useState(null);
    const [shortToken, setShortToken] = useState(null);
    const [travelerModalData, setTravelerModalData] = useState(null);
    const [travelerModalVisible, setTravelerModalVisible] = useState(false);

    const editTraveler = (record) => {
        setTravelerModalData(record);
        setTravelerModalVisible(true);
    }
    useEffect(() => {
        travelerModalForm.resetFields();
        if (travelerModalData) {
            let data = JSON.parse(JSON.stringify(travelerModalData.data));
            data = convertDatesFromDataToForm(data);
            data = convertDataFromDataToForm(data);
            travelerModalForm.setFieldsValue(data);
        }
    }, [travelerModalData, travelerModalVisible]);
    const travelerColumns = [
        {
            title: 'Last Name',
            dataIndex: 'lastName',
            key: 'lastName',
            render: (text, record) => {
                return record.data.basic.lastName
            }
        },
        {
            title: 'First Name',
            dataIndex: 'firstName',
            key: 'firstName',
            render: (text, record) => {
                return record.data.basic.firstName
            }
        }, {
            title: 'Source',
            dataIndex: 'source',
            key: 'source',
            render: (text, record) => {
                return record.source
            }
        },
        {
            title: 'Source Id',
            dataIndex: 'source_id',
            key: 'source_id',
            render: (text, record) => {
                return record.source_id ? record.source_id : 'N/A'
            }
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: (text, record) => {
                return <Button type="primary" onClick={() => editTraveler(record)}>{t('entityOrder.actions.edit-traveler')}</Button>
            }
        }
    ];

    const [isCustomAddonsModalOpen, setIsCustomAddonsModalOpen] = useState(false);
    const showCustomAddonsModal = () => {
        setIsCustomAddonsModalOpen(true);
    };
    const handleCustomAddonOk = () => {
        const values = customAddonModalForm.getFieldsValue();
        const requiredFields = ['name', 'code', 'price'];
        for (let i in requiredFields) {
            if (typeof values[requiredFields[i]] === 'undefined' || values[requiredFields[i]] === null || values[requiredFields[i]] === '') {
                message.error(t('customAddonModalForm.field.required'));
                return;
            }
        }
        dispatch(addCustomAddonOnOrder(params.entityId, params.orderId, values)).then((response) => {
            if (typeof response.result !== 'undefined' && typeof response.result.id !== 'undefined') {
                setIsCustomAddonsModalOpen(false);
                message.success(t('customAddonModalForm.message.success'));
                setOrderData(response.result);
            } else {
                message.error(t('customAddonModalForm.message.error'));
            }
        }).catch(e => {
            console.log(e.message)
            message.error(`${t('customAddonModalForm.message.error')} - ${e.message}`);
        });
    };
    const handleCustomAddonCancel = () => {
        setIsCustomAddonsModalOpen(false);
    };

    useEffect(() => {
        if (orderData) {
            setPricingDataSource(calculatePricingDataSource(orderData));
            let nextOrderItems = [];
            basicNeededKeys.forEach(needKey => {
                if (typeof orderData[needKey.key] !== 'undefined') {
                    if (needKey.key === 'tripTranslation') {
                        nextOrderItems.push({
                            key: needKey.key,
                            label: needKey.label,
                            children: <Link to={`/entity/${params.entityId}/trip/${orderData.tripTranslation.parent}`}>{orderData[needKey.key].name}</Link>
                        });
                    } else if (needKey.key === 'user') {
                        nextOrderItems.push({
                            key: needKey.key,
                            label: needKey.label,
                            children: <Link to={`/entity/${params.entityId}/user/${orderData.user._id}`}>{`${orderData[needKey.key].lastName}, ${orderData[needKey.key].firstName}`}</Link>
                        });
                    } else if (needKey.key === 'completedInformation') {
                        nextOrderItems.push({
                            key: needKey.key,
                            label: needKey.label,
                            children: (typeof orderData[needKey.key] !== 'undefined' && orderData[needKey.key] === true) ? 'Yes' : 'No'
                        });
                    } else {
                        nextOrderItems.push({
                            key: needKey.key,
                            label: needKey.label,
                            children: orderData[needKey.key]
                        });
                    }
                }
            });
            let nextPriceOrderItems = [];
            basicPricingKeys.forEach(needKey => {
                if (typeof orderData[needKey.key] !== 'undefined') {
                    if (needKey.key === 'early_bird') {
                        nextPriceOrderItems.push({
                            key: needKey.key,
                            label: needKey.label,
                            children: orderData[needKey.key] ? 'Yes' : 'No'
                        });
                    } else if (needKey.key === 'total') {
                        nextPriceOrderItems.push({
                            key: needKey.key,
                            label: needKey.label,
                            children: orderData[needKey.key] ? orderData[needKey.key] : 'N/A'
                        });
                    } else if (needKey.key === 'needDeposit') {
                        nextPriceOrderItems.push({
                            key: needKey.key,
                            label: needKey.label,
                            children: (typeof orderData[needKey.key] !== 'undefined' && orderData[needKey.key] === true) ? 'Yes' : 'No'
                        });
                    } else {
                        nextPriceOrderItems.push({
                            key: needKey.key,
                            label: needKey.label,
                            children: orderData[needKey.key]
                        });
                    }
                }
            });
            setBasicOrderItems(nextOrderItems);
            setPriceOrderItems(nextPriceOrderItems);
        }
    }, [orderData]);

    useEffect(() => {
        if (typeof params.orderId !== 'undefined') {
            dispatch(getShortToken()).then((data) => {
                if (data && data.shortToken) {
                    setShortToken(data.shortToken);
                }
            }).catch(e => {
                console.log(e.message);
            });
            setOrderTitle(t('entityOrder.title.edit'));
            dispatch(readOrder(params.entityId, params.orderId)).then((response) => {
                if (typeof response.result !== 'undefined' && typeof response.result.id !== 'undefined') {
                    setOrderTitle(`${t('entityOrder.title.edit')} - ${response.result.id}`);
                    setOrderData(response.result);
                } else {
                    message.error(t('entityOrder.message.error-reading'));
                    navigate(`/entity/${params.entityId}/orders`);
                }
            }).catch(e => {
                message.error(t('entityOrder.message.error-reading'));
                navigate(`/entity/${params.entityId}/orders`);
            });
        } else {
            setOrderTitle(t('entityOrder.title.new'));
        }
    }, [params.orderId, params.entityId, dispatch, navigate]);

    let extraButtons = [
        <Button key="custom_addons" type="primary" onClick={showCustomAddonsModal}>Custom Addons</Button>,
        <Button key="cancel" type="primary" danger href={`/entity/${params.entityId}/orders`}>{t('entityOrder.actions.cancel')}</Button>
    ];

    const handleEditTravelerOk = () => {
        let formData = travelerModalForm.getFieldsValue();
        let data = convertDatesFromFormToData(formData);
        dispatch(updateOrderTraveler(params.entityId, params.orderId, travelerModalData._id, data)).then((response) => {
            if (typeof response.result !== 'undefined' && typeof response.result.id !== 'undefined') {
                message.success(t('entityOrder.message.success-update-traveler'));
                setTravelerModalVisible(false);
                setTravelerModalData(null);
                setOrderData(response.result);
            }
        }).catch(e => {
            message.error(`${t('entityOrder.message.error-update-traveler')} - ${e.message}`);
        });
    }

    const handleEditTravelerCancel = () => {
        setTravelerModalVisible(false);
        setTravelerModalData(null);
    }

    const mediaLinkRenderer = (props) => {
        console.log('mediaLinkRenderer', props);
    }

    const items = [
        {
            key: '1',
            label: 'UserName',
            children: 'Zhou Maomao',
        },
        {
            key: '2',
            label: 'Telephone',
            children: '1810000000',
        },
        {
            key: '3',
            label: 'Live',
            children: 'Hangzhou, Zhejiang',
        },
        {
            key: '4',
            label: 'Remark',
            children: 'empty',
        },
        {
            key: '5',
            label: 'Address',
            children: 'No. 18, Wantang Road, Xihu District, Hangzhou, Zhejiang, China',
        },
    ];
    return <>
        <MemberSidebar />
        <Layout className="site-layout">
            <Content style={{ margin: '0 16px' }}>
                <Row gutter={[16, 0]}>
                    <Col span={24}>
                        <div className="site-layout-background-flexible">
                            <PageHeader
                                className="site-page-header"
                                onBack={() => navigate(`/entity/${params.entityId}/orders`)}
                                title={orderTitle}
                                extra={extraButtons}
                            />
                            {basicOrderItems && <Descriptions title="Order Info">
                                {basicOrderItems.map(orderItem => <Descriptions.Item key={orderItem.key} label={orderItem.label}>{orderItem.children}</Descriptions.Item>)}</Descriptions>}
                        </div>
                    </Col>
                    <Col span={24}>
                        <div className="site-layout-background-flexible">
                            {priceOrderItems && <Descriptions title="Pricing Info">
                                {priceOrderItems.map(orderItem => <Descriptions.Item key={orderItem.key} label={orderItem.label}>{orderItem.children}</Descriptions.Item>)}</Descriptions>}
                        </div>
                    </Col>
                    <Col span={24}>
                        <div className="site-layout-background-flexible">
                            <h3>Pricing Items</h3>
                            <Table dataSource={pricingDataSource} columns={pricingColumns} />
                        </div>
                    </Col>
                    <Col span={24}>
                        <div className="site-layout-background-flexible">
                            <h3>Travelers</h3>
                            <Table
                                rowKey={(record) => record._id}
                                dataSource={orderData && orderData.travelers}
                                columns={travelerColumns}
                                expandable={{
                                    expandedRowRender: (record) => <TravelerDetail shortToken={shortToken} traveler={record} />,
                                    rowExpandable: (record) => {
                                        return true;
                                    }
                                }}
                            />
                        </div>
                    </Col>
                    <Col span={24}>
                        <div className="site-layout-background-flexible">
                            <h3>Payment</h3>
                            <Table
                                rowKey={(record) => record.id}
                                dataSource={orderData && orderData.payments}
                                columns={paymentColumns}
                                expandable={{
                                    expandedRowRender: (record) => (
                                        <pre>{JSON.stringify(record.paymentData, null, 4)}</pre>
                                    ),
                                    rowExpandable: (record) => {
                                        return true;
                                    }
                                }}
                            />
                        </div>
                    </Col>
                    {(orderData && orderData.agreements) &&
                        <Col span={24}>
                            <div className="site-layout-background-flexible">
                                <h3>Agreements</h3>
                                {orderData.agreements.map(agreement => <div key={agreement.id} style={{ marginBottom: 10 }}>
                                    <h4>{agreement.name}</h4>
                                    <div>{agreement.type === 'boolean' ? (agreement.value === true ? 'Checked' : 'Not Checked') : agreement.value}</div>
                                </div>)}
                            </div>
                        </Col>
                    }
                </Row>
                <Modal title="Custom Modal" open={isCustomAddonsModalOpen} onOk={handleCustomAddonOk} onCancel={handleCustomAddonCancel}>
                    <Form
                        {...layout}
                        form={customAddonModalForm}
                        name="custom_addon_modal">
                        <Form.Item
                            label="Name"
                            name="name"
                            required={true}
                            rules={[
                                {
                                    required: true,
                                    message: t('customAddonModalForm.field.required')
                                }
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Code"
                            name="code"
                            required={true}
                            rules={[
                                {
                                    required: true,
                                    message: t('customAddonModalForm.field.required')
                                },
                                { pattern: /^[a-z0-9-_]+$/, message: t('customAddonModalForm.field.code.pattern') }
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Price"
                            name="price"
                            required={true}
                            rules={[
                                {
                                    required: true,
                                    message: t('customAddonModalForm.field.required')
                                }
                            ]}
                        >
                            <InputNumber />
                        </Form.Item>
                    </Form>
                </Modal>
                <Modal title="Traveler Modal" width={1000} open={travelerModalVisible} onOk={handleEditTravelerOk} onCancel={handleEditTravelerCancel}>
                    <Form
                        {...layout}
                        form={travelerModalForm}
                        name="order-traveler">
                        <CompanionForm mediaLinkRenderer={mediaLinkRenderer} form={travelerModalForm} showMediaDrawer={null} t={t} />
                    </Form>
                </Modal>
            </Content>
            <MemberFooter />
        </Layout>
    </>;
}

export default Order;