import React, { useRef } from 'react';
import JoditEditor from 'jodit-react';

const TextEditor = ({ value, onChange, placeholder }) => {
    const editor = useRef(null);

    const config = {
        readonly: false,
        placeholder: placeholder,
        // Add custom font-family
        minHeight: '400px',
        toolbarAdaptive: false,
    };

    return (
        <JoditEditor
            ref={editor}
            value={value}
            config={config}
            onBlur={onChange}
        />
    );
};

export default TextEditor;