import TripTypeService from "../services/entity.trip.type.service";

// Action to get all active trip type translations by entity id
export const readAllActiveTripTypesTranslations = (entityId) => (dispatch) => {
    return TripTypeService.readAllActiveTripTypesTranslations(entityId).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to get entity by id
export const readTripType = (entityId, id) => (dispatch) => {
    return TripTypeService.readTripType(entityId, id).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to get all entity types
export const readTripTypes = (entityId) => (dispatch) => {
    return TripTypeService.readTripTypes(entityId).then((data) => {
        // if (data.result) {
        //     dispatch({
        //         type: READ_ENTITY_REGIONS_SUCCESS,
        //         payload: data.result
        //     });
        // }
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to create the entity type
export const createTripType = (entityId, tripType) => (dispatch) => {
    return TripTypeService.createTripType(entityId, tripType).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        console.log(error.message);
        return Promise.reject(error);
    });
}

// Action to update the entity type
export const updateTripType = (entityId, id, tripType) => (dispatch) => {
    return TripTypeService.updateTripType(entityId, id, tripType).then((data) => {
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}

// Action to delete the entity type
export const deleteTripType = (entityId, id) => (dispatch) => {
    return TripTypeService.deleteTripType(entityId, id).then((data) => {
        // dispatch({
        //     type: DELETE_ENTITY_REGION_SUCCESS,
        //     payload: id
        // });
        return Promise.resolve(data);
    }, (error) => {
        return Promise.reject(error);
    });
}